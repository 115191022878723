import { format, isValid, addMinutes } from 'date-fns';

export const shortLocalDate = input => {
    const date = new Date(input);
    if (!input || !isValid(date)) return;
    return format(date, 'MM/dd/yyyy');
};

export const shortLocalDateTime = input => {
    const date = new Date(input);
    if (!input || !isValid(date)) return;
    return format(date, 'MM/dd/yyyy hh:mm a');
}

export const shortUtcDate = input => {
    const date = new Date(input);
    if (!input || !isValid(date)) return;
    return format(addMinutes(date, date.getTimezoneOffset()+10), "P")
};

export const shortNumberToText = input => {
    if (!input || typeof input != 'number') return;
    return ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
    'seventeen', 'eighteen', 'nineteen', 'twenty'][input];
}

export const dashIfEmpty = input => {
    return !input ? '-' : input
}