import { Castable } from '../Castable';


export class Service extends Castable {
    static Cast(obj: Object) : Service {
        return super.Cast(Service, obj);
    }
    code!: string;
    quantity?: Number;
    dateOfService?: string;
    endDateOfService?: string;
    unitOfMeasure?: string;
    frequency?: string;
    frequencyType?: string;
    modifiers?: Array<string>;
    admissionTypeCode?: string;
    admissionSourceCode?: string;
    isPrimary?: boolean;

    get startDate() : string | undefined {
        return this.dateOfService;
    }

    get endDate() : string | undefined {
        return this.endDateOfService
    }
}


export class UniqueService extends Service {
    static Cast(obj) {
        return Castable.Cast(UniqueService, obj);
    }
    id!: number;
    primary: boolean = false;
    valid: boolean = false;
}