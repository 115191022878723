import { Castable } from "../Castable";

export class NoaRequest extends Castable {
    static Cast(obj?: Object) : NoaRequest {
        return super.Cast(NoaRequest, obj);
    } 
    fromDate?: string;
    toDate?: string;
    lengthOfStay?: string;
    primaryProcedure?: string;
    diagnosisCode?: string;
    readmission?: boolean;
    patientInEmergencyDepartment: boolean = false;
    contactName?: string;
    contactPhone?: string;
    notes?: string;
    fileIdentifiers?: Array<string>;
}