import {
  map,
  tap,
  catchError,
  retryWhen,
  delay,
  switchMap,
} from "rxjs/operators";
import { of, Observable, defer, from, forkJoin } from "rxjs";
import Api from "./api";
import Axios, { AxiosResponse } from "axios";
import { ROUTER_NAMES } from "@shared/constants";
import { Draft, DraftStatus } from "@shared/interfaces/draft/draft";
import {
  Case,
  CaseReasonIds,
  CaseStatusIds,
  isAddressChangeReason,
  isDateOfServiceReason,
  isPayerQuestionReason,
  isFileUploadNeeded,
  fileErroredNotProcessing,
  isReprocessingReason,
  isModifierChangeReason,
} from "@shared/interfaces/case/case";
import store from "@core/store/store";
import { submittedComponentNames } from "@views/submitted/interfaces/interfaces";
import { Route } from "vue-router";
import { isMocked } from "../shared/utils";
import { RetrievableFileData } from "../views/submitted/caseDetails/interfaces/interfaces";
import { FileDownloadResponse } from "../shared/interfaces/file/FileDownload";
import { Referral, TransactionType } from "../shared/interfaces/case/referral";
import {
  DataGatheringId,
  DataGatheringIdMap,
  DataGatheringRequiredResponse,
  DataGatheringResultTracking,
  DataGatheringSupportedResponse,
} from "./interfaces/scalableDataGathering";
import { Submission } from "../shared/interfaces/case/submission";
import { NoaRecord } from "../shared/interfaces/case/noaRecord";

export const getDraftSource = (
  orgId: string,
  draftId: string,
  mockProperty: string,
  isSubmitted: boolean,
  api: Api
): Observable<AxiosResponse<Draft> | null> => {
  return defer(() =>
    api.get<Draft>(
      `${process.env.VUE_APP_RHYME_API}/organization/${orgId}/draft/${draftId}`
    )
  ).pipe(
    catchError((error) => {
      // eslint-disable-next-line no-console
      console.warn(error);
      // eslint-disable-next-line no-console
      console.warn("Could not get the draft status");
      // return of(null);
      throw error;
    }),
    baseRetry
  );
};

// For Draft or Confirm Submissions.
// Based on the calculated destination from the Draft status
// If you want to go to a destination, and thats what the draft status confirms, then return NULL to let the request through
// Otherwise, you will create a new route to get you to that draft destination
const NavigateToDraftDependentComponent = (
  draft: Draft,
  route: Route,
  destination: ROUTER_NAMES
) => {
  return route.name === destination
    ? null
    : {
        ...route,
        name: destination,
        params: { orgId: draft.organizationId, draftId: draft.partnerCaseId },
      };
};

const getDraftRoutePipe = (
  source: Observable<AxiosResponse<Draft> | null>,
  orgId: string,
  route: Route
) => {
  return source.pipe(
    map((response) => {
      const notFound = { name: ROUTER_NAMES.NotFound };
      if (!response || !response.data || response.status === 404)
        return notFound;
      if (response.status === 200) {
        const draftResponse = response.data;
        //hack for noa to work, draft service needs transaction type on referral
        //currently submit request is where that transaction type live,
        //submit request  is the request object for draft validate and submit endpoint,
        // and we need transaction type on there
        //solution hack into the referral the transaction type from the draft.
        draftResponse.referral.transactionType = draftResponse.transactionType;
        store.dispatch(
          "UserModule/updateActiveOrgId",
          draftResponse.organizationId
        );
        store.dispatch("CaseModule/setDraftAction", draftResponse);
        if (
          draftResponse.status === DraftStatus.Submitted &&
          !!draftResponse.panCaseId
        ) {
          //call a get draft to get caseId;
          const newRoute: any = {
            ...route,
            name: ROUTER_NAMES.Submitted,
            params: { orgId, caseId: draftResponse.panCaseId },
          };
          delete newRoute.path;
          delete newRoute.fullPath;

          return newRoute;
        }
        if (draftResponse.status === DraftStatus.Pending) {
          return NavigateToDraftDependentComponent(
            draftResponse,
            route,
            ROUTER_NAMES.ConfirmSubmission
          );
        } else if (
          draftResponse.status === DraftStatus.Incomplete ||
          draftResponse.status === DraftStatus.Reprocessing
        ) {
          return NavigateToDraftDependentComponent(
            draftResponse,
            route,
            ROUTER_NAMES.Create
          );
        }
      }
      return notFound;
    })
  );
};

export const getDraftRouteObservable = (
  orgId: string,
  draftId: string,
  route: Route,
  isSubmitted: boolean,
  api: Api
) => {
  return getDraftRoutePipe(
    getDraftSource(
      orgId,
      draftId,
      isMocked(route) ? route.query.isMock.toString() : "",
      isSubmitted,
      api
    ),
    orgId,
    route
  ).pipe(
    map((route) => {
      if (
        store.getters["UserModule/isReadOnly"] &&
        route &&
        route.name == ROUTER_NAMES.ConfirmSubmission
      ) {
        const createRoute = {
          name: ROUTER_NAMES.Create,
          params: { orgId, draftId },
        };
        return createRoute;
      }

      // Default to just letting the route go through as is
      return route;
    })
  );
};

export const getDataGatheringCaseURL = (
  orgId: string,
  caseId: string,
  submissionId: string
) =>
  `${process.env.VUE_APP_RHYME_API}/organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering`;

const getSubmissionScalableSupported = (
  orgId: string,
  caseId: string,
  submissionId: string,
  api: Api
): Observable<AxiosResponse<DataGatheringSupportedResponse> | null> => {
  return defer(() =>
    api.get<DataGatheringSupportedResponse>(
      `${getDataGatheringCaseURL(orgId, caseId, submissionId)}/supported`
    )
  ).pipe(
    catchError((ex) => {
      // Explicitly tell the observable 404 is OK
      if (!!ex.response && ex.response.status === 404) {
        return of(ex.response);
      } else {
        throw ex;
      }
    }),
    baseRetry
  );
};

const getDataGatheringRequiredScreen = (
  orgId: string,
  caseId: string,
  submissionId: string,
  api: Api
): Observable<AxiosResponse<DataGatheringRequiredResponse> | null> => {
  return defer(() =>
    api.get<DataGatheringRequiredResponse>(
      `${getDataGatheringCaseURL(orgId, caseId, submissionId)}/required`
    )
  ).pipe(
    catchError((ex) => {
      // Explicitly tell the observable 404 is OK
      if (!!ex.response && ex.response.status === 404) {
        return of(ex.response);
      } else {
        throw ex;
      }
    }),
    baseRetry
  );
};

const getCaseSource = (
  orgId: string,
  caseId: string,
  mockProperty: string,
  api: Api
): Observable<AxiosResponse<Case> | null> => {
  return defer(() =>
    api.get<Case>(
      `${process.env.VUE_APP_RHYME_API}/organization/${orgId}/case/${caseId}`
    )
  ).pipe(
    catchError((error) => {
      // No Case? we push through gracefully as null
      // eslint-disable-next-line no-console
      console.warn(error);
      // eslint-disable-next-line no-console
      console.warn("Could not get the case");
      throw error;
    }),
    baseRetry
  );
};

const isNoa = (caseResponse: Case) => {
  return (
    caseResponse.referral.transactionType ===
    TransactionType.INPATIENT_NOTICE_OF_ADMISSION
  );
};

const NavigateByStatusAndCaseInfo = (caseResponse: Case) => {
  // If no statusId exists, just sit on SubmissionCompleted
  const transactionBasedDefault = isNoa(caseResponse)
    ? submittedComponentNames.ContinueStayCaseDetails
    : submittedComponentNames.CaseDetails;
  if (!caseResponse.statusId)
    return submittedComponentNames.SubmissionCompleted;
  switch (caseResponse.statusId) {
    case CaseStatusIds.PROCESSING:
      // Processing Processing is submission completed for now, all other go to cases details;
      if (caseResponse.reasonId === CaseReasonIds.PROCESSING) {
        return submittedComponentNames.SubmissionCompleted;
      }
      return transactionBasedDefault;
    case CaseStatusIds.SURVEY_REQUIRED:
      return submittedComponentNames.Surveys;
    case CaseStatusIds.CLINICAL_NEEDED:
      if (isFileUploadNeeded(caseResponse.reasonId)) {
        return submittedComponentNames.ClinicalRequired;
      }
      if (
        caseResponse.isProcessingDocuments &&
        !fileErroredNotProcessing(caseResponse.reasonId)
      ) {
        return submittedComponentNames.SubmissionCompleted;
      }
      return transactionBasedDefault;
    case CaseStatusIds.INCOMPLETE:
      if (
        isDateOfServiceReason(caseResponse.reasonId) ||
        isAddressChangeReason(caseResponse.reasonId) ||
        isPayerQuestionReason(caseResponse) ||
        isModifierChangeReason(caseResponse.reasonId)
      ) {
        return submittedComponentNames.SubmissionCorrections;
      }
      if (isReprocessingReason(caseResponse.reasonId)) {
        return store.getters["CaseModule/draftSuccessfullySubmitted"]
          ? submittedComponentNames.SubmissionCompleted
          : submittedComponentNames.DraftRedirect;
      }
      return transactionBasedDefault;
    default:
      return transactionBasedDefault;
  }
};

const getScalableDataGatheringResult = (
  theCase: Case,
  submission: Submission,
  api
): Observable<DataGatheringResultTracking | null> => {
  const statusDeterminedScreen = NavigateByStatusAndCaseInfo(theCase);
  const defaultScreen = isNoa(theCase)
    ? submittedComponentNames.ContinuedStayReview
    : statusDeterminedScreen;
  // Get Supported result from backend
  return getDataGatheringRequiredScreen(
    theCase.organizationId,
    theCase.caseId,
    submission.submissionId!,
    api
  ).pipe(
    switchMap((dgr) => {
      //  TODO - Backwards Compatibility with 404
      if (!!dgr && dgr.status === 404) {
        return of({
          screen: defaultScreen,
          sdgDetermined: true,
          dgrResponse: {
            dataGatheringScreen: DataGatheringId.None,
          },
        } as DataGatheringResultTracking);
      }
      // If we actually get data back, map it with our supported screens. Default to Statusdriven result if we cannot find it.
      if (!!dgr && !!dgr.data) {
        const foundMappedComponent = DataGatheringIdMap.find(
          (dgid) => dgid.dataGatheringId === dgr.data.dataGatheringScreen
        );
        // If 'None', we overwrite with the default screen
        if (foundMappedComponent?.dataGatheringId === DataGatheringId.None) {
          foundMappedComponent.submissionCorrectionMapping = defaultScreen;
        }
        return of({
          screen:
            foundMappedComponent?.submissionCorrectionMapping ??
            statusDeterminedScreen,
          sdgDetermined: !!foundMappedComponent?.submissionCorrectionMapping,
          dgrResponse: dgr.data,
          submissionId: submission.submissionId,
        } as DataGatheringResultTracking);
      }
      // Other failure cases just use StatusDriven result.
      return of({
        screen: statusDeterminedScreen,
        sdgDetermined: false,
        dgrResponse: null,
        submissionId: "",
      } as DataGatheringResultTracking);
    })
  );
};
const getCaseComponentPipe = (
  source: Observable<AxiosResponse<Case> | null>,
  api: Api
): Observable<submittedComponentNames | null> => {
  return source.pipe(
    switchMap((caseAxiosResponse) => {
      // Store case if we have it then move on with case response
      let caseObject: Case | null = null;
      if (
        !!caseAxiosResponse &&
        !!caseAxiosResponse.data &&
        caseAxiosResponse.status === 200
      ) {
        let caseResponse = caseAxiosResponse.data;
        caseResponse = Case.Cast({ ...caseResponse });
        store.dispatch(
          "UserModule/updateActiveOrgId",
          caseResponse.organizationId
        );
        store.dispatch("CaseModule/setCaseStateAction", caseResponse);
        caseObject = caseResponse;
      }
      return of(caseObject);
    }),
    switchMap((theCase) => {
      // If we have a case we see what is supported and has required screens amongst the submissions
      if (theCase === null) {
        return of([]);
      }
      // If case is submitted but has no submissions due to events not being added yet, send them to the Processing screen of SubmissionCompleted
      if (theCase.submissions.length === 0) {
        return of([
          {
            screen: submittedComponentNames.SubmissionCompleted,
            sdgDetermined: false,
            dgrResponse: null,
            submissionId: "",
          } as DataGatheringResultTracking,
        ]);
      }
      if (
        !theCase.activeSubmissions.length &&
        theCase.statusId == CaseStatusIds.INCOMPLETE &&
        theCase.reasonId == CaseReasonIds.Incomplete
      ) {
        return of([
          {
            screen: submittedComponentNames.DraftRedirect,
            sdgDetermined: false,
            dgrResponse: null,
            submissionId: "",
          } as DataGatheringResultTracking,
        ]);
      }
      // Loop through active submissions and see if it is has Scalable Data Gathering info, if not it defaults to case details
      const calls = theCase.activeSubmissions.map((submission) =>
        getScalableDataGatheringResult(theCase, submission, api)
      );
      if (!calls.length) {
        return of([]);
      }
      return forkJoin(calls);
    }),
    map((results) => {
      // Case is null, no submissions, no good
      if (results.length === 0) return null;
      // Everything Case Details? Use that
      if (
        results.every(
          (result) => result?.screen === submittedComponentNames.CaseDetails
        )
      ) {
        return submittedComponentNames.CaseDetails;
      }
      // If we are this far, theres a non-case details result. We should pick amongst those.
      const nonCaseDetailsResults = results.filter(
        (result) =>
          !!result && result.screen != submittedComponentNames.CaseDetails
      );

      // If we have an SDG Result, we use the first found one amongst the entries
      const firstSdgResult = nonCaseDetailsResults.find(
        (result) => !!result && result.sdgDetermined
      );
      if (!!firstSdgResult) {
        store.dispatch(
          "CaseModule/setDataGatheringId",
          firstSdgResult.dgrResponse!.dataGatheringScreen
        );
        store.dispatch(
          "CaseModule/setDataGatheringScreenData",
          firstSdgResult.dgrResponse!.dataGatheringScreenInitializationData
        );
        store.dispatch(
          "CaseModule/setDataGatheringSubmissionId",
          firstSdgResult.submissionId
        );
        return firstSdgResult.screen;
      }
      // At this point, we are just using the case status so grab the first one and roll with it.
      return nonCaseDetailsResults[0]!.screen;
    })
  );
};
export const getCaseComponentObservable = (
  orgId: string,
  caseId: string,
  mockProperty: string,
  api: Api
) => {
  return getCaseComponentPipe(
    getCaseSource(orgId, caseId, mockProperty, api),
    api
  ).pipe(
    map((c) => {
      if (store.getters["UserModule/isReadOnly"]) {
        // Read Only users will always be sent to the CaseDetails component
        return submittedComponentNames.CaseDetails;
      }

      store.dispatch("CaseModule/setShownSubmittedComponent", c);
      // Default to just letting the route go through as is
      return c;
    })
  );
};

// For the moment, this is used only for SubmissionCorrections. As long as we get a 200 we are good to start polling
export const submitCaseSource = (
  orgId: string,
  partnerCaseId: string,
  benefitManagerId: string,
  referral: Referral,
  mockProperty: string,
  api: Api
): Observable<AxiosResponse> => {
  const request = {
    referral,
    benefitManagerId,
  };
  return api.post(
    `${process.env.VUE_APP_RHYME_API}/organization/${orgId}/case?partnerCaseId=${partnerCaseId}`,
    request
  );
};

export const retryThrows = (source: Observable<AxiosResponse<any>>) => {
  let retryCount = 0;
  return source.pipe(
    retryWhen((errors) =>
      errors.pipe(
        delay(1000),
        tap((x) => {
          retryCount++;
          if (retryCount > 3) throw x;
        })
      )
    )
  );
};
export const baseRetry = (source: Observable<AxiosResponse<any>>) => {
  // Depends on the source being retryable. For $api calls, this means they must be wrapped in rxjs 'defer' as a factory
  return source.pipe(
    retryThrows,
    // If there is an error, send null instead
    catchError((x) => of(null))
  );
};

export const getFileDownloadURLSource = (
  file: RetrievableFileData,
  mocked: boolean,
  api: Api,
  organizationId: string,
  caseId: string
) => {
  return defer(() =>
    api.get<FileDownloadResponse>(
      `${process.env.VUE_APP_RHYME_API}/organization/${organizationId}/case/${caseId}/file/${file.fileData.fileId}`
    )
  );
};

export const getFileDownloadURLPipe = (
  source: Observable<AxiosResponse<FileDownloadResponse>>
): Observable<string> => {
  return source.pipe(
    baseRetry,
    map((response) => {
      if (!!response && !!response.data) return response.data.uri;
      return "";
    })
  );
};

export const getS3DownloadSource = (
  url: string,
  mocked: boolean
): Observable<AxiosResponse<ArrayBuffer>> => {
  return defer(() =>
    from(
      Axios.get<ArrayBuffer>(url, {
        responseType: "arraybuffer",
        headers: { Accept: "application/octet-stream" },
        timeout: 30000,
      })
    )
  );
};

export const getS3DownloadPipe = (
  source: Observable<AxiosResponse<ArrayBuffer>>
) => {
  return source.pipe(
    baseRetry,
    map((response: AxiosResponse<ArrayBuffer> | null) => {
      if (!!response && !!response.data) return response.data;
      return null;
    })
  );
};

export const getS3DownloadResponsePipe = (
  source: Observable<AxiosResponse>
): Observable<AxiosResponse<any> | null> => {
  return source.pipe(baseRetry);
};

export const validateAndSetDefaults = (
  api: Api,
  organizationId: string,
  partnerCaseId: string,
  request: Referral
): Observable<AxiosResponse> => {
  return defer(() =>
    api.post(
      `${process.env.VUE_APP_RHYME_API}/organization/${organizationId}/draft/${partnerCaseId}/validate?setDefaults=true`,
      request
    )
  );
};
