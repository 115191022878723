import { Patient } from './patient';
import { Provider } from './provider';
import { Diagnosis } from './diagnosis';
import { Facility } from './facility';
import { Service } from './service';
import { Castable } from '../Castable';
import { ServiceLevel } from './serviceLevel';
import { Subscriber } from './subscriber';
import { Contact} from './contact';
import { EmailContact } from './emailContact';
import { Metadata } from './metadata';

export enum TransactionType {
  PRIOR_AUTHORIZATION = "PRIOR_AUTHORIZATION",
  INPATIENT_NOTICE_OF_ADMISSION = "INPATIENT_NOTICE_OF_ADMISSION"
}

export class Referral extends Castable {
    static Cast(obj: Object) : Referral {
        const referral = super.Cast(Referral, obj);
        if (!!obj) referral.services = (obj['services'] || []).map(x => Service.Cast(x));
        if (!!obj) referral.unsubmittedServices = (obj['unsubmittedServices'] || []).map(x => Service.Cast(x));
        if(!!referral.contact){
            referral.contact = undefined;
        }
        return referral;
    }

    panPayerId?: string;
    serviceLevel?: ServiceLevel;
    subscriber: Subscriber = {} as Subscriber;
    patient: Patient = {} as Patient;
    
    // Soon to be obsolete ////
    contact?: Contact;
    ///////////////////////////

    contacts?: Array<EmailContact>;
    orderingProvider?: Provider;
    renderingProvider?: Provider;
    renderingFacility?: Facility;
    services: Array<Service> = [];
    unsubmittedServices: Array<Service> = []; // Internal technically but yknow lets keep it consistent
    diagnoses?: Array<Diagnosis>;
    metadata?: Metadata;
    
    //this is needed for NOA to function, it does not appear on the backend referral
    //its needs to be here for outgoing requests to draft, which is expecting a SubmitRequest object, which will have the transaction type
    //defaults to not defined 
    transactionType? : TransactionType | string;

    get serviceLevelComplete() : boolean {
        return !!this.serviceLevel && !!this.serviceLevel.serviceTypeCode && !!this.serviceLevel.requestType && !!this.serviceLevel.levelOfService && !!this.serviceLevel.placeOfService
    }
    get payerComplete() : boolean {
        return !!this.panPayerId && !!this.subscriber && !!this.subscriber.subscriberId && !!this.patient && !!this.patient.state
    }
    get servicesComplete() : boolean {
        return this.services.length > 0 && this.services.every((service) => !!service.code)
    }
    get precheckComplete() : boolean {
        return this.payerComplete && this.servicesComplete
    }
    get subscriberComplete() : boolean {
        return !!this.subscriber && !!this.subscriber.firstName && !!this.subscriber.lastName && !!this.subscriber.subscriberId
    }
    get patientComplete() : boolean {
        return !!this.patient && !!this.patient.firstName && !!this.patient.lastName && !!this.patient.state && !!this.patient.dob
    }
    get contactsComplete() : boolean {
        return !!this.contacts && this.contacts.length > 0 && this.contacts.every((contact) => !!contact.name && !!contact.phone && !!contact.email && !!contact.contactType)
    }
    get diagnosesComplete() : boolean {
        return !!this.diagnoses && this.diagnoses.length > 0 && this.diagnoses.every((diagnosis) => !!diagnosis.code)
    }
    get orderingProviderComplete() : boolean {
        const provider = this.orderingProvider
        return !!provider && !!provider.firstName && !!provider.lastName && !!provider.address1 && !!provider.city && !!provider.state && !!provider.npi && !!provider.zip
    }
    get renderingProviderComplete() : boolean {
        const provider = this.renderingProvider
        return !!provider && !!provider.firstName && !!provider.lastName && !!provider.address1 && !!provider.city && !!provider.state && !!provider.npi && !!provider.zip
    }
    get renderingFacilityComplete() : boolean {
        const facility = this.renderingFacility;
        return !!facility && !!facility.name && !!facility.address1 && !!facility.city && !!facility.npi && !!facility.state && !!facility.zip
    }


}