import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

// Colors here get used across SCSS variables as well;
export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#2F5233",
        primarydark: "#254128",
        rhymesecondary: "#ffffff",
        error: "#F07B61",
        warning: "#F7D133",
        accent: "#3fc25f",
      }
    }
  }
});