import { Castable } from './Castable';


export enum errorCodes {
    MISSING_DATA = "MISSING_DATA",
    TOO_LONG = "TOO_LONG",
    LENGTH_INVALID = "LENGTH_INVALID",
    FORMAT_ERROR = "FORMAT_ERROR",
    NOT_FOUND = "NOT_FOUND",
    ERROR = "ERROR"
}

export class ValidationError extends Castable{
    static Cast(obj) {
        return Castable.Cast(ValidationError, obj);
    }
    errorCode!: errorCodes;
    relatedTo!: string;
    message!: string;
    values?: string[] | undefined;

    forSection(section: string | Array<String>) : boolean {
        const toCheck = typeof section === 'string' ? [section] : section;
        const relatedTo = this.relatedTo.toLowerCase()
        return toCheck.some((toCheckItem) =>
        relatedTo.includes(toCheckItem.toLowerCase()))
    }

    forExactSection(section: string | Array<String>) : boolean {
        const toCheck = typeof section === 'string' ? [section] : section;
        return toCheck.some(toCheckItem => this.relatedTo.toLowerCase() === toCheckItem.toLowerCase());
    }
}

export class StandardResponse extends Castable {
    static Cast(obj: Object) : StandardResponse {
        const standardResponse = super.Cast(StandardResponse, obj);
        if (!!obj) standardResponse.errors = (obj['errors'] || []).map(x => ValidationError.Cast(x));
        return standardResponse;
    }
    valid: Boolean = false;
    timestamp!: string; //ISO DateTime plz
    errors: ValidationError[] = [];
}