import { Castable } from '../Castable';
import { CaseStatusIds, CaseReasonIds } from './case';

export class DecisionDataCode extends Castable {
    static Cast(obj: Object) : DecisionDataCode {
        return super.Cast(DecisionDataCode, obj);
    }
    code: string = '';
    units?: Number;
    authId?: string;
    statusId?: CaseStatusIds;
    reasonId?: CaseReasonIds;
    reasonDescription?: string = '';
    reasonDescriptionId?: string = '';
    startDate?: string; // ISO Date String Plz
    endDate?: string; //  ISO Date String Plz
    additionallyApprovedCodes: Array<string> = [];
    benefitManagerNote?: string;

    get getStartDate() : string | undefined {
        return this.startDate;
    }

    get getEndDate() : string | undefined {
        return this.endDate;
    }
}