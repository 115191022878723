






























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import rhymeLogo from "@/assets/rhyme-green-logo-web-1331.png";
import { PanPayerIdList, ROUTER_NAMES } from "@shared/constants";
import { downloadFile, rsoUrl } from "@/app/shared/utils";
import { forkJoin, of, Subject, zip } from "rxjs";
import { catchError, concatMap, map, switchMap, tap } from "rxjs/operators";

import { Organization } from "@/app/shared/components/standardAdmin/interfaces/interfaces";
import { AxiosResponse } from "axios";
import {
  baseRetry,
  getS3DownloadResponsePipe,
  getS3DownloadSource,
} from "@core/commonObservables";
import { PayerEntry } from "@shared/interfaces/draft/payerEntry";
import { submittedComponentNames } from "../submitted/interfaces/interfaces";

const UserModule = namespace("UserModule");
const GlobalModule = namespace("GlobalModule");
const CaseModule = namespace("CaseModule");
interface OrgInfo {
  name: string;
  organizationId: string;
  isReactTachyonEnabled: boolean;
}
@Component<Header>({
  created() {
    this.organizationInfoList$ = [];
  },
  mounted() {
    this.getOrganizationInfoSubject$.next(this.organizationIDs);
    this.payerEntrySubject$.next();
    this.$root.$on("survey-redirect", (redirectStatus) => {
      this.redirectStatus = !redirectStatus;
    });
  },
  subscriptions() {
    return {
      organizationInfoList$: this.getOrganizationInfoSubject$.pipe(
        switchMap((organizationIds) => {
          let organizationCalls = organizationIds.map((organizationId) => {
            return this.$api
              .get<Organization>(
                `${process.env.VUE_APP_RHYME_API}/organization/${organizationId}`
              )
              .pipe(baseRetry);
          });
          return forkJoin(organizationCalls);
        }),
        map((organizationInfoResponses) => {
          const nonNullResponses = organizationInfoResponses.reduce(
            (acc, curr) => {
              if (!!curr && !!curr.data) acc.push(curr);
              return acc;
            },
            [] as AxiosResponse<Organization>[]
          );
          return nonNullResponses.map<OrgInfo>((organizationInfoResponse) => {
            const organization = organizationInfoResponse.data;
            return {
              name: !!organization ? organization.name : "Unknown Org",
              organizationId: organization.organizationId,
              isReactTachyonEnabled: organization.isReactTachyonEnabled,
            };
          });
        }),
        tap((result) => {
          if (this.activeOrgID) {
            const organization = result.filter(
              (o) => o.organizationId == this.activeOrgID
            )[0];
            this.setOrganization(organization.name);
            this.setIsReactTachyonEnabled(organization.isReactTachyonEnabled);
          }
        })
      ),
      payerEntryObservable$: this.payerEntrySubject$.pipe(
        switchMap(() =>
          this.$api
            .get<Array<PayerEntry>>(
              `${process.env.VUE_APP_RHYME_API}/ontology/payer/flat`
            )
            .pipe(
              catchError((err) => {
                // eslint-disable-next-line no-console
                console.warn(`${err} while attempting to retrieve payer list`);
                return of(err.response);
              })
            )
        ),
        map((response) => {
          if (!!response && !!response.data) return response.data;
          else return PanPayerIdList; // fallback in case of an error... this shouldn't happen, but we're logging a warning above if it does
        }),
        tap((payerList) => {
          this.emitSavedPayerEntryList(payerList);
        })
      ),
      getHelpFiles$: this.getHelpFilesSubject$.pipe(
        concatMap((helpFileName) => {
          let file = getS3DownloadResponsePipe(
            getS3DownloadSource(
              `${process.env.VUE_APP_API_ACCESSIBLE_FILES}/${helpFileName}`,
              false
            )
          );
          return zip(file, of(helpFileName));
        }),
        tap(([file, helpFileName]) => {
          if (!!file && !!file.data) {
            // Handles the browser shenanigans of actually downloading a file
            downloadFile(file.headers["content-type"], file.data, helpFileName);
          }
        })
      ),
    };
  },
})
export default class Header extends Vue {
  @UserModule.State //if you omit the argument for the decorator, it will expect the property name matches a property in the state
  public name!: string;
  @UserModule.State //if you omit the argument for the decorator, it will expect the property name matches a property in the state
  public organization!: string;
  @UserModule.State
  public loggedIn!: boolean;
  @UserModule.State("embedded") //if you supply an argument, it will look for a property by that name in the state and map it to the class property
  public isEmbedded!: boolean;
  @UserModule.State
  public isAdmin!: boolean;
  @UserModule.State("activeOrgID")
  public userActiveOrgID!: string;
  @UserModule.Action("updateActiveOrgId")
  public setActiveOrgID!: (activeOrgID: string) => void;
  @UserModule.Action("updateIsReactTachyonEnabled")
  public setIsReactTachyonEnabled!: (isEnabled: boolean) => void;
  @UserModule.Action("updateUserHasOrgs")
  public setUserHasOrgs!: (hasOrgs: boolean) => void;
  @UserModule.Action("updateOrganization")
  public setOrganization!: (organization: string) => void;
  @UserModule.State("organizationIDs")
  public organizationIDs!: string[];
  @Watch("activeOrgID")
  watchActiveOrgId(newOrgId: string) {
    this.updateOrganization(newOrgId);
  }
  @GlobalModule.Action
  public emitSavedPayerEntryList!: (payerEntryList: Array<PayerEntry>) => void;
  @CaseModule.State
  shownSubmittedComponent!: submittedComponentNames | null;
  @CaseModule.Action
  public setShownSubmittedComponent!: (val: submittedComponentNames | null) => void;
  

  rhymeLogo: any = rhymeLogo;
  redirectStatus = true;

  public getOrganizationInfoSubject$: Subject<string[]> = new Subject<
    string[]
  >();
  public organizationInfoList$: OrgInfo[] = [];
  public payerEntrySubject$: Subject<Array<PayerEntry>> = new Subject<
    Array<PayerEntry>
  >();
  public getHelpFilesSubject$: Subject<string> = new Subject<string>();

  get activeOrgID() {
    return this.userActiveOrgID;
  }
  set activeOrgID(activeOrgID: string) {
    this.setActiveOrgID(activeOrgID);
  }

  get isCaseLookup(): boolean {
    return this.$route.name === ROUTER_NAMES.CaseLookup;
  }
  get clickableLogo(): boolean {
    // RSO Users can't have this
    return !rsoUrl();
  }

  get showOrgSelector() {
    return !this.isEmbedded;
  }

  get hasOrgNames() {
    return this.organizationInfoList$.length > 0;
  }

  get orgDropdownIsDisabled() {
    let dropdownEnabledRoutes: string[] = [
      ROUTER_NAMES.CaseLookup,
      ROUTER_NAMES.PrivateDraftCreate,
    ];
    return !dropdownEnabledRoutes.includes(this.$route.name ?? "");
  }

  get hideLogo() {
    return (
      this.$route.name === ROUTER_NAMES.Submitted &&
      this.shownSubmittedComponent === submittedComponentNames.Surveys
    );
  }

  get showBackBtn(): boolean {
    return this.shownSubmittedComponent === submittedComponentNames.Surveys && !rsoUrl()
  }

  updateOrganization(orgId) {
    const organizationInfoFound = this.organizationInfoList$.find(
      (o) => o.organizationId == orgId
    );
    if (!!organizationInfoFound && !!organizationInfoFound.name) {
      this.setOrganization(organizationInfoFound.name);
      this.setIsReactTachyonEnabled(
        organizationInfoFound.isReactTachyonEnabled
      );
    }
  }

  handleNavToSearch(): void {
    this.setShownSubmittedComponent(null)
     this.$router.push({ name: ROUTER_NAMES.CaseLookup });
  }

  logoClick() {
    if (!this.clickableLogo) return;
    if (!this.isCaseLookup)
      this.$router.push({ name: ROUTER_NAMES.CaseLookup });
  }
  goToAdmin() {
    if (this.isAdmin && this.$route.name != ROUTER_NAMES.Admin)
      this.$router.push({ name: ROUTER_NAMES.Admin });
  }
  downloadHelpFiles() {
    this.getHelpFilesSubject$.next("ProviderUserGuideWithLinks.pdf");
    this.getHelpFilesSubject$.next("PriorAuthNowFAQs.pdf");
  }

  @Watch("hasOrgNames")
  updateUserHasOrgs() {
    this.setUserHasOrgs(this.hasOrgNames);
  }
}
