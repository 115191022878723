import { format, parseISO } from "date-fns";
import { Route } from "vue-router";
import { IntegrationType } from "@/app/core/constants";
import { JWTBody } from "@/app/core/store/interfaces/interfaces";
import { ValidationError } from "../interfaces/standardResponses";

export const distinctByProperty = (array, property) =>
  [...new Set(array.map((element) => element[property]))].map(
    (distinctProperty) => ({
      ...array.find((element) => element[property] === distinctProperty),
    })
  );

export const downloadFile = (contentType, fileBlob, name) => {
  let filename = name;
  try {
    /* For Internet Explorer and it's nefarious brethrebbn */
    if ((navigator as any).msSaveBlob) {
      const blob = new Blob([fileBlob], { type: "application/octet-stream" });
      /* Check if the filename does not have an extension */
      if (!filename.match(/.+\.+/g)) {
        const fileExtension = contentType.substring(
          contentType.lastIndexOf("/") + 1
        );
        filename = `${filename}test.${fileExtension}`;
      }
      (navigator as any).msSaveBlob(blob, filename);
    } else {
      /* For Chrome and the civilized world */
      const url = URL.createObjectURL(
        new Blob([fileBlob], { type: contentType })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    }
  } catch (error) {
    throw new Error("Unable to download file");
  }
};

export const validationsExcludingSection = (
  validationsArray: Array<ValidationError> = [],
  section: string | Array<string>
): Array<ValidationError> => {
  return validationsArray.filter(
    (validationError) => !validationError.forSection(section)
  );
};
export const validationsForSection = (
  validationsArray: Array<ValidationError> = [],
  section: string | Array<string>
): Array<ValidationError> => {
  return validationsArray.filter((validationError) =>
    validationError.forSection(section)
  );
};
export const validationsForExactSection = (
  validationsArray: Array<ValidationError> = [],
  section: string | Array<string>
): Array<ValidationError> => {
  return validationsArray.filter((validationError) =>
    validationError.forExactSection(section)
  );
};
export const getExternalMessages = (
  externalValidations: Array<ValidationError>,
  field: string
): Array<string> => {
  return messagesForSection(externalValidations, field);
};
export const messagesForSection = (
  validationsArray: Array<ValidationError> = [],
  section: string | Array<string>
): Array<string> => {
  return validationsForSection(validationsArray, section).map(
    (validationError) => validationError.message
  );
};
export const messagesForExactSection = (
  validationsArray: Array<ValidationError> = [],
  section: string | Array<string>
): Array<string> => {
  return validationsForExactSection(validationsArray, section).map(
    (validationError) => validationError.message
  );
};

export const dateOnlyFormat = (date: string) => {
  if (!date) return "";
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );
  const returnDate = format(dtDateOnly, "MM/dd/yyyy");
  return returnDate;
};

export const dateStringToUTCFormat = (date: string) => {
  if (!date) return "";
  return new Date(`${date} UTC`).toISOString();
};

export const todayPlusDaysDate = (days: number): Date => {
  const todaysDate = new Date();
  return new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    todaysDate.getDate() + days
  );
};

export const dateToUTCFormatDate = (date: Date): Date => {
  return parseISO(
    dateStringToUTCFormat(
      `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`
    )
  );
};

export const isMocked = (route: Route): boolean => {
  return (
    process.env.VUE_APP_UI_VERSION?.toLowerCase() === "dev" &&
    !!route.query.isMock
  );
};

export const toUserFriendlyIntegrationName = (benefitManagerName: string) => {
  if (!benefitManagerName) return;
  const foundDisplayName =
    IntegrationType.DisplayName[benefitManagerName.toUpperCase()];
  return !!foundDisplayName ? foundDisplayName : benefitManagerName;
};

export const getUmIntegrationUrlFromRouteId = (routeId: string): string =>
  `https://${routeId}umintegration.${process.env.VUE_APP_BACKEND}.priorauthnow.com/jwt`.toLowerCase();

export const decodeJwtBody = (jwt: string): JWTBody => {
  if (!jwt) return {} as JWTBody;
  try {
    const sections = jwt.split(".");
    const parsed = JSON.parse(atob(sections[1]));
    return parsed as JWTBody;
  } catch {
    return {} as JWTBody;
  }
};

export const truncateDescription = (name: string): string => {
  if (name.length > 20) {
    const truncatedDescription = name.substring(0, 34);
    return `${truncatedDescription}...`;
  } else return name;
};

export const experianTokens = ["experianToken", "token"];
export const panRsoToken = "panToken";
export const uhcRsoToken = "panReferral";
export const isUhcRso = (): boolean =>
  window.location.href.toLowerCase().includes(uhcRsoToken.toLowerCase());
export const isPanRso = (): boolean =>
  window.location.href.toLowerCase().includes(panRsoToken.toLowerCase());
export const isExperianRso = (): boolean =>
  experianTokens.some((experianToken) =>
    window.location.href.toLowerCase().includes(experianToken.toLowerCase())
  );
export const rsoUrl = (): boolean =>
  isPanRso() || isExperianRso() || isUhcRso();

export const phoneFormat = (phone: string) =>
  !phone || phone.length !== 10
    ? ""
    : `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
