import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import { Auth } from "aws-amplify";
import store from "@core/store/store";
import { ROUTER_NAMES } from "@shared/constants";
import { getDraftRouteObservable } from "./commonObservables";
import { authGuard } from "../shared/utils/auth";
Vue.use(VueRouter);

const caseCreateRouteGuard = (to: Route, from: Route, next) => {
  store.dispatch("CaseModule/setOrganizationId", to.params.orgId);
  if (to.params.ignoreRouteGuard) {
    next();
  } else {
    getDraftRouteObservable(
      to.params.orgId,
      to.params.draftId,
      to,
      !!to.query.submitted,
      router.app.$api
    ).subscribe((val) => {
      !!val ? next(val) : next();
    });
  }
};

const routes = [
  {
    path: "/",
    name: ROUTER_NAMES.CaseLookup,
    meta: { requiresAuth: true },
    component: () => import("@views/caseLookup/CaseLookup.vue"),
  },
  {
    path: "/login",
    name: ROUTER_NAMES.Login,
    component: () => import("@views/login/Login.vue"),
  },
  {
    path: "/org/:orgId/case/:caseId",
    name: ROUTER_NAMES.Submitted,
    meta: { requiresAuth: true },
    component: () => import("@views/submitted/Submitted.vue"),
    props: true,
  },
  {
    path: "/confirmSubmission/:orgId/:draftId",
    name: ROUTER_NAMES.ConfirmSubmission,
    meta: { requiresAuth: true },
    beforeEnter: caseCreateRouteGuard,
    component: () => import("@views/confirmSubmission/ConfirmSubmission.vue"),
    props: true,
  },
  {
    path: "/draft/:orgId/:draftId",
    alias: "/embed/:orgId/:draftId",
    name: ROUTER_NAMES.Create,
    meta: { requiresAuth: true, betterFlow: false },
    beforeEnter: caseCreateRouteGuard,
    component: () => import("@views/create/Create.vue"),
    props: true,
  },
  {
    path: "/admin",
    name: ROUTER_NAMES.Admin,
    beforeEnter: (to: Route, from: Route, next) => {
      if (store.state["UserModule"].isAdmin) {
        next();
      } else {
        next({ name: ROUTER_NAMES.NotFound });
      }
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@views/admin/Admin.vue"),
  },
  {
    path: "/contactAdministrator",
    name: ROUTER_NAMES.ContactAdministrator,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "contactAdministrator" */ "@views/contactAdministrator/ContactAdministrator.vue"
      ),
  },
  {
    path: "/sessionExpired",
    name: ROUTER_NAMES.SessionExpired,
    component: () =>
      import(
        /* webpackChunkName: "sessionExpired" */ "@views/sessionExpired/SessionExpired.vue"
      ),
  },
  {
    path: "/draftCreate",
    name: ROUTER_NAMES.PrivateDraftCreate,
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "privateDraftCreate" */ "@views/privateDraftCreate/PrivateDraftCreate.vue"
      ),
  },
  {
    path: "/successful",
    name: ROUTER_NAMES.SuccessfulSurveySubmission,
    component: () => import("@views/iframe/SuccessfulSurveySubmission.vue"),
  },
  {
    path: "/*",
    name: ROUTER_NAMES.NotFound,
    component: () => import("@views/notFound/NotFound.vue"),
  },
];

const router: VueRouter = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(authGuard(Auth, router, store));
export default router;
