import { WaitingState } from './waitingstate';
import { DecisionData } from './decisionData';
import { ReopenReason } from './reopenReason';
import { CaseStatusIds } from './case';
import { Castable } from '../Castable';

export enum SubmissionState {
    INDETERMINATE = "INDETERMINATE",
    WAITING = "WAITING",
    COMPLETE = "COMPLETE",
}
export class SubmissionStatus extends Castable {
    static Cast(obj: Object) : SubmissionStatus {
        const submissionStatus = super.Cast(SubmissionStatus, obj);
        if (!!obj) submissionStatus.decisionData = DecisionData.Cast(obj['decisionData']);
        return submissionStatus;
    }
    status!: CaseStatusIds;
    state!: SubmissionState;
    waitingState?: WaitingState;
    decisionData?: DecisionData;
    reopenedData?: ReopenReason;
}