
























export interface TOSStatus {
  accepted: boolean;
}
export interface TOS {
  content: string;
  version: number;
  createdDate: string;
}
import { from, Observable, of, Subject, zip } from "rxjs";
import { map, pluck, switchMap, tap } from "rxjs/operators";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class/lib/bindings";
import { AxiosResponse } from "axios";
import { isMocked } from "@/app/shared/utils";
import { ROUTER_NAMES, SESSION_LENGTH } from "../constants";
import { rsoUrl } from "../utils";
const CaseModule = namespace("CaseModule");
const UserModule = namespace("UserModule");

@Component<TermsOfService>({
  mounted() {
    this.loadTOSModalSubject$.next(true);
  },
  subscriptions() {
    return {
      DisplayTos$: this.loadTOSModalSubject$.pipe(
        switchMap(() =>
          isMocked(this.$route)
            ? of({
                data: { accepted: false } as TOSStatus,
              } as AxiosResponse<TOSStatus>)
            : this.$api.get<TOSStatus>(
                `${process.env.VUE_APP_RHYME_API}/organization/${this.orgId}/user/${this.email}/terms`
              )
        ),
        map((response) => {
          if (!!response && response.data.accepted === false) {
            this.retrieveLatestTos$.next(true);
          }
          return !response.data.accepted;
        })
      ),
      LatestTOS$: this.retrieveLatestTos$.pipe(
        switchMap(() =>
          isMocked(this.$route)
            ? of({
                data: { content: "Mock terms" } as TOS,
              } as AxiosResponse<TOS>)
            : this.$api.get<TOS>(`${process.env.VUE_APP_RHYME_API}/terms`)
        ),
        map((response) => {
          if (!response || !response.data) return "";
          this.version = response.data.version;
          return response.data.content;
        })
      ),

      TOSAccepted$: this.TOSAcceptedSubject$.pipe(
        map((action) => {
          if (action === true) {
            this.$api.put(
              `${process.env.VUE_APP_RHYME_API}/organization/${this.orgId}/user/${this.email}/terms/version/${this.version}`
            );
          } else {
            this.$router.push({
              name: rsoUrl() ? ROUTER_NAMES.SessionExpired : ROUTER_NAMES.Login,
            });
          }
          this.DisplayTos$ = false;
        })
      ),
    };
  },
})
export default class TermsOfService extends Vue {
  public loadTOSModalSubject$: Subject<boolean> = new Subject<boolean>();
  public TOSAcceptedSubject$: Subject<boolean> = new Subject<boolean>();
  public retrieveLatestTos$: Subject<boolean> = new Subject<boolean>();
  public DisplayTos$ = false;
  public version;

  @UserModule.State //if you omit the argument for the decorator, it will expect the property name matches a property in the state
  public email!: string;

  @UserModule.State
  public activeOrgID!: string;

  @CaseModule.State
  public organizationId!: string;

  public TOSAccepted(action: boolean): void {
    this.TOSAcceptedSubject$.next(action);
  }
  get show(): boolean {
    return this.DisplayTos$;
  }
  get orgId(): string {
    return rsoUrl() ? this.organizationId : this.activeOrgID;
  }
}
