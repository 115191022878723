export enum submittedComponentNames {
  ConnectingToPayer = "ConnectingToPayer",
  SubmissionCompleted = "SubmissionCompleted",
  Surveys = "Surveys",
  ClinicalRequired = "ClinicalRequired",
  CaseDetails = "CaseDetails",
  SubmissionCorrections = "SubmissionCorrections",
  DraftRedirect = "DraftRedirect",
  NoaFileUpload = "NoaFileUpload",
  ContinueStayCaseDetails = "ContinueStayCaseDetails",
  ContinuedStayReview = "ContinuedStayReview",
  ExternalActionRequired = "ExternalActionRequired",
  EnhancedProviderSelection = "EnhancedProviderSelection",
  RetroRequestCorrection = "RetroRequestCorrection",
}
