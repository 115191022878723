var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header_base_flex flex-rowed-leftcentered"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"my-2"},[(_vm.showBackBtn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.handleNavToSearch()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"#636363"}},[_vm._v("mdi-arrow-left-bold-circle")])],1)]}}],null,false,2471033059)},[_c('span',[_vm._v("Navigate to Live Auth Search")])]):_vm._e()],1)]),_c('div',{class:[{ pointer_cursor: _vm.clickableLogo }, 'header__logo', 'flex--20']},[(!_vm.hideLogo)?_c('img',{staticClass:"header__logo",attrs:{"src":_vm.rhymeLogo},on:{"click":function($event){return _vm.logoClick()}}}):_vm._e()]),_c('div',{staticClass:"flex--60"}),(_vm.redirectStatus)?_c('div',{staticClass:"flex--20 flex-columned"},[_c('div',{staticClass:"flex-rowed-centered"},[(_vm.isEmbedded)?_c('div',{staticClass:"header__embedded flex--90 flex-columned"},[_c('div',{staticClass:"header__name flex-rowed-rightcentered"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"flex-rowed-rightcentered"},[_vm._v(_vm._s(_vm.organization))])]):_c('v-menu',{staticClass:"header_profile",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({class:[
              { pointer_cursor: _vm.clickableLogo },
              'flex--90 flex-columned' ]},on),[_c('div',{staticClass:"header__name"},[_vm._v(_vm._s(_vm.name))]),_c('div',[_vm._v(_vm._s(_vm.organization))])])]}}],null,false,2135770773)},[_c('v-list',{staticClass:"profile-list"},[(_vm.isAdmin)?_c('v-list-item',{staticClass:"profile-list-item flex-rowed-centered"},[_c('v-btn',{on:{"click":function($event){return _vm.goToAdmin()}}},[_vm._v("Admin")])],1):_vm._e(),_c('v-list-item',{staticClass:"profile-list-item flex-rowed-centered"},[(_vm.loggedIn && !_vm.isEmbedded)?_c('amplify-sign-out'):_vm._e()],1)],1)],1),_c('div',{staticClass:"flex-rowed-centered flex--10"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadHelpFiles()}}},[_c('v-icon',{attrs:{"large":"","color":"gray"}},[_vm._v("mdi-help-circle")])],1)],1)],1),(_vm.showOrgSelector)?_c('div',{staticClass:"org-selector"},[(_vm.hasOrgNames)?_c('v-select',{attrs:{"items":_vm.organizationInfoList$,"item-text":"name","item-value":"organizationId","label":"Organization","outlined":"","dense":"","disabled":_vm.orgDropdownIsDisabled},model:{value:(_vm.activeOrgID),callback:function ($$v) {_vm.activeOrgID=$$v},expression:"activeOrgID"}}):_c('div',{staticClass:"flex-rowed-centered"},[_c('div',[_vm._v("Retrieving Organization Info")]),_c('v-progress-circular',{staticClass:"loading-orgs-spinner",attrs:{"indeterminate":""}})],1)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }