import { PayerEntry } from "./interfaces/draft/payerEntry";

export const SESSION_LENGTH = 60 * 60 * 1000; // 60 Minutes in milliseconds
export enum ROUTER_NAMES {
  Rso = "rso",
  About = "about",
  Login = "login",
  Submitted = "submitted",
  Create = "create",
  ConfirmSubmission = "confirm-submission",
  SessionExpired = "sessionExpired",
  NotFound = "not-found",
  Supported = "supported",
  CaseLookup = "case-lookup",
  Admin = "admin",
  ContactAdministrator = "contact-administrator",
  PrivateDraftCreate = "private-draft-create",
  Successful = "successful",
  ClinicalDoc = "clinical-doc",
  SuccessfulSurveySubmission = "successful-survey-submission",
}

export const NineSequentialDigitsRegex: RegExp = /^(?=(\D*\d){9}\D*$)[\d]*$/;

export interface usState {
  displayText: string;
  value: string;
}

export const usStates: Array<usState> = [
  { displayText: "Alabama", value: "AL" },
  { displayText: "Alaska", value: "AK" },
  { displayText: "Arizona", value: "AZ" },
  { displayText: "Arkansas", value: "AR" },
  { displayText: "California", value: "CA" },
  { displayText: "Colorado", value: "CO" },
  { displayText: "Connecticut", value: "CT" },
  { displayText: "Delaware", value: "DE" },
  { displayText: "District of Columbia", value: "DC" },
  { displayText: "Florida", value: "FL" },
  { displayText: "Georgia", value: "GA" },
  { displayText: "Hawaii", value: "HI" },
  { displayText: "Idaho", value: "ID" },
  { displayText: "Illinois", value: "IL" },
  { displayText: "Indiana", value: "IN" },
  { displayText: "Iowa", value: "IA" },
  { displayText: "Kansas", value: "KS" },
  { displayText: "Kentucky", value: "KY" },
  { displayText: "Louisiana", value: "LA" },
  { displayText: "Maine", value: "ME" },
  { displayText: "Maryland", value: "MD" },
  { displayText: "Massachusetts", value: "MA" },
  { displayText: "Michigan", value: "MI" },
  { displayText: "Minnesota", value: "MN" },
  { displayText: "Mississippi", value: "MS" },
  { displayText: "Missouri", value: "MO" },
  { displayText: "Montana", value: "MT" },
  { displayText: "Nebraska", value: "NE" },
  { displayText: "Nevada", value: "NV" },
  { displayText: "New Hampshire", value: "NH" },
  { displayText: "New Jersey", value: "NJ" },
  { displayText: "New Mexico", value: "NM" },
  { displayText: "New York", value: "NY" },
  { displayText: "North Carolina", value: "NC" },
  { displayText: "North Dakota", value: "ND" },
  { displayText: "Ohio", value: "OH" },
  { displayText: "Oklahoma", value: "OK" },
  { displayText: "Oregon", value: "OR" },
  { displayText: "Pennsylvania", value: "PA" },
  { displayText: "Rhode Island", value: "RI" },
  { displayText: "South Carolina", value: "SC" },
  { displayText: "South Dakota", value: "SD" },
  { displayText: "Tennessee", value: "TN" },
  { displayText: "Texas", value: "TX" },
  { displayText: "Utah", value: "UT" },
  { displayText: "Vermont", value: "VT" },
  { displayText: "Virginia", value: "VA" },
  { displayText: "Washington", value: "WA" },
  { displayText: "West Virginia", value: "WV" },
  { displayText: "Wisconsin", value: "WI" },
  { displayText: "Wyoming", value: "WY" },
];

export const PanPayerIdList: Array<PayerEntry> = [
  { id: "PAN.1199NB", name: `1199 National Benefit Fund` },
  { id: "PAN.AARP", name: `AARP Medicare Complete (UHC)` },
  { id: "PAN.ATCHC", name: `Absolute Total Care - Healthy Connections` },
  { id: "PAN.ABA", name: `Advantek Benefit Administrators` },
  { id: "PAN.AETNA", name: `Aetna` },
  { id: "PAN.ABH", name: `Aetna Better Health` },
  { id: "PAN.ABHCA", name: `Aetna Better Health of California` },
  { id: "PAN.ABHFL", name: `Aetna Better Health of Florida` },
  { id: "PAN.ABHIL", name: `Aetna Better Health of Illinois` },
  { id: "PAN.ABHKY", name: `Aetna Better Health of Kentucky` },
  { id: "PAN.ABHLA", name: `Aetna Better Health of Louisiana` },
  { id: "PAN.ABHMD", name: `Aetna Better Health of Maryland` },
  { id: "PAN.ABHMI", name: `Aetna Better Health of Michigan` },
  { id: "PAN.ABHNJ", name: `Aetna Better Health of New Jersey` },
  { id: "PAN.ABHOH", name: `Aetna Better Health of Ohio` },
  { id: "PAN.ABHPA", name: `Aetna Better Health of Pennsylvania` },
  { id: "PAN.ABHTX", name: `Aetna Better Health of Texas` },
  { id: "PAN.ABHVA", name: `Aetna Better Health of Virginia` },
  { id: "PAN.ABHWV", name: `Aetna Better Health of West Virginia` },
  { id: "PAN.AETNAMCR", name: `Aetna Medicare` },
  { id: "PAN.AHP", name: `Affinity Health Plan` },
  { id: "PAN.AGEWELL", name: `AgeWell New York Health Plans` },
  { id: "PAN.ALMCAID", name: `Alabama Medicaid` },
  { id: "PAN.AAFH", name: `Alameda Alliance for Health` },
  { id: "PAN.AKMCAID", name: `Alaska Medicaid` },
  { id: "PAN.ABPM", name: `Allegiance Benefit Plan Management` },
  { id: "PAN.ALLIANT", name: `Alliant Health Plans` },
  { id: "PAN.ABS", name: `Allied Benefit Systems` },
  { id: "PAN.ALLWAYS", name: `AllWays Health Partners` },
  { id: "PAN.ALLWELL", name: `Allwell` },
  { id: "PAN.AWATC", name: `Allwell from Absolute Total Care` },
  { id: "PAN.AWAZCH", name: `Allwell from Arizona Complete Health` },
  { id: "PAN.AWARHW", name: `Allwell from Arkansas Health & Wellness` },
  { id: "PAN.AWBHP", name: `Allwell from Buckeye Health Plan` },
  { id: "PAN.AWHSH", name: `Allwell from Home State Health` },
  { id: "PAN.AWICH", name: `Allwell from IlliniCare Health` },
  { id: "PAN.AWLHC", name: `Allwell from Louisiana Healthcare Connections` },
  { id: "PAN.AWMH", name: `Allwell from Magnolia Health` },
  { id: "PAN.AWMHSWI", name: `Allwell from MHS Health Wisconsin` },
  { id: "PAN.AWMHSIN", name: `Allwell from MHS Indiana` },
  { id: "PAN.AWPAHW", name: `Allwell from PA Health and Wellness` },
  { id: "PAN.AWPSHP", name: `Allwell from Peach State Health Plan` },
  { id: "PAN.AWSUNHP", name: `Allwell from Sunflower Health Plan` },
  { id: "PAN.AWSH", name: `Allwell from Sunshine Health` },
  { id: "PAN.AWSUPHP", name: `Allwell from Superior HealthPlan` },
  { id: "PAN.AWWSCC", name: `Allwell from Western Sky Community Care` },
  { id: "PAN.ALOHA", name: `AlohaCare` },
  { id: "PAN.AMB", name: `Ambetter` },
  { id: "PAN.AMBATC", name: `Ambetter from Absolute Total Care` },
  { id: "PAN.AMBAZCH", name: `Ambetter from Arizona Complete Health` },
  { id: "PAN.AMBARHW", name: `Ambetter from Arkansas Health & Wellness` },
  { id: "PAN.AMBBHP", name: `Ambetter from Buckeye Health Plan` },
  { id: "PAN.AMBCC", name: `Ambetter from Coordinated Care` },
  { id: "PAN.AMBHSH", name: `Ambetter from Home State Health` },
  { id: "PAN.AMBICH", name: `Ambetter from IlliniCare Health` },
  { id: "PAN.AMBMH", name: `Ambetter from Magnolia Health` },
  { id: "PAN.AMBMHS", name: `Ambetter from MHS` },
  { id: "PAN.AMBNHHF", name: `Ambetter from NH Healthy Families` },
  { id: "PAN.AMBPAHW", name: `Ambetter from PA Health and Wellness` },
  { id: "PAN.AMBPSHP", name: `Ambetter from Peach State Health Plan ` },
  { id: "PAN.AMBSSHP", name: `Ambetter from SilverSummit Healthplan` },
  { id: "PAN.AMBSUNHP", name: `Ambetter from Sunflower Health Plan` },
  { id: "PAN.AMBSUNH", name: `Ambetter from Sunshine Health` },
  { id: "PAN.AMBSUPHP", name: `Ambetter from Superior HealthPlan` },
  { id: "PAN.AMBNC", name: `Ambetter of North Carolina` },
  { id: "PAN.AMBTN", name: `Ambetter of Tennessee` },
  { id: "PAN.AFCSC", name: `Americas 1st Choice of SC` },
  { id: "PAN.AMRGRP", name: `Amerigroup` },
  { id: "PAN.AMRGRPAZ", name: `Amerigroup Arizona` },
  { id: "PAN.AMRGRPDC", name: `Amerigroup D.C` },
  { id: "PAN.AMRGRPFL", name: `Amerigroup Florida` },
  { id: "PAN.AMRGRPGA", name: `Amerigroup Georgia` },
  { id: "PAN.AMRGRPIA", name: `Amerigroup Iowa` },
  { id: "PAN.AMRGRPMD", name: `Amerigroup Maryland` },
  { id: "PAN.AMRGRPNJ", name: `Amerigroup New Jersey` },
  { id: "PAN.AMRGRPNM", name: `Amerigroup New Mexico` },
  { id: "PAN.AMRGRPTN", name: `Amerigroup Tennessee` },
  { id: "PAN.AMRGRPTX", name: `Amerigroup Texas` },
  { id: "PAN.AMRGRPWA", name: `Amerigroup Washington` },
  { id: "PAN.AMHLTH", name: `AmeriHealth` },
  { id: "PAN.AMHLTHDC", name: `AmeriHealth Caritas D.C.` },
  { id: "PAN.AMHLTHDE", name: `AmeriHealth Caritas Delaware` },
  { id: "PAN.AMHLTHLA", name: `AmeriHealth Caritas Louisiana` },
  { id: "PAN.AMHLTHNE", name: `AmeriHealth Caritas Northeast` },
  { id: "PAN.AMHLTHPA", name: `AmeriHealth Caritas Pennsylvania` },
  { id: "PAN.AHCARVIP", name: `AmeriHealth Caritas VIP Care Plus` },
  { id: "PAN.AMHLTHNJ", name: `AmeriHealth of New Jersey` },
  { id: "PAN.AHLTHVIP", name: `AmeriHealth VIP Care` },
  { id: "PAN.AMIDA", name: `Amida Care` },
  { id: "PAN.ANTHEM", name: `Anthem` },
  { id: "PAN.ANTHASOC", name: `Anthem Associates` },
  { id: "PAN.ANTHEMCA", name: `Anthem BC of California` },
  {
    id: "PAN.ANTHMHIP",
    name: `Anthem BCBS of IN Medicaid - Healthy Indiana Plan`,
  },
  {
    id: "PAN.ANTHMHCC",
    name: `Anthem BCBS of IN Medicaid - Hoosier Care Connect`,
  },
  {
    id: "PAN.ANTHMHHW",
    name: `Anthem BCBS of IN Medicaid - Hoosier Healthwise`,
  },
  { id: "PAN.ANTHEMMB", name: `Anthem MediBlue` },
  { id: "PAN.ANTHMMCR", name: `Anthem Medicare Advantage` },
  {
    id: "PAN.ANMCRAIB",
    name: `Anthem Medicare Advantage - Individual Business`,
  },
  { id: "PAN.ANTHEMNA", name: `Anthem National` },
  { id: "PAN.ANTHEMGM", name: `Anthem National Accounts Auto GM and Chrysler` },
  { id: "PAN.ANTHEMCO", name: `Anthem of Colorado` },
  { id: "PAN.ANTHEMCT", name: `Anthem of Connecticut` },
  { id: "PAN.ANTHEMIN", name: `Anthem of Indiana` },
  { id: "PAN.ANTHEMKY", name: `Anthem of Kentucky` },
  { id: "PAN.ANKYMCAID", name: `Anthem of Kentucky Medicaid` },
  { id: "PAN.ANTHEMME", name: `Anthem of Maine` },
  { id: "PAN.ANTHEMMO", name: `Anthem of Missouri` },
  { id: "PAN.ANTHEMNV", name: `Anthem of Nevada` },
  { id: "PAN.ANNVMCAID", name: `Anthem of Nevada - Medicaid` },
  { id: "PAN.ANTHEMNH", name: `Anthem of New Hampshire` },
  { id: "PAN.ANTHEMOH", name: `Anthem of Ohio` },
  { id: "PAN.ANTHEMVA", name: `Anthem of Virginia` },
  { id: "PAN.ANVAHKP", name: `Anthem of Virginia HealthKeepers Plus` },
  { id: "PAN.ANTHEMWI", name: `Anthem of Wisconsin` },
  { id: "PAN.ANWIMCAID", name: `Anthem of Wisconsin Medicaid` },
  { id: "PAN.APWUUHC", name: `APWU Consumer Driven UHC` },
  { id: "PAN.APWU", name: `APWU Health Plan` },
  { id: "PAN.APWUCI", name: `APWU High Option Cigna` },
  { id: "PAN.ARISE", name: `Arise Health Plan` },
  { id: "PAN.AZCH", name: `Arizona Complete Health` },
  { id: "PAN.AZMCAID", name: `Arizona Medicaid` },
  { id: "PAN.ARHW", name: `Arkansas Health & Wellness` },
  { id: "PAN.ARMCAID", name: `Arkansas Medicaid` },
  { id: "PAN.ARTC", name: `Arkansas Total Care` },
  { id: "PAN.ASURISNW", name: `Asuris Northwest Health` },
  { id: "PAN.ATRIO", name: `ATRIO Health Plans` },
  { id: "PAN.AULTCARE", name: `AultCare` },
  { id: "PAN.AUTOBS", name: `Automated Benefit Services` },
  { id: "PAN.AVERA", name: `Avera Health Plans` },
  { id: "PAN.AVMED", name: `AvMed` },
  { id: "PAN.BHN", name: `Banner Health Network` },
  { id: "PAN.BCID", name: `BC of Idaho` },
  { id: "PAN.BCBSBCNMI", name: `BCBS Blue Care Network of Michigan` },
  { id: "PAN.BCBSFEPIL", name: `BCBS FEP - Illinois` },
  { id: "PAN.BCBSFEPTX", name: `BCBS FEP - Texas` },
  { id: "PAN.BCBSAL", name: `BCBS of Alabama` },
  { id: "PAN.BCBSAZ", name: `BCBS of Arizona` },
  { id: "PAN.BCBSAR", name: `BCBS of Arkansas` },
  { id: "PAN.BCBSGA", name: `BCBS of Georgia` },
  { id: "PAN.BCBSIL", name: `BCBS of Illinois` },
  { id: "PAN.BCBSILGOV", name: `BCBS of Illinois` },
  { id: "PAN.BCBSILCH", name: `BCBS of Illinois Community Health Plan` },
  { id: "PAN.BCBSILMCR", name: `BCBS of Illinois Medicare PPO` },
  { id: "PAN.BCBSILPPO", name: `BCBS of Illinois PPO` },
  {
    id: "PAN.BCBSILBCS",
    name: `BCBS of Illinois PPO - Blue Choice Select PPO`,
  },
  {
    id: "PAN.BCBSILBO",
    name: `BCBS of Illinois PPO - Blue Options (Small Groups)`,
  },
  { id: "PAN.BCBSILBS", name: `BCBS of Illinois PPO - Blue Security PPO` },
  { id: "PAN.BCBSILBC", name: `BCBS of Illinois PPO - BlueChoice PPO` },
  { id: "PAN.BCBSILBCP", name: `BCBS of Illinois PPO - BlueChoice Preferred` },
  {
    id: "PAN.BCBSILBCSVC",
    name: `BCBS of Illinois PPO - BlueChoice Select Value Choice`,
  },
  { id: "PAN.BCBSILHCA", name: `BCBS of Illinois PPO - BlueEdge HCA` },
  { id: "PAN.BCBSILHSA", name: `BCBS of Illinois PPO - BlueEdge HSA` },
  { id: "PAN.BCBSILBP", name: `BCBS of Illinois PPO - BluePrint` },
  {
    id: "PAN.BCBSILHASBCO",
    name: `BCBS of Illinois PPO - Health Advocacy Solutions Blue Choice Options PPO`,
  },
  {
    id: "PAN.BCBSILHASBCS",
    name: `BCBS of Illinois PPO - Health Advocacy Solutions Blue Choice Select PPO`,
  },
  {
    id: "PAN.BCBSILHAS",
    name: `BCBS of Illinois PPO - Health Advocacy Solutions PPO`,
  },
  { id: "PAN.BCBSILPVC", name: `BCBS of Illinois PPO - PPO Value Choice` },
  {
    id: "PAN.BCBSILWBCO",
    name: `BCBS of Illinois PPO - Wellbeing Management Blue Choice Options PPO`,
  },
  {
    id: "PAN.BCBSILWBCS",
    name: `BCBS of Illinois PPO - Wellbeing Management Blue Choice Select PPO`,
  },
  {
    id: "PAN.BCBSILWCMM",
    name: `BCBS of Illinois PPO - Wellbeing Management Comprehensive Major Medical`,
  },
  {
    id: "PAN.BCBSILWM",
    name: `BCBS of Illinois PPO - Wellbeing Management PPO`,
  },
  { id: "PAN.BCBSKS", name: `BCBS of Kansas` },
  { id: "PAN.BCBSKC", name: `BCBS of Kansas City` },
  { id: "PAN.BCBSLA", name: `BCBS of Louisiana` },
  { id: "PAN.BCBSLAGB", name: `BCBS of Louisiana - Office of Group Benefits` },
  { id: "PAN.BCBSMA", name: `BCBS of Massachusetts` },
  { id: "PAN.BCBSMAA", name: `BCBS of Massachusetts Associates` },
  { id: "PAN.BCBSMAMA", name: `BCBS of Massachusetts Medicare Advantage` },
  { id: "PAN.BCBSMI", name: `BCBS of Michigan` },
  { id: "PAN.BCBSMN", name: `BCBS of Minnesota` },
  { id: "PAN.BCBSMS", name: `BCBS of Mississippi` },
  { id: "PAN.BCBSMT", name: `BCBS of Montana` },
  { id: "PAN.BCBSNE", name: `BCBS of Nebraska` },
  { id: "PAN.BCBSNM", name: `BCBS of New Mexico` },
  { id: "PAN.BCBSNC", name: `BCBS of North Carolina` },
  {
    id: "PAN.BCBSNCSHPM",
    name: `BCBS of North Carolina - State Health Plan Members`,
  },
  { id: "PAN.BCBSND", name: `BCBS of North Dakota` },
  { id: "PAN.BCBSOK", name: `BCBS of Oklahoma` },
  { id: "PAN.BCBSRI", name: `BCBS of Rhode Island` },
  { id: "PAN.BCBSSC", name: `BCBS of South Carolina` },
  { id: "PAN.BCBSTN", name: `BCBS of Tennessee` },
  { id: "PAN.BCBSTNBAP", name: `BCBS of Tennessee - BlueAdvantage Plus PPO` },
  { id: "PAN.BCBSTNBA", name: `BCBS of Tennessee - BlueAdvantage PPO` },
  { id: "PAN.BCBSTNHMO", name: `BCBS of Tennessee - BlueCare Plus HMO-SNP` },
  { id: "PAN.BCBSTNBC", name: `BCBS of Tennessee - BlueCare ` },
  { id: "PAN.BCBSTNCK", name: `BCBS of Tennessee - CoverKids ` },
  { id: "PAN.BCBSTNTCS", name: `BCBS of Tennessee - TennCare Select` },
  { id: "PAN.BCBSTX", name: `BCBS of Texas` },
  {
    id: "PAN.BCBSTXPFI",
    name: `BCBS of Texas - Blue Choice PPO Fully Insured`,
  },
  {
    id: "PAN.BCBSTXSIHAS",
    name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions`,
  },
  {
    id: "PAN.BCBSTXSIHASA",
    name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions Advanced`,
  },
  {
    id: "PAN.BCBSTXSIHASP",
    name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions Premier`,
  },
  {
    id: "PAN.BCBSTXSIWOHAS",
    name: `BCBS of Texas - Blue Choice PPO Self Insured without Health Advocacy Solutions`,
  },
  {
    id: "PAN.BCBSTXEA",
    name: `BCBS of Texas - Blue Essentials and Blue Essentials Access`,
  },
  { id: "PAN.BCBSTXMA", name: `BCBS of Texas - Medicare Advantage PPO` },
  { id: "PAN.BCBSTXSC", name: `BCBS of Texas - Star and Chip` },
  { id: "PAN.BCBSTXSKM", name: `BCBS of Texas - Star Kids Medicaid` },
  {
    id: "PAN.BCBSTXMCAREA",
    name: `BCBS of Texas - Medicare Advantage HMO & SNP Plans`,
  },
  { id: "PAN.BCBSVT", name: `BCBS of Vermont` },
  { id: "PAN.BCBSVTCBA", name: `BCBS of Vermont - CBA` },
  { id: "PAN.BCBSWNY", name: `BCBS of Western New York` },
  { id: "PAN.BCBSWNYMCAID", name: `BCBS of Western New York - Medicaid` },
  { id: "PAN.BCBSWY", name: `BCBS of Wyoming` },
  { id: "PAN.BLCRSS", name: `Blue Cross` },
  { id: "PAN.BLSHLD", name: `Blue Shield` },
  { id: "PAN.BSCA", name: `Blue Shield of California` },
  { id: "PAN.BSCAPHP", name: `Blue Shield of California Promise Health Plan` },
  { id: "PAN.BADAAR", name: `BlueAdvantage Administrators of Arkansas` },
  { id: "PAN.BCHPSC", name: `BlueChoice HealthPlan of South Carolina` },
  { id: "PAN.BCBS", name: `BlueCross BlueShield` },
  { id: "PAN.BCBSFEP", name: `BlueCross BlueShield Federal Employee Plan` },
  { id: "PAN.BSNENY", name: `BlueShield of Northeastern New York` },
  { id: "PAN.BMCHNP", name: `Boston Medical Center HealthNet Plan` },
  { id: "PAN.BSPH", name: `BridgeSpan Health` },
  { id: "PAN.BRTH", name: `Bright Health` },
  { id: "PAN.BKEYEHP", name: `Buckeye Health Plan` },
  { id: "PAN.CALHW", name: `California Health & Wellness` },
  { id: "PAN.CALOPT", name: `CalOptima` },
  { id: "PAN.CAPBC", name: `Capital BC` },
  { id: "PAN.CAPHP", name: `Captial Health Plan` },
  { id: "PAN.CIPSC", name: `Care Improvement Plus South Central` },
  { id: "PAN.C1HPAZ", name: `Care1st Health Plan of Arizona` },
  { id: "PAN.CFBCBS", name: `CareFirst BCBS` },
  { id: "PAN.CAREMO", name: `CareMore Health - Cal MediConnect` },
  { id: "PAN.CAREOR", name: `CareOregon Advantage` },
  { id: "PAN.CAREPLHP", name: `CarePlus Health Plans` },
  { id: "PAN.CARESO", name: `CareSource` },
  { id: "PAN.CARESOGA", name: `CareSource of Georgia` },
  { id: "PAN.CARESOIN", name: `CareSource of Indiana` },
  { id: "PAN.CARESOKY", name: `CareSource of Kentucky - Humana` },
  { id: "PAN.CARESOOH", name: `CareSource of Ohio` },
  { id: "PAN.CARSOWVA", name: `CareSource of West Virginia` },
  { id: "PAN.CDPHP", name: `CDPHP - Capital District Physicians Health Plan` },
  { id: "PAN.CENCAL", name: `CenCal Health` },
  { id: "PAN.CCAFH", name: `Central California Alliance For Health` },
  { id: "PAN.CHMP", name: `Central Health Medicare Plan` },
  { id: "PAN.CCHP", name: `Children's Community Health Plan` },
  { id: "PAN.CHRISTUS", name: `CHRISTUS Health` },
  {
    id: "PAN.CHRSTUSLA",
    name: `CHRISTUS Health - Louisiana Family and Individual Plans`,
  },
  { id: "PAN.CHRSTUSMCR", name: `CHRISTUS Health - Medicare Advantage` },
  {
    id: "PAN.CHRSTUSNM",
    name: `CHRISTUS Health - New Mexico Family and Individual Plans`,
  },
  { id: "PAN.CHRSTUSTX", name: `CHRISTUS Health - Texas HIX` },
  { id: "PAN.CHRISTUSFHP", name: `CHRISTUS Health - US Family Health Plan` },
  { id: "PAN.CIGNA", name: `Cigna` },
  { id: "PAN.CIGNAHS", name: `Cigna HealthSpring` },
  { id: "PAN.CLEARA", name: `Clear Alliance` },
  { id: "PAN.COACC", name: `Colorado Access` },
  { id: "PAN.COMCAID", name: `Colorado Medicaid` },
  { id: "PAN.CMWCA", name: `Commonwealth Care Alliance` },
  { id: "PAN.COMHC", name: `Community Health Choice` },
  { id: "PAN.COMHG", name: `Community Health Group` },
  { id: "PAN.COMHPW", name: `Community Health Plan of Washington` },
  {
    id: "PAN.COMHFMCR",
    name: `Community HealthFirst Medicare Advantage Plans`,
  },
  { id: "PAN.COMCAR", name: `CommunityCare` },
  { id: "PAN.CTCARE", name: `ConnectiCare` },
  { id: "PAN.CTCAREMA", name: `ConnectiCare Medicare Advantage` },
  { id: "PAN.CTCAREMCAID", name: `Connecticut Medicaid` },
  { id: "PAN.COCOHP", name: `Contra Costa Health Plan` },
  { id: "PAN.COOKCH", name: `Cook Children's Health Plan` },
  { id: "PAN.COORDCARE", name: `Coordinated Care` },
  { id: "PAN.COUNTY", name: `CountyCare` },
  { id: "PAN.COVHC", name: `Coventry Health Care` },
  { id: "PAN.COVHCDE", name: `Coventry Health Care of Delaware` },
  { id: "PAN.COVHCFL", name: `Coventry Health Care of Florida` },
  { id: "PAN.COVHCGA", name: `Coventry Health Care of Georgia` },
  { id: "PAN.COVHCIL", name: `Coventry Health Care of Illinois` },
  { id: "PAN.COVHCIA", name: `Coventry Health Care of Iowa` },
  { id: "PAN.COVHCKS", name: `Coventry Health Care of Kansas` },
  { id: "PAN.COVHCMO", name: `Coventry Health Care of Missouri` },
  { id: "PAN.COVHCNE", name: `Coventry Health Care of Nebraska` },
  { id: "PAN.COVHCOH", name: `Coventry Health Care of Ohio` },
  { id: "PAN.COVHCOK", name: `Coventry Health Care of Oklahoma` },
  { id: "PAN.COVHCPA", name: `Coventry Health Care of Pennsylvania` },
  { id: "PAN.COVHCSD", name: `Coventry Health Care of South Dakota` },
  { id: "PAN.COVHCCAR", name: `Coventry Health Care of the Carolinas` },
  { id: "PAN.COVHCUT", name: `Coventry Health Care of Utah` },
  { id: "PAN.COVHCWV", name: `Coventry Health Care of West Virginia` },
  { id: "PAN.COVHCVA", name: `Coventry Health Care Virginia` },
  { id: "PAN.COXHP", name: `Cox HealthPlans` },
  { id: "PAN.DAKCARE", name: `DAKOTACARE` },
  { id: "PAN.DEANHP", name: `Dean Health Plan` },
  { id: "PAN.DEMCAID", name: `Delaware Medicaid` },
  { id: "PAN.DENHMP", name: `Denver Health Medical Plan` },
  { id: "PAN.DCMCAID", name: `District of Columbia Medicaid` },
  { id: "PAN.EMBLEM", name: `EmblemHealth` },
  { id: "PAN.EMPIRE", name: `Empire BCBS` },
  { id: "PAN.ESSENCE", name: `Essence Healthcare` },
  { id: "PAN.EXCELLUS", name: `Excellus BCBS` },
  { id: "PAN.EXCLMCAID", name: `Excellus BCBS Medicaid` },
  { id: "PAN.FALCHP", name: `Fallon Community Health Plan` },
  { id: "PAN.FIDCARE", name: `Fidelis Care` },
  { id: "PAN.FIDSLMCAID", name: `Fidelis SecureLife Medicaid` },
  { id: "PAN.FIRSTHN", name: `First Health Network` },
  { id: "PAN.FCSHSC", name: `FirstChoice by Select Health of South Carolina` },
  { id: "PAN.FLBLUE", name: `Florida Blue` },
  { id: "PAN.FHCP", name: `Florida Health Care Plans (FHCP)` },
  { id: "PAN.FLHEAL", name: `Florida Health Plans` },
  { id: "PAN.FLMCAID", name: `Florida Medicaid` },
  { id: "PAN.FRESHP", name: `Fresenius Health Plans` },
  { id: "PAN.GATEWAY", name: `Gateway Health Plan` },
  { id: "PAN.GEISINGER", name: `Geisinger Health Plan` },
  { id: "PAN.GAMCAID", name: `Georgia Medicaid` },
  { id: "PAN.GLOBAL", name: `GlobalHealth` },
  { id: "PAN.GOLDCHP", name: `Gold Coast Health Plan` },
  { id: "PAN.GOLDEN", name: `Golden Rule Insurance Company` },
  { id: "PAN.GEHA", name: `Government Employee Health Association (GEHA)` },
  {
    id: "PAN.GHCSCW",
    name: `Group Health Cooperative South Central Wisconsin`,
  },
  { id: "PAN.GUNDER", name: `Gundersen Health Plan` },
  { id: "PAN.HARVPHC", name: `Harvard Pilgrim Health Care` },
  { id: "PAN.HIMA", name: `Hawaii Mainland Administrators` },
  { id: "PAN.HIMCAID", name: `Hawaii Medicaid` },
  { id: "PAN.HABCBSAR", name: `Health Advantage BCBS - Arkansas` },
  { id: "PAN.HA", name: `Health Alliance` },
  {
    id: "PAN.HAPEMIMCAID",
    name: `Health Alliance Plan Empowered Michigan Medicaid`,
  },
  { id: "PAN.HAPMI", name: `Health Alliance Plan of Michigan` },
  { id: "PAN.HCAZ", name: `Health Choice Arizona` },
  { id: "PAN.HFCO", name: `Health First Colorado` },
  { id: "PAN.HFHP", name: `Health First Health Plans` },
  { id: "PAN.HFNY", name: `Health First of New York` },
  { id: "PAN.HNET", name: `Health Net` },
  { id: "PAN.HNETFSTW", name: `Health Net Federal Services – TRICARE West` },
  { id: "PAN.HNE", name: `Health New England` },
  { id: "PAN.HPP", name: `Health Partners Plans` },
  { id: "PAN.HPNV", name: `Health Plan of Nevada` },
  { id: "PAN.HPSJ", name: `Health Plan of San Joaquin` },
  { id: "PAN.HPSM", name: `Health Plan of San Mateo` },
  { id: "PAN.HCOK", name: `HealthChoice of Oklahoma` },
  { id: "PAN.OMIT", name: `Healthfirst Health Plans` },
  { id: "PAN.HLINK", name: `HealthLink` },
  { id: "PAN.HPART", name: `HealthPartners` },
  { id: "PAN.HPLUS", name: `HealthPlus` },
  { id: "PAN.HSCOPE", name: `HealthSCOPE Benefits` },
  { id: "PAN.HUOFU", name: `Healthy U of Utah` },
  { id: "PAN.HIGHMK", name: `Highmark BCBS` },
  { id: "PAN.HMSAHI", name: `HMSA BCBS of Hawaii` },
  { id: "PAN.HOMESHP", name: `Home State Health Plan` },
  { id: "PAN.HRZNNJ", name: `Horizon BCBS of New Jersey` },
  { id: "PAN.HRZNNJNASC", name: `Horizon BCBS of New Jersey - NASCO` },
  { id: "PAN.HRZNNJHL", name: `Horizon NJ Health` },
  { id: "PAN.HUMANA", name: `Humana` },
  { id: "PAN.HMTE", name: `Humana Military - TRICARE East` },
  { id: "PAN.HUSKY", name: `Husky Health Connecticut` },
  { id: "PAN.IDMCAID", name: `Idaho Medicaid` },
  { id: "PAN.ILCH", name: `Illinicare Health` },
  { id: "PAN.ILMCAID", name: `Illinois Medicaid` },
  { id: "PAN.IMCARE", name: `IMCare` },
  { id: "PAN.INDPBC", name: `Independence Blue Cross` },
  { id: "PAN.INDPH", name: `Independent Health` },
  { id: "PAN.INMCAID", name: `Indiana Medicaid` },
  { id: "PAN.INEMPIRE", name: `Inland Empire Health Plan` },
  { id: "PAN.INNHEALTH", name: `Innovation Health` },
  { id: "PAN.IVHP", name: `Inter Valley Health Plan` },
  { id: "PAN.IAMCAID", name: `Iowa Medicaid` },
  { id: "PAN.IUHP", name: `IU Health Plans` },
  { id: "PAN.JAIMED", name: `Jai Medical` },
  { id: "PAN.JOHNHO", name: `Johns Hopkins HealthCare` },
  { id: "PAN.KAISER", name: `Kaiser Permanente` },
  { id: "PAN.KSMCAID", name: `Kansas Medicaid` },
  { id: "PAN.KYMCAID", name: `Kentucky Medicaid` },
  { id: "PAN.KEYFIR", name: `Keystone First` },
  { id: "PAN.KFCHC", name: `Keystone First Community HealthChoices` },
  { id: "PAN.LACARE", name: `LA Care Health Plan` },
  { id: "PAN.LWOR", name: `LifeWise of Oregon` },
  { id: "PAN.LWWA", name: `LifeWise of Washington` },
  { id: "PAN.LAHCC", name: `Louisiana Healthcare Connections` },
  { id: "PAN.LAMCAID", name: `Louisiana Medicaid` },
  { id: "PAN.MAGCC", name: `Magellan Complete Care` },
  { id: "PAN.MAGNA", name: `MagnaCare` },
  { id: "PAN.MAGH", name: `Magnolia Health` },
  { id: "PAN.MECOMHO", name: `Maine Community Health Options` },
  { id: "PAN.MEMCAID", name: `Maine Medicaid` },
  { id: "PAN.MPGA", name: `Martins Point Generations Advantage` },
  { id: "PAN.MDMCAID", name: `Maryland Medicaid` },
  { id: "PAN.MDPC", name: `Maryland Physician Care` },
  { id: "PAN.MAMCAID", name: `Massachusetts Medicaid` },
  { id: "PAN.MDWHIP", name: `MDWise - Healthy Indiana Plan` },
  { id: "PAN.MDWHH", name: `MDWise - Hoosier Healthwise` },
  { id: "PAN.MEDIHP", name: `Medica Health Plan` },
  { id: "PAN.MEDIHC", name: `Medica HealthCare` },
  { id: "PAN.CAMCAID", name: `Medi-Cal` },
  { id: "PAN.MEDAHP", name: `Medical Associates Health Plans` },
  { id: "PAN.MMOH", name: `Medical Mutual of Ohio` },
  { id: "PAN.MCARE", name: `Medicare` },
  { id: "PAN.MEDIGOLD", name: `MediGold` },
  { id: "PAN.MSFC", name: `MedStar Family Choice` },
  { id: "PAN.MSMCARE", name: `MedStar Medicare Choice` },
  { id: "PAN.MERCYCARE", name: `Mercy Care` },
  { id: "PAN.MCPAZ", name: `Mercy Care Plan - Arizona` },
  { id: "PAN.MMIC", name: `Mercy Maricopa Integrated Care` },
  { id: "PAN.MERIDH", name: `Meridian Health` },
  { id: "PAN.MERITAIN", name: `Meritain Health` },
  { id: "PAN.METROP", name: `MetroPlus Health Plan` },
  { id: "PAN.MHSIN", name: `MHS (Managed Health Services of Indiana)` },
  { id: "PAN.MHSWI", name: `MHS (Managed Health Services of Wisconsin)` },
  { id: "PAN.MICH", name: `Michigan Complete Health` },
  { id: "PAN.MIMCAID", name: `Michigan Medicaid` },
  { id: "PAN.MIDATLR", name: `Mid-Atlantic Regional` },
  { id: "PAN.MNMCAID", name: `Minnesota Medicaid` },
  { id: "PAN.MNCARE", name: `MinnesotaCare` },
  { id: "PAN.MSMCAID", name: `Mississippi Medicaid` },
  { id: "PAN.MOMCAID", name: `Missouri Medicaid` },
  { id: "PAN.MODA", name: `Moda Health` },
  { id: "PAN.MOLINA", name: `Molina Healthcare` },
  { id: "PAN.MOLINACA", name: `Molina Healthcare of California` },
  { id: "PAN.MOLINAFL", name: `Molina Healthcare of Florida` },
  { id: "PAN.MOLINAID", name: `Molina Healthcare of Idaho` },
  { id: "PAN.MOLINAIL", name: `Molina Healthcare of Illinois` },
  { id: "PAN.MOLINAMI", name: `Molina Healthcare of Michigan` },
  { id: "PAN.MOLINAMS", name: `Molina Healthcare of Mississippi` },
  { id: "PAN.MOLINANM", name: `Molina Healthcare of New Mexico` },
  { id: "PAN.MOLINANY", name: `Molina Healthcare of New York` },
  { id: "PAN.MOLINAOH", name: `Molina Healthcare of Ohio` },
  { id: "PAN.MOLINAPR", name: `Molina Healthcare of Puerto Rico` },
  { id: "PAN.MOLINASC", name: `Molina Healthcare of South Carolina` },
  { id: "PAN.MOLINATX", name: `Molina Healthcare of Texas` },
  { id: "PAN.MOLINAUT", name: `Molina Healthcare of Utah` },
  { id: "PAN.MOLINAWA", name: `Molina Healthcare of Washington` },
  { id: "PAN.MOLINAWI", name: `Molina Healthcare of Wisconsin` },
  { id: "PAN.MTHC", name: `Montana Health Co-op` },
  { id: "PAN.MTMCAID", name: `Montana Medicaid` },
  { id: "PAN.MVPHC", name: `MVP Health Care` },
  { id: "PAN.NALC", name: `National Association of Letter Carriers (NALC)` },
  { id: "PAN.NEMCAID", name: `Nebraska Medicaid` },
  { id: "PAN.NETC", name: `Nebraska Total Care` },
  { id: "PAN.NHHPRI", name: `Neighborhood Health Plan of Rhode Island` },
  { id: "PAN.NETWRK", name: `Network Health` },
  { id: "PAN.NVMCAID", name: `Nevada Medicaid` },
  { id: "PAN.NHHF", name: `New Hampshire Healthy Families` },
  { id: "PAN.NHMCAID", name: `New Hampshire Medicaid` },
  { id: "PAN.NJMCAID", name: `New Jersey Medicaid` },
  { id: "PAN.NMHC", name: `New Mexico Health Connections` },
  { id: "PAN.NMMCAID", name: `New Mexico Medicaid` },
  { id: "PAN.NYMCAID", name: `New York Medicaid` },
  { id: "PAN.NXTLEV", name: `NextLevelHealth` },
  { id: "PAN.NIPPON", name: `Nippon Life Insurance Company` },
  { id: "PAN.NCMCAID", name: `North Carolina Medicaid` },
  { id: "PAN.NDMCAID", name: `North Dakota Medicaid` },
  { id: "PAN.NOVA", name: `Nova Healthcare Administrators` },
  { id: "PAN.OHMCAID", name: `Ohio Medicaid` },
  { id: "PAN.OKMCAID", name: `Oklahoma Medicaid` },
  { id: "PAN.ONENET", name: `OneNet PPO Supplement` },
  { id: "PAN.OPTIMA", name: `Optima Health Plan` },
  { id: "PAN.ORMCAID", name: `Oregon Medicaid` },
  { id: "PAN.OSCAR", name: `Oscar` },
  { id: "PAN.OXHP", name: `Oxford Health Plan (UHC)` },
  { id: "PAN.PAHW", name: `PA Health and Wellness` },
  { id: "PAN.PACSHP", name: `PacificSource Health Plans` },
  { id: "PAN.PARAMT", name: `Paramount` },
  { id: "PAN.PARTHP", name: `Partnership Health Plan` },
  { id: "PAN.PASSPORT", name: `Passport Health Plan` },
  { id: "PAN.PEACHSHP", name: `Peach State Health Plan` },
  { id: "PAN.PAMCAID", name: `Pennsylvania Medicaid` },
  { id: "PAN.PEOPLES", name: `Peoples Health Network` },
  { id: "PAN.PHYSHP", name: `Physicians Health Plan` },
  { id: "PAN.PHYSPLUS", name: `Physicians Plus` },
  { id: "PAN.PCP", name: `Preferred Care Partners` },
  { id: "PAN.PREFONE", name: `PreferredOne` },
  { id: "PAN.PREMBC", name: `Premera Blue Cross` },
  { id: "PAN.PREMHP", name: `Premier Health Plan` },
  { id: "PAN.PRESBT", name: `Presbyterian` },
  { id: "PAN.PRESBTCC", name: `Presbyterian Centennial Care` },
  { id: "PAN.PRESBTHP", name: `Presbyterian Health Plan` },
  { id: "PAN.PRESTI", name: `Prestige Health Choice` },
  { id: "PAN.PRIORPAR", name: `Priority Partners` },
  { id: "PAN.PRIORH", name: `PriorityHealth` },
  { id: "PAN.PROMHP", name: `Prominence Health Plan` },
  { id: "PAN.PROMHFTX", name: `Prominence HealthFirst of TX` },
  { id: "PAN.PROVHP", name: `Providence Health Plan` },
  { id: "PAN.PROVPHP", name: `Provider Partners Health Plan` },
  { id: "PAN.PEHP", name: `Public Employee Health Plan (PEHP)` },
  { id: "PAN.QUALCA", name: `QualCare` },
  { id: "PAN.QUALCH", name: `QualChoice` },
  { id: "PAN.QUARTZ", name: `Quartz` },
  { id: "PAN.REGENCE", name: `Regence BCBS` },
  { id: "PAN.REGENCEOR", name: `Regence BCBS - Oregon` },
  { id: "PAN.REGENCEWA", name: `Regence BCBS - Washington` },
  { id: "PAN.REGENCEAD", name: `Regence Group Administrators` },
  { id: "PAN.RIMCAID", name: `Rhode Island Medicaid` },
  { id: "PAN.RIGHTC", name: `RightCare` },
  { id: "PAN.RIVVAL", name: `River Valley Entities Supplement` },
  { id: "PAN.ROCKYM", name: `Rocky Mountain Health Plans` },
  { id: "PAN.SAMHP", name: `Samaritan Health Plans` },
  { id: "PAN.SFHP", name: `San Francisco Health Plan` },
  { id: "PAN.SANFORD", name: `Sanford Health Plan` },
  { id: "PAN.SCFHP", name: `Santa Clara Family Health Plan` },
  { id: "PAN.SCAN", name: `SCAN Health Plan` },
  { id: "PAN.SWHP", name: `Scott And White Health Plan` },
  { id: "PAN.SECHP", name: `Security Health Plan` },
  { id: "PAN.SLCTHL", name: `SelectHealth` },
  { id: "PAN.SHCCUT", name: `SelectHealth Community Care of Utah` },
  { id: "PAN.SWHNY", name: `Senior Whole Health of NY ` },
  { id: "PAN.SHARP", name: `Sharp Health Plan` },
  { id: "PAN.SIERRA", name: `Sierra Health and Life` },
  { id: "PAN.SILVSM", name: `SilverSummit Healthplan` },
  { id: "PAN.SMPLHC", name: `Simply Healthcare Plans` },
  { id: "PAN.SCMCAID", name: `South Carolina Medicaid` },
  { id: "PAN.SDMCAID", name: `South Dakota Medicaid` },
  { id: "PAN.SIHO", name: `Southeastern Indiana Health Organization (SIHO)` },
  { id: "PAN.SUMMA", name: `SummaCare Health Plan` },
  { id: "PAN.SUNFLS", name: `Sunflower State` },
  { id: "PAN.SUNSHI", name: `Sunshine Health` },
  { id: "PAN.SUPERI", name: `Superior HealthPlan` },
  { id: "PAN.SUPERISH", name: `Superior Select Health (Tribute Health Plans)` },
  { id: "PAN.TNMCAID", name: `Tennessee Medicaid` },
  { id: "PAN.TXMCAID", name: `Texas Medicaid` },
  { id: "PAN.THP", name: `The Health Plan` },
  {
    id: "PAN.TML",
    name: `TML Multistate Intergovernmental Employee Benefits Pool`,
  },
  { id: "PAN.TRICARE", name: `TRICARE For Life` },
  { id: "PAN.TRILLC", name: `Trillium Community Health PLan` },
  { id: "PAN.TRUEHNM", name: `True Health New Mexico` },
  { id: "PAN.TRUSTED", name: `Trusted Health Plan of DC` },
  { id: "PAN.TRUSTM", name: `Trustmark` },
  { id: "PAN.TUFTS", name: `Tufts Health Plan` },
  { id: "PAN.UCARE", name: `UCare` },
  { id: "PAN.UMR", name: `UMR` },
  { id: "PAN.UNICARE", name: `Unicare` },
  { id: "PAN.UHC", name: `UnitedHealthcare` },
  { id: "PAN.UHCAS", name: `UnitedHealthcare All Savers` },
  {
    id: "PAN.UHCCPHI",
    name: `UnitedHealthcare Community Pan of Hawaii Medicare`,
  },
  { id: "PAN.UHCCP", name: `UnitedHealthcare Community Plan` },
  { id: "PAN.UHCCPAZ", name: `UnitedHealthcare Community Plan of Arizona` },
  { id: "PAN.UHCCPCA", name: `UnitedHealthcare Community Plan of California` },
  { id: "PAN.UHCCPCO", name: `UnitedHealthcare Community Plan of Colorado` },
  { id: "PAN.UHCCPDC", name: `UnitedHealthcare Community Plan of D.C` },
  { id: "PAN.UHCCPDE", name: `UnitedHealthcare Community Plan of Delaware` },
  { id: "PAN.UHCCPFL", name: `UnitedHealthcare Community Plan of Florida` },
  { id: "PAN.UHCCPGA", name: `UnitedHealthcare Community Plan of Georgia` },
  { id: "PAN.UHCCPIA", name: `UnitedHealthcare Community Plan of Iowa` },
  { id: "PAN.UHCCPKS", name: `UnitedHealthcare Community Plan of Kansas` },
  { id: "PAN.UHCCPKY", name: `UnitedHealthcare Community Plan of Kentucky` },
  { id: "PAN.UHCCPLA", name: `UnitedHealthcare Community Plan of Louisiana` },
  { id: "PAN.UHCCPME", name: `UnitedHealthcare Community Plan of Maine` },
  { id: "PAN.UHCCPMD", name: `UnitedHealthcare Community Plan of Maryland` },
  {
    id: "PAN.UHCCPMA",
    name: `UnitedHealthcare Community Plan of Massachusetts`,
  },
  { id: "PAN.UHCCPMI", name: `UnitedHealthcare Community Plan of Michigan` },
  { id: "PAN.UHCCPMS", name: `UnitedHealthcare Community Plan of Mississippi` },
  { id: "PAN.UHCCPMO", name: `UnitedHealthcare Community Plan of Missouri` },
  { id: "PAN.UHCCPNE", name: `UnitedHealthcare Community Plan of Nebraska` },
  { id: "PAN.UHCCPNJ", name: `UnitedHealthcare Community Plan of New Jersey` },
  { id: "PAN.UHCCPNM", name: `UnitedHealthcare Community Plan of New Mexico` },
  { id: "PAN.UHCCPNY", name: `UnitedHealthcare Community Plan of New York` },
  { id: "PAN.UHCCPOH", name: `UnitedHealthcare Community Plan of Ohio` },
  {
    id: "PAN.UHCCPPA",
    name: `UnitedHealthcare Community Plan of Pennsylvania`,
  },
  {
    id: "PAN.UHCCPRI",
    name: `UnitedHealthcare Community Plan of Rhode Island`,
  },
  {
    id: "PAN.UHCCPTN",
    name: `UnitedHealthcare Community Plan of Tennessee (Tenncare)`,
  },
  { id: "PAN.UHCCPTX", name: `UnitedHealthcare Community Plan of Texas` },
  { id: "PAN.UHCCPVA", name: `UnitedHealthcare Community Plan of Virginia` },
  { id: "PAN.UHCCPWA", name: `UnitedHealthcare Community Plan of Washington` },
  { id: "PAN.UHCCPWI", name: `UnitedHealthcare Community Plan of Wisconsin` },
  { id: "PAN.UNITY", name: `Unity Health Insurance` },
  { id: "PAN.UNIVERA", name: `Univera Healthcare` },
  { id: "PAN.UHA", name: `University Health Alliance` },
  { id: "PAN.UAZHP", name: `University of Arizona Health Plans` },
  { id: "PAN.UUTHP", name: `University of Utah Health Plans` },
  { id: "PAN.UPMC", name: `UPMC Health Plan` },
  { id: "PAN.UTMCAID", name: `Utah Medicaid` },
  { id: "PAN.VAPHP", name: `VA Premier Health Plan` },
  { id: "PAN.VCHCP", name: `Ventura County Health Care Plan (VCHCP)` },
  { id: "PAN.VTMCAID", name: `Vermont Medicaid` },
  { id: "PAN.VAMCAID", name: `Virginia Medicaid` },
  { id: "PAN.VIVA", name: `VIVA Health` },
  { id: "PAN.WAMCAID", name: `Washington Medicaid` },
  { id: "PAN.WEA", name: `WEA Trust (Wisconsin Educators Association)` },
  { id: "PAN.WELLCARE", name: `WellCare Health Plans` },
  { id: "PAN.WCALMCARE", name: `WellCare of Alabama - Medicare` },
  { id: "PAN.WCAZMCARE", name: `WellCare of Arizona - Medicare` },
  { id: "PAN.WCAR", name: `WellCare of Arkansas` },
  { id: "PAN.WCCT", name: `WellCare of Connecticut` },
  { id: "PAN.WCFLMCAID", name: `WellCare of FL Medicaid` },
  { id: "PAN.WCFLMCARE", name: `WellCare of FL Medicare` },
  { id: "PAN.WCGAMCAID", name: `WellCare of GA Medicaid` },
  { id: "PAN.WCGAMCARE", name: `WellCare of GA Medicare` },
  { id: "PAN.WCILMCARE", name: `WellCare of IL Medicare` },
  { id: "PAN.WCKY", name: `WellCare of Kentucky` },
  { id: "PAN.WCLA", name: `WellCare of Louisiana` },
  { id: "PAN.WCMS", name: `WellCare of Mississippi` },
  { id: "PAN.WCMO", name: `WellCare of Missouri` },
  { id: "PAN.WCNJMCAID", name: `WellCare of NJ Medicaid` },
  { id: "PAN.WCNJMCARE", name: `WellCare of NJ Medicare` },
  { id: "PAN.WCNYMCAID", name: `WellCare of NY Medicaid` },
  { id: "PAN.WCNYMCARE", name: `WellCare of NY Medicare` },
  { id: "PAN.WCSC", name: `WellCare of South Carolina` },
  { id: "PAN.WCTXMCARE", name: `WellCare of Texas Medicare` },
  { id: "PAN.WCTNMCARE", name: `WellCare of TN Medicare` },
  { id: "PAN.WELLMK", name: `Wellmark BCBS` },
  { id: "PAN.WVFHP", name: `West Virginia Family Health Plan` },
  { id: "PAN.WVMCAID", name: `West Virginia Medicaid` },
  { id: "PAN.WHA", name: `Western Health Advantage` },
  { id: "PAN.WSCC", name: `Western Sky Community Care` },
  { id: "PAN.WIMCAID", name: `Wisconsin Medicaid` },
  { id: "PAN.WPS", name: `WPS Health Insurance` },
  { id: "PAN.WYMCAID", name: `Wyoming Medicaid` },
  { id: "PAN.MOCK", name: `PAN Mock` },
];

export interface ContactType {
  id: string;
  name: string;
}

export const ContactTypeList: Array<ContactType> = [
  { id: "OFFICE_STAFF", name: "Physician Staff" },
  { id: "PATIENT", name: "Patient Information" },
  { id: "AUTH_REQUESTOR", name: "Auth Requestor" },
];

export interface ServiceTypeCode {
  id: string;
  ediHl7Code: string;
  name: string;
}

export const ServiceTypeCodeList: Array<ServiceTypeCode> = [
  { id: "MEDICAL_CARE", ediHl7Code: "1", name: "Medical care" },
  { id: "SURGICAL", ediHl7Code: "2", name: "Surgical" },
  { id: "CONSULTATION", ediHl7Code: "3", name: "Consultation" },
  { id: "DIAGNOSTICXRAY", ediHl7Code: "4", name: "Diagnostic X Ray" },
  { id: "DIAGNOSTIC_LAB", ediHl7Code: "5", name: "Diagnostic Lab" },
  { id: "RADIATION_THERAPY", ediHl7Code: "6", name: "Radiation Therapy" },
  { id: "ANESTHESIA", ediHl7Code: "7", name: "Anesthesia" },
  { id: "SURGICAL_ASSISTANCE", ediHl7Code: "8", name: "Surgical Assistance" },
  {
    id: "USED_DURABLE_MEDICAL_EQUIPMENT",
    ediHl7Code: "11",
    name: "Used Durable Medical Equipment",
  },
  {
    id: "DURABLE_MEDICAL_EQUIPMENT_PURCHASE",
    ediHl7Code: "12",
    name: "Durable Medical Equipment purchase",
  },
  {
    id: "RENAL_SUPPLIES_IN_THE_HOME",
    ediHl7Code: "14",
    name: "Renal supplies in the home",
  },
  {
    id: "ALTERNATE_METHOD_DIALYSIS",
    ediHl7Code: "15",
    name: "Alternate method dialysis",
  },
  {
    id: "CHRONIC_RENAL_DISEASE_EQUIPMENT",
    ediHl7Code: "16",
    name: "Chronic renal disease equipment",
  },
  {
    id: "PRE_ADMISSION_TESTING",
    ediHl7Code: "17",
    name: "Pre Admission Testing",
  },
  {
    id: "DURABLE_MEDICAL_EQUIPMENT_RENTAL",
    ediHl7Code: "18",
    name: "Durable Medical Equipment rental",
  },
  {
    id: "SECOND_SURGICAL_OPINION",
    ediHl7Code: "20",
    name: "Second surgical opinion",
  },
  {
    id: "THIRD_SURGICAL_OPINION",
    ediHl7Code: "21",
    name: "Third surgical opinion",
  },
  { id: "DIAGNOSTIC_DENTAL", ediHl7Code: "23", name: "Diagnostic Dental" },
  { id: "RESTORATIVE", ediHl7Code: "25", name: "Restorative" },
  { id: "ENDODONTICS", ediHl7Code: "26", name: "Endodontics" },
  {
    id: "MAXILLOFACIAL_PROSTHETICS",
    ediHl7Code: "27",
    name: "Maxillofacial Prosthetics",
  },
  {
    id: "ADJUNCTIVE_DENTAL_SERVICES",
    ediHl7Code: "28",
    name: "Adjunctive Dental Services",
  },
  { id: "CHIROPRACTIC", ediHl7Code: "33", name: "Chiropractic" },
  { id: "DENTAL_CARE", ediHl7Code: "35", name: "Dental Care" },
  { id: "DENTAL_CROWNS", ediHl7Code: "36", name: "Dental Crowns" },
  { id: "DENTAL_ACCIDENT", ediHl7Code: "37", name: "Dental Accident" },
  { id: "ORTHODONTICS", ediHl7Code: "38", name: "Orthodontics" },
  { id: "PROSTHODONTICS", ediHl7Code: "39", name: "Prosthodontics" },
  { id: "ORAL_SURGERY", ediHl7Code: "40", name: "Oral surgery" },
  { id: "HOME_HEALTH_CARE", ediHl7Code: "42", name: "Home Health Care" },
  { id: "HOME_HEALTH_VISITS", ediHl7Code: "44", name: "Home Health Visits" },
  { id: "HOSPICE", ediHl7Code: "45", name: "Hospice" },
  { id: "RESPITE_CARE", ediHl7Code: "46", name: "Respite care" },
  { id: "LONG_TERM_CARE", ediHl7Code: "54", name: "Long Term Care" },
  {
    id: "MEDICALLY_RELATED_TRANSPORTATION",
    ediHl7Code: "56",
    name: "Medically Related Transportation",
  },
  {
    id: "IN_VITRO_FERTILIZATION",
    ediHl7Code: "61",
    name: "In Vitro Fertilization",
  },
  { id: "MRI_CAT_SCAN", ediHl7Code: "62", name: "MRI/CAT scan" },
  { id: "DONOR_PROCEDURES", ediHl7Code: "63", name: "Donor Procedures" },
  { id: "ACUPUNCTURE", ediHl7Code: "64", name: "Acupuncture" },
  { id: "NEWBORN_CARE", ediHl7Code: "65", name: "Newborn care" },
  { id: "PATHOLOGY", ediHl7Code: "66", name: "Pathology" },
  { id: "SMOKING_CESSATION", ediHl7Code: "67", name: "Smoking Cessation" },
  { id: "WELL_BABY_CARE", ediHl7Code: "68", name: "Well Baby Care" },
  { id: "MATERNITY", ediHl7Code: "69", name: "Maternity" },
  { id: "TRANSPLANTS", ediHl7Code: "70", name: "Transplants" },
  { id: "AUDILOLOGY_EXAM", ediHl7Code: "71", name: "Audilology Exam" },
  { id: "INHALATION_THERAPY", ediHl7Code: "72", name: "Inhalation Therapy" },
  { id: "DIAGNOSTIC_MEDICAL", ediHl7Code: "73", name: "Diagnostic Medical" },
  {
    id: "PRIVATE_DUTY_NURSING",
    ediHl7Code: "74",
    name: "Private Duty Nursing",
  },
  { id: "PROSTHETIC_DEVICE", ediHl7Code: "75", name: "Prosthetic Device" },
  { id: "DIALYSIS", ediHl7Code: "76", name: "Dialysis" },
  { id: "OTOLOGICAL_EXAM", ediHl7Code: "77", name: "Otological Exam" },
  { id: "CHEMOTHERAPY", ediHl7Code: "78", name: "Chemotherapy" },
  { id: "ALLERGY_TESTING", ediHl7Code: "79", name: "Allergy Testing" },
  { id: "IMMUNIZATIONS", ediHl7Code: "80", name: "Immunizations" },
  { id: "FAMILY_PLANNING", ediHl7Code: "82", name: "Family Planning" },
  { id: "INFERTILITY", ediHl7Code: "83", name: "Infertility" },
  { id: "ABORTION", ediHl7Code: "84", name: "Abortion" },
  { id: "AIDS", ediHl7Code: "85", name: "AIDS" },
  { id: "EMERGENCY_SERVICES", ediHl7Code: "86", name: "Emergency Services" },
  { id: "CANCER", ediHl7Code: "87", name: "Cancer" },
  { id: "PHARMACY", ediHl7Code: "88", name: "Pharmacy" },
  { id: "PODIATRY", ediHl7Code: "93", name: "Podiatry" },
  { id: "PSYCHIATRIC", ediHl7Code: "A4", name: "Psychiatric" },
  { id: "PSYCHOTHERAPY", ediHl7Code: "A6", name: "Psychotherapy" },
  { id: "REHABILITATION", ediHl7Code: "A9", name: "Rehabilitation" },
  {
    id: "OCCUPATIONAL_THERAPY",
    ediHl7Code: "AD",
    name: "Occupational Therapy",
  },
  { id: "PHYSICAL_MEDICINE", ediHl7Code: "AE", name: "Physical Medicine" },
  { id: "SPEECH_THERAPY", ediHl7Code: "AF", name: "Speech Therapy" },
  {
    id: "SKILLED_NURSING_CARE",
    ediHl7Code: "AG",
    name: "Skilled Nursing Care",
  },
  {
    id: "SKILLED_NURSING_CARE-ROOM_AND_BOARD",
    ediHl7Code: "AH",
    name: "Skilled Nursing Care-Room and Board",
  },
  { id: "ALCOHOLISM", ediHl7Code: "AJ", name: "Alcoholism" },
  { id: "DRUG_ADDICTION", ediHl7Code: "AK", name: "Drug Addiction" },
  { id: "VISION_OPTOMETRY", ediHl7Code: "AL", name: "Vision Optometry" },
  {
    id: "EXPERIMENTAL_DRUG_THERAPY",
    ediHl7Code: "AR",
    name: "Experimental Drug Therapy",
  },
  { id: "BURN_CARE", ediHl7Code: "B1", name: "Burn Care" },
  {
    id: "PARTIAL_HOSPITALIZATION(PSYCHIATRIC)",
    ediHl7Code: "BB",
    name: "Partial Hospitalization(psychiatric)",
  },
  {
    id: "DAY_CARE_PSYCHIATRIC",
    ediHl7Code: "BC",
    name: "Day Care Psychiatric",
  },
  { id: "COGNITIVE_THERAPY", ediHl7Code: "BD", name: "Cognitive Therapy" },
  { id: "MASSAGE_THERAPY", ediHl7Code: "BE", name: "Massage Therapy" },
  {
    id: "PULMONARY_REHABILITATION",
    ediHl7Code: "BF",
    name: "Pulmonary Rehabilitation",
  },
  {
    id: "CARDIAC_REHABILITATAION",
    ediHl7Code: "BG",
    name: "Cardiac Rehabilitataion",
  },
  { id: "CARDIAC", ediHl7Code: "BL", name: "Cardiac" },
  { id: "GASTROINTESTINAL", ediHl7Code: "BN", name: "Gastrointestinal" },
  { id: "ENDOCRINE", ediHl7Code: "BP", name: "Endocrine" },
  { id: "NEUROLOGY", ediHl7Code: "BQ", name: "Neurology" },
  { id: "INVASIVE_PROCEDURES", ediHl7Code: "BS", name: "Invasive Procedures" },
  {
    id: "PHYSICIAN_VISIT-OFFICE:SICK",
    ediHl7Code: "BY",
    name: "Physician visit-Office:Sick",
  },
  {
    id: "PHYSICIAN_VISIT-OFFICE:WELL",
    ediHl7Code: "BZ",
    name: "Physician visit-Office:Well",
  },
  { id: "CORONARY_CARE", ediHl7Code: "C1", name: "Coronary Care" },
  { id: "CASE_MANAGEMENT", ediHl7Code: "CQ", name: "Case Management" },
  { id: "ALLERGY", ediHl7Code: "GY", name: "Allergy" },
  { id: "INTENSIVE_CARE", ediHl7Code: "IC", name: "Intensive Care" },
  { id: "MENTAL_HEALTH", ediHl7Code: "MH", name: "Mental Health" },
  {
    id: "NEONATAL_INTENSIVE_CARE",
    ediHl7Code: "NI",
    name: "Neonatal Intensive Care",
  },
  { id: "ONCOLOGY", ediHl7Code: "ON", name: "Oncology" },
  { id: "PHYSICAL_THERAPY", ediHl7Code: "PT", name: "Physical Therapy" },
  { id: "PULMONARY", ediHl7Code: "PU", name: "Pulmonary" },
  { id: "RENAL", ediHl7Code: "RN", name: "Renal" },
  {
    id: "RESIDENTIAL_PSYCHIATRIC_TREATMENT",
    ediHl7Code: "RT",
    name: "Residential Psychiatric Treatment",
  },
  { id: "TRANSITIONAL_CARE", ediHl7Code: "TC", name: "Transitional Care" },
  {
    id: "TRANSITIONAL_NURSERY_CARE",
    ediHl7Code: "TN",
    name: "Transitional Nursery Care",
  },
  {
    id: "SUBSTANCE_ABUSE_REHABILITATION",
    ediHl7Code: "AI",
    name: "Substance Abuse Rehabilitation",
  },
];

export interface PlaceOfService {
  id: string;
  ediCode: string;
  name: string;
}

export const PlaceOfServiceList: Array<PlaceOfService> = [
  { id: "PHARMACY", ediCode: "01", name: "Pharmacy" },
  { id: "TELEHEALTH", ediCode: "02", name: "Telehealth/Telemedicine" },
  { id: "SCHOOL", ediCode: "03", name: "School" },
  { id: "SHELTER", ediCode: "04", name: "Homeless Shelter" },
  { id: "UNASSIGNED", ediCode: "10", name: "Unassigned" },
  { id: "OFFICE", ediCode: "11", name: "Office" },
  { id: "HOME", ediCode: "12", name: "Home" },
  { id: "OCO_HOSPITAL", ediCode: "19", name: "Off Campus Outpatient Hospital" },
  { id: "URGENT_CARE", ediCode: "20", name: "Urgent Care Facility" },
  { id: "INPATIENT_HOSPITAL", ediCode: "21", name: "Inpatient Hospital" },
  {
    id: "ON_CAMPUS_OUTPATIENT_HOSPITAL",
    ediCode: "22",
    name: "On campus Outpatient Hospital",
  },
  { id: "ER", ediCode: "23", name: "Emergency Room - Hospital" },
  {
    id: "AMBULATORY_SURGICAL_CENTER",
    ediCode: "24",
    name: "Ambulatory Surgical Center",
  },
  { id: "OTHER", ediCode: "99", name: "Other Place of Service" },
];

export interface LevelOfService {
  id: string;
  ediFhirCode: string;
  name: string;
  description: string;
}

export const LevelOfServiceList: Array<LevelOfService> = [
  {
    id: "EMERGENCY",
    ediFhirCode: "03",
    name: "Emergency",
    description:
      "The patient required immediate medical intervention as a result of severe, life threatening or potentially disabling conditions. Generally, the patient was admitted through the emergency room.",
  },
  {
    id: "URGENT",
    ediFhirCode: "U",
    name: "Urgent",
    description:
      "The patient required immediate attention for the care and treatment of a physical or mental disorder. Generally, the patient was admitted to the first available, suitable accommodation (direct admits from a Doctor’s Office for example).",
  },
  {
    id: "ELECTIVE",
    ediFhirCode: "E",
    name: "Elective",
    description:
      "The patient’s condition permitted adequate time to schedule the availability of a suitable accommodation.",
  },
];

export interface RequestType {
  id: string;
  ediCode: string;
  name: string;
}

export const RequestTypeList: Array<RequestType> = [
  { id: "INPATIENT", ediCode: "AR", name: "Inpatient service" },
  { id: "OUTPATIENT", ediCode: "HS", name: "Outpatient service" },
  { id: "REFERRAL", ediCode: "SC", name: "Referral" },
];

export interface PatientRelationship {
  id: string;
  name: string; // 'Valid Values'
  individualRelationshipCode: string; // HIPAA Individual Relationship Codes
  cwRelationshipCode: string; //'Convert to CWF Patient Relationship Codes - Effective October 16, 2003
}

export const PatientRelationshipList: Array<PatientRelationship> = [
  {
    id: "SPOUSE",
    name: "Spouse",
    individualRelationshipCode: "1",
    cwRelationshipCode: "2",
  },
  {
    id: "GRANDPARENT",
    name: "Grandfather or Grandmother",
    individualRelationshipCode: "4",
    cwRelationshipCode: "19",
  },
  {
    id: "GRANDCHILD",
    name: "Grandson or Granddaughter",
    individualRelationshipCode: "5",
    cwRelationshipCode: "13",
  },
  {
    id: "NEPHEW_NIECE",
    name: "Nephew or Niece",
    individualRelationshipCode: "7",
    cwRelationshipCode: "14",
  },
  {
    id: "FOSTER_CHILD",
    name: "Foster Child",
    individualRelationshipCode: "10",
    cwRelationshipCode: "6",
  },
  {
    id: "WARD",
    name: "Ward of the Court",
    individualRelationshipCode: "15",
    cwRelationshipCode: "7",
  },
  {
    id: "STEPCHILD",
    name: "Stepson or Stepdaughter",
    individualRelationshipCode: "17",
    cwRelationshipCode: "5",
  },
  {
    id: "SELF",
    name: "Self",
    individualRelationshipCode: "18",
    cwRelationshipCode: "1",
  },
  {
    id: "CHILD",
    name: "Child",
    individualRelationshipCode: "19",
    cwRelationshipCode: "3",
  },
  {
    id: "EMPLOYEE",
    name: "Employee",
    individualRelationshipCode: "20",
    cwRelationshipCode: "8",
  },
  {
    id: "UNKNOWN",
    name: "Unknown",
    individualRelationshipCode: "21",
    cwRelationshipCode: "9",
  },
  {
    id: "HANDICAPPED_DEPENDANT",
    name: "Handicapped/Dependent",
    individualRelationshipCode: "22",
    cwRelationshipCode: "10",
  },
  {
    id: "SPONSORED_DEPENDENT",
    name: "Sponsored Dependent",
    individualRelationshipCode: "23",
    cwRelationshipCode: "16",
  },
  {
    id: "DEPENDENT_OF_MINOR",
    name: "Dependent of Minor Dependent",
    individualRelationshipCode: "24",
    cwRelationshipCode: "17",
  },
  {
    id: "SIGNIFICANT_OTHER",
    name: "Significant Other",
    individualRelationshipCode: "29",
    cwRelationshipCode: "none*",
  },
  {
    id: "MOTHER",
    name: "Mother",
    individualRelationshipCode: "32",
    cwRelationshipCode: "none",
  },
  {
    id: "FATHER",
    name: "Father",
    individualRelationshipCode: "33",
    cwRelationshipCode: "none",
  },
  {
    id: "EMANCIPATED_MINOR",
    name: "Emancipated Minor",
    individualRelationshipCode: "36",
    cwRelationshipCode: "none",
  },
  {
    id: "ORGAN_DONOR",
    name: "Organ Donor",
    individualRelationshipCode: "39",
    cwRelationshipCode: "11",
  },
  {
    id: "CADAVER_DONOR",
    name: "Cadaver Donor",
    individualRelationshipCode: "40",
    cwRelationshipCode: "12",
  },
  {
    id: "INJURED_PLAINTIFF",
    name: "Injured Plaintiff",
    individualRelationshipCode: "41",
    cwRelationshipCode: "15",
  },
  {
    id: "LIFE_PARTNER",
    name: "Life Partner",
    individualRelationshipCode: "53",
    cwRelationshipCode: "none*",
  },
  {
    id: "OTHER",
    name: "Other Relationship",
    individualRelationshipCode: "G8",
    cwRelationshipCode: "none",
  },
];

export interface GenderOption {
  id: string;
  name: string;
}

export const GenderOptionList: Array<GenderOption> = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
];

export interface QuantityType {
  id: string;
  ediFhirCode: string;
  name: string;
}

export const QuantityTypeList: Array<QuantityType> = [
  { id: "DAYS", ediFhirCode: "DY", name: "Days" },
  { id: "HOURS", ediFhirCode: "HS", name: "Hours" },
  { id: "MONTHS", ediFhirCode: "MN", name: "Months" },
  { id: "UNITS", ediFhirCode: "UN", name: "Units" },
  { id: "VISITS", ediFhirCode: "VS", name: "Visits" },
];

export interface FrequencyType {
  id: string;
  ediHl7Code: string;
  name: string;
}

export const FrequencyTypeList: Array<FrequencyType> = [
  { id: "HOUR", ediHl7Code: "6", name: "Hour" },
  { id: "DAY", ediHl7Code: "7", name: "Day" },
  { id: "YEARS", ediHl7Code: "21", name: "Years" },
  { id: "VISIT", ediHl7Code: "27", name: "Visit" },
  { id: "MONTH", ediHl7Code: "34", name: "Month" },
  { id: "WEEK", ediHl7Code: "35", name: "Week" },
];

export interface ProcedureModifier {
  id: string;
  description: string;
  codeSet: string;
}

export const ProcedureModifierList: Array<ProcedureModifier> = [
  { id: "LT", description: "Left", codeSet: "WLP" },
  { id: "RT", description: "Right", codeSet: "WLP" },
  { id: "32", description: "32", codeSet: "WLP" },
  { id: "52", description: "52", codeSet: "WLP" },
  {
    id: "21",
    description: "Prolonged Evaluation and Management Services",
    codeSet: "WLP",
  },
  {
    id: "22",
    description: "Increased Procedural Services: When the work requi",
    codeSet: "WLP",
  },
  {
    id: "23",
    description: "Unusual Anesthesia: Occasionally, a procedure, whi",
    codeSet: "WLP",
  },
  {
    id: "24",
    description: "Unrelated Evaluation and Management Service by the",
    codeSet: "WLP",
  },
  {
    id: "25",
    description: "Significant, Separately Identifiable Evaluation an",
    codeSet: "WLP",
  },
  {
    id: "26",
    description: "Professional Component: Certain procedures are a c",
    codeSet: "WLP",
  },
  {
    id: "27",
    description: "Multiple Outpatient Hospital E/M Encounters on the",
    codeSet: "WLP",
  },
  {
    id: "33",
    description: "Preventive Services: When the primary purpose of t",
    codeSet: "WLP",
  },
  {
    id: "47",
    description: "Anesthesia by Surgeon: Regional or general anesthe",
    codeSet: "WLP",
  },
  {
    id: "50",
    description: "Bilateral Procedure: Unless otherwise identified i",
    codeSet: "WLP",
  },
  {
    id: "51",
    description: "Multiple Procedures: When multiple procedures, oth",
    codeSet: "WLP",
  },
  {
    id: "53",
    description: "Discontinued Procedure: Under certain circumstance",
    codeSet: "WLP",
  },
  {
    id: "54",
    description: "Surgical Care Only: When 1 physician or other qual",
    codeSet: "WLP",
  },
  {
    id: "55",
    description: "Postoperative Management Only: When 1 physician or",
    codeSet: "WLP",
  },
  {
    id: "56",
    description: "Preoperative Management Only: When 1 physician or",
    codeSet: "WLP",
  },
  {
    id: "57",
    description: "Decision for Surgery: An evaluation and management",
    codeSet: "WLP",
  },
  {
    id: "58",
    description: "Staged or Related Procedure or Service by the Same",
    codeSet: "WLP",
  },
  {
    id: "59",
    description: "Distinct Procedural Service: Under certain circums",
    codeSet: "WLP",
  },
  { id: "60", description: "Altered Surgical Field", codeSet: "WLP" },
  {
    id: "62",
    description: "Two Surgeons: When 2 surgeons work together as pri",
    codeSet: "WLP",
  },
  {
    id: "63",
    description: "Procedure Performed on Infants less than 4 kg: Pro",
    codeSet: "WLP",
  },
  {
    id: "66",
    description: "Surgical Team: Under some circumstances, highly co",
    codeSet: "WLP",
  },
  {
    id: "73",
    description: "Discontinued Out-Patient Hospital/Ambulatory Surge",
    codeSet: "WLP",
  },
  {
    id: "74",
    description: "Discontinued Out-Patient Hospital/Ambulatory Surge",
    codeSet: "WLP",
  },
  {
    id: "76",
    description: "Repeat Procedure or Service by Same Physician or O",
    codeSet: "WLP",
  },
  {
    id: "77",
    description: "Repeat Procedure by Another Physician or Other Qua",
    codeSet: "WLP",
  },
  {
    id: "78",
    description: "Unplanned Return to the Operating/Procedure Room b",
    codeSet: "WLP",
  },
  {
    id: "79",
    description: "Unrelated Procedure or Service by the Same Physici",
    codeSet: "WLP",
  },
  {
    id: "80",
    description: "Assistant Surgeon: Surgical assistant services may",
    codeSet: "WLP",
  },
  {
    id: "81",
    description: "Minimum Assistant Surgeon: Minimum surgical assist",
    codeSet: "WLP",
  },
  {
    id: "82",
    description: "Assistant Surgeon (When Qualified Resident Surgeon",
    codeSet: "WLP",
  },
  {
    id: "90",
    description: "Reference (Outside) Laboratory: When laboratory pr",
    codeSet: "WLP",
  },
  {
    id: "91",
    description: "Repeat Clinical Diagnostic Laboratory Test: In the",
    codeSet: "WLP",
  },
  {
    id: "92",
    description: "Alternative Laboratory Platform Testing: When labo",
    codeSet: "WLP",
  },
  {
    id: "99",
    description: "Multiple Modifiers: Under certain circumstances 2",
    codeSet: "WLP",
  },
  {
    id: "1P",
    description: "PERFORMANCE MEASURE EXCLUSION MODIFIER DUE TO MEDI",
    codeSet: "WLP",
  },
  {
    id: "2P",
    description: "PERFORMANCE MEASURE EXCLUSION MODIFIER DUE TO PATI",
    codeSet: "WLP",
  },
  {
    id: "3P",
    description: "PERFORMANCE MEASURE EXCLUSION MODIFIER DUE TO SYST",
    codeSet: "WLP",
  },
  {
    id: "8P",
    description: "PERFORMANCE MEASURE REPORTING MODIFIER - ACTION NO",
    codeSet: "WLP",
  },
  { id: "A1", description: "DRESSING FOR ONE WOUND", codeSet: "WLP" },
  { id: "A2", description: "DRESSING FOR TWO WOUNDS", codeSet: "WLP" },
  { id: "A3", description: "DRESSING FOR THREE WOUNDS", codeSet: "WLP" },
  { id: "A4", description: "DRESSING FOR FOUR WOUNDS", codeSet: "WLP" },
  { id: "A5", description: "DRESSING FOR FIVE WOUNDS", codeSet: "WLP" },
  { id: "A6", description: "DRESSING FOR SIX WOUNDS", codeSet: "WLP" },
  { id: "A7", description: "DRESSING FOR SEVEN WOUNDS", codeSet: "WLP" },
  { id: "A8", description: "DRESSING FOR EIGHT WOUNDS", codeSet: "WLP" },
  { id: "A9", description: "DRESSING FOR NINE OR MORE WOUNDS", codeSet: "WLP" },
  {
    id: "AA",
    description: "ANESTHESIA SERVICES PERFORMED PERSONALLY BY ANESTH",
    codeSet: "WLP",
  },
  {
    id: "AD",
    description: "MEDICAL SUPERVISION BY A PHYSICIAN: MORE THAN FOUR",
    codeSet: "WLP",
  },
  { id: "AE", description: "REGISTERED DIETICIAN", codeSet: "WLP" },
  { id: "AF", description: "SPECIALTY PHYSICIAN", codeSet: "WLP" },
  { id: "AG", description: "PRIMARY PHYSICIAN", codeSet: "WLP" },
  {
    id: "AH",
    description: "CLINICAL PSYCHOLOGIST (Special coverage instructio",
    codeSet: "WLP",
  },
  { id: "AI", description: "PRINCIPAL PHYSICIAN OF RECORD", codeSet: "WLP" },
  {
    id: "AJ",
    description: "CLINICAL SOCIAL WORKER (Special coverage instructi",
    codeSet: "WLP",
  },
  { id: "AK", description: "NON PARTICIPATING PHYSICIAN", codeSet: "WLP" },
  {
    id: "AM",
    description: "PHYSICIAN, TEAM MEMBER SERVICE (Special coverage i",
    codeSet: "WLP",
  },
  {
    id: "AO",
    description: "ALTERNATE PAYMENT METHOD DECLINED BY PROVIDER OF S",
    codeSet: "WLP",
  },
  {
    id: "AP",
    description: "DETERMINATION OF REFRACTIVE STATE WAS NOT PERFORME",
    codeSet: "WLP",
  },
  {
    id: "AQ",
    description: "PHYSICIAN PROVIDING A SERVICE IN AN UNLISTED HEALT",
    codeSet: "WLP",
  },
  {
    id: "AR",
    description: "PHYSICIAN PROVIDER SERVICES IN A PHYSICIAN SCARCIT",
    codeSet: "WLP",
  },
  {
    id: "AS",
    description: "PHYSICIAN ASSISTANT, NURSE PRACTITIONER, OR CLINIC",
    codeSet: "WLP",
  },
  {
    id: "AT",
    description: "ACUTE TREATMENT (THIS MODIFIER SHOULD BE USED WHEN",
    codeSet: "WLP",
  },
  {
    id: "AU",
    description: "ITEM FURNISHED IN CONJUNCTION WITH A UROLOGICAL, O",
    codeSet: "WLP",
  },
  {
    id: "AV",
    description: "ITEM FURNISHED IN CONJUNCTION WITH A PROSTHETIC DE",
    codeSet: "WLP",
  },
  {
    id: "AW",
    description: "ITEM FURNISHED IN CONJUNCTION WITH A SURGICAL DRES",
    codeSet: "WLP",
  },
  {
    id: "AX",
    description: "ITEM FURNISHED IN CONJUNCTION WITH DIALYSIS SERVIC",
    codeSet: "WLP",
  },
  {
    id: "AY",
    description: "ITEM OR SERVICE FURNISHED TO AN ESRD PATIENT THAT",
    codeSet: "WLP",
  },
  {
    id: "AZ",
    description: "PHYSICIAN PROVIDING A SERVICE IN A DENTAL HEALTH P",
    codeSet: "WLP",
  },
  {
    id: "BA",
    description: "ITEM FURNISHED IN CONJUNCTION WITH PARENTERAL ENTE",
    codeSet: "WLP",
  },
  {
    id: "BL",
    description: "SPECIAL ACQUISITION OF BLOOD AND BLOOD PRODUCTS",
    codeSet: "WLP",
  },
  {
    id: "BO",
    description: "ORALLY ADMINISTERED NUTRITION, NOT BY FEEDING TUBE",
    codeSet: "WLP",
  },
  {
    id: "BP",
    description: "THE BENEFICIARY HAS BEEN INFORMED OF THE PURCHASE",
    codeSet: "WLP",
  },
  {
    id: "BR",
    description: "THE BENEFICIARY HAS BEEN INFORMED OF THE PURCHASE",
    codeSet: "WLP",
  },
  {
    id: "BU",
    description: "THE BENEFICIARY HAS BEEN INFORMED OF THE PURCHASE",
    codeSet: "WLP",
  },
  {
    id: "CA",
    description: "PROCEDURE PAYABLE ONLY IN THE INPATIENT SETTING WH",
    codeSet: "WLP",
  },
  {
    id: "CB",
    description: "SERVICE ORDERED BY A RENAL DIALYSIS FACILITY (RDF)",
    codeSet: "WLP",
  },
  {
    id: "CC",
    description: "PROCEDURE CODE CHANGE (USE 'CC' WHEN THE PROCEDURE",
    codeSet: "WLP",
  },
  {
    id: "CD",
    description: "AMCC TEST HAS BEEN ORDERED BY AN ESRD FACILITY OR",
    codeSet: "WLP",
  },
  {
    id: "CE",
    description: "AMCC TEST HAS BEEN ORDERED BY AN ESRD FACILITY OR",
    codeSet: "WLP",
  },
  {
    id: "CF",
    description: "AMCC TEST HAS BEEN ORDERED BY AN ESRD FACILITY OR",
    codeSet: "WLP",
  },
  { id: "CG", description: "POLICY CRITERIA APPLIED", codeSet: "WLP" },
  {
    id: "CH",
    description: "0 PERCENT IMPAIRED, LIMITED OR RESTRICTED",
    codeSet: "WLP",
  },
  {
    id: "CI",
    description: "AT LEAST 1 PERCENT BUT LESS THAN 20 PERCENT IMPAIR",
    codeSet: "WLP",
  },
  {
    id: "CJ",
    description: "AT LEAST 20 PERCENT BUT LESS THAN 40 PERCENT IMPAI",
    codeSet: "WLP",
  },
  {
    id: "CK",
    description: "AT LEAST 40 PERCENT BUT LESS THAN 60 PERCENT IMPAI",
    codeSet: "WLP",
  },
  {
    id: "CL",
    description: "AT LEAST 60 PERCENT BUT LESS THAN 80 PERCENT IMPAI",
    codeSet: "WLP",
  },
  {
    id: "CM",
    description: "AT LEAST 80 PERCENT BUT LESS THAN 100 PERCENT IMPA",
    codeSet: "WLP",
  },
  {
    id: "CN",
    description: "100 PERCENT IMPAIRED, LIMITED OR RESTRICTED",
    codeSet: "WLP",
  },
  { id: "CR", description: "CATASTROPHE/DISASTER RELATED", codeSet: "WLP" },
  {
    id: "CS",
    description: "ITEM OR SERVICE RELATED, IN WHOLE OR IN PART, TO A",
    codeSet: "WLP",
  },
  {
    id: "DA",
    description: "ORAL HEALTH ASSESSMENT BY A LICENSED HEALTH PROFES",
    codeSet: "WLP",
  },
  {
    id: "DD",
    description: "From Diagnostic or therapeutic site To Diagnostic",
    codeSet: "WLP",
  },
  {
    id: "DE",
    description: "From Diagnostic or therapeutic site To Residential",
    codeSet: "WLP",
  },
  {
    id: "DG",
    description: "From Diagnostic or therapeutic site To Hospital ba",
    codeSet: "WLP",
  },
  {
    id: "DH",
    description: "From Diagnostic or therapeutic site To Hospital",
    codeSet: "WLP",
  },
  {
    id: "DI",
    description: "From Diagnostic or therapeutic site To Site of tra",
    codeSet: "WLP",
  },
  {
    id: "DJ",
    description: "From Diagnostic or therapeutic site To Freestandin",
    codeSet: "WLP",
  },
  {
    id: "DN",
    description: "From Diagnostic or therapeutic site To Skilled Nur",
    codeSet: "WLP",
  },
  {
    id: "DP",
    description: "From Diagnostic or therapeutic site To Physician's",
    codeSet: "WLP",
  },
  {
    id: "DR",
    description: "From Diagnostic or therapeutic site To Residence",
    codeSet: "WLP",
  },
  {
    id: "DS",
    description: "From Diagnostic or therapeutic site To Scene of ac",
    codeSet: "WLP",
  },
  {
    id: "DX",
    description: "From Diagnostic or therapeutic site To Intermediat",
    codeSet: "WLP",
  },
  { id: "E1", description: "UPPER LEFT, EYELID", codeSet: "WLP" },
  { id: "E2", description: "LOWER LEFT, EYELID", codeSet: "WLP" },
  { id: "E3", description: "UPPER RIGHT, EYELID", codeSet: "WLP" },
  { id: "E4", description: "LOWER RIGHT, EYELID", codeSet: "WLP" },
  {
    id: "EA",
    description: "ERYTHROPOETIC STIMULATING AGENT (ESA) ADMINISTERED",
    codeSet: "WLP",
  },
  {
    id: "EB",
    description: "ERYTHROPOETIC STIMULATING AGENT (ESA) ADMINISTERED",
    codeSet: "WLP",
  },
  {
    id: "EC",
    description: "ERYTHROPOETIC STIMULATING AGENT (ESA) ADMINISTERED",
    codeSet: "WLP",
  },
  {
    id: "ED",
    description: "HEMATOCRIT LEVEL HAS EXCEEDED 39% (OR HEMOGLOBIN L",
    codeSet: "WLP",
  },
  {
    id: "EE",
    description: "HEMATOCRIT LEVEL HAS NOT EXCEEDED 39% (OR HEMOGLOB",
    codeSet: "WLP",
  },
  {
    id: "EG",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "EH",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "EI",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "EJ",
    description: "SUBSEQUENT CLAIMS FOR A DEFINED COURSE OF THERAPY,",
    codeSet: "WLP",
  },
  {
    id: "EM",
    description: "EMERGENCY RESERVE SUPPLY (FOR ESRD BENEFIT ONLY) (",
    codeSet: "WLP",
  },
  {
    id: "EN",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "EP",
    description:
      "Residential, domiciliary, custodial facility (nursing home, not SNF) to Physicians Office",
    codeSet: "WLP",
  },
  {
    id: "EP",
    description: "SERVICE PROVIDED AS PART OF MEDICAID EARLY PERIODI",
    codeSet: "WLP",
  },
  {
    id: "ER",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "ES",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  { id: "ET", description: "EMERGENCY SERVICES", codeSet: "WLP" },
  {
    id: "EX",
    description: "From Residential, domiciliary, custodial facilityÂ ",
    codeSet: "WLP",
  },
  {
    id: "EY",
    description: "NO PHYSICIAN OR OTHER LICENSED HEALTH CARE PROVIDE",
    codeSet: "WLP",
  },
  { id: "F1", description: "LEFT HAND, SECOND DIGIT", codeSet: "WLP" },
  { id: "F2", description: "LEFT HAND, THIRD DIGIT", codeSet: "WLP" },
  { id: "F3", description: "LEFT HAND, FOURTH DIGIT", codeSet: "WLP" },
  { id: "F4", description: "LEFT HAND, FIFTH DIGIT", codeSet: "WLP" },
  { id: "F5", description: "RIGHT HAND, THUMB", codeSet: "WLP" },
  { id: "F6", description: "RIGHT HAND, SECOND DIGIT", codeSet: "WLP" },
  { id: "F7", description: "RIGHT HAND, THIRD DIGIT", codeSet: "WLP" },
  { id: "F8", description: "RIGHT HAND, FOURTH DIGIT", codeSet: "WLP" },
  { id: "F9", description: "RIGHT HAND, FIFTH DIGIT", codeSet: "WLP" },
  { id: "FA", description: "LEFT HAND, THUMB", codeSet: "WLP" },
  {
    id: "FB",
    description: "ITEM PROVIDED WITHOUT COST TO PROVIDER, SUPPLIER O",
    codeSet: "WLP",
  },
  {
    id: "FC",
    description: "PARTIAL CREDIT RECEIVED FOR REPLACED DEVICE (Speci",
    codeSet: "WLP",
  },
  {
    id: "FP",
    description: "SERVICE PROVIDED AS PART OF FAMILY PLANNING PROGRA",
    codeSet: "WLP",
  },
  {
    id: "G1",
    description: "MOST RECENT URR READING OF LESS THAN 60",
    codeSet: "WLP",
  },
  {
    id: "G2",
    description: "MOST RECENT URR READING OF 60 TO 64.9",
    codeSet: "WLP",
  },
  {
    id: "G3",
    description: "MOST RECENT URR READING OF 65 TO 69.9",
    codeSet: "WLP",
  },
  {
    id: "G4",
    description: "MOST RECENT URR READING OF 70 TO 74.9",
    codeSet: "WLP",
  },
  {
    id: "G5",
    description: "MOST RECENT URR READING OF 75 OR GREATER",
    codeSet: "WLP",
  },
  {
    id: "G6",
    description: "ESRD PATIENT FOR WHOM LESS THAN SIX DIALYSIS SESSI",
    codeSet: "WLP",
  },
  {
    id: "G7",
    description: "PREGNANCY RESULTED FROM RAPE OR INCEST OR PREGNANC",
    codeSet: "WLP",
  },
  {
    id: "G8",
    description: "MONITORED ANESTHESIA CARE (MAC) FOR DEEP COMPLEX,Â ",
    codeSet: "WLP",
  },
  {
    id: "G9",
    description: "MONITORED ANESTHESIA CARE FOR PATIENT WHO HAS HIST",
    codeSet: "WLP",
  },
  {
    id: "GA",
    description: "WAIVER OF LIABILITY STATEMENT ISSUED AS REQUIRED B",
    codeSet: "WLP",
  },
  {
    id: "GB",
    description: "CLAIM BEING RE-SUBMITTED FOR PAYMENT BECAUSE IT IS",
    codeSet: "WLP",
  },
  {
    id: "GC",
    description: "THIS SERVICE HAS BEEN PERFORMED IN PART BY A RESID",
    codeSet: "WLP",
  },
  {
    id: "GD",
    description: "UNITS OF SERVICE EXCEEDS MEDICALLY UNLIKELY EDIT V",
    codeSet: "WLP",
  },
  {
    id: "GE",
    description: "THIS SERVICE HAS BEEN PERFORMED BY A RESIDENT WITH",
    codeSet: "WLP",
  },
  {
    id: "GF",
    description: "NON-PHYSICIAN (E.G. NURSE PRACTITIONER (NP), CERTI",
    codeSet: "WLP",
  },
  {
    id: "GG",
    description: "PERFORMANCE AND PAYMENT OF A SCREENING MAMMOGRAM A",
    codeSet: "WLP",
  },
  {
    id: "GH",
    description: "DIAGNOSTIC MAMMOGRAM CONVERTED FROM SCREENING MAMM",
    codeSet: "WLP",
  },
  {
    id: "GI",
    description: "From Hospital based ESRD facility To Site of trans",
    codeSet: "WLP",
  },
  {
    id: "GJ",
    description: "OPT OUT PHYSICIAN OR PRACTITIONER EMERGENCY OR URG",
    codeSet: "WLP",
  },
  {
    id: "GK",
    description: "REASONABLE AND NECESSARY ITEM/SERVICE ASSOCIATED W",
    codeSet: "WLP",
  },
  {
    id: "GL",
    description: "MEDICALLY UNNECESSARY UPGRADE PROVIDED INSTEAD OF",
    codeSet: "WLP",
  },
  {
    id: "GM",
    description: "MULTIPLE PATIENTS ON ONE AMBULANCE TRIP",
    codeSet: "WLP",
  },
  {
    id: "GN",
    description: "SERVICES DELIVERED UNDER AN OUTPATIENT SPEECH LANG",
    codeSet: "WLP",
  },
  {
    id: "GO",
    description: "SERVICES DELIVERED UNDER AN OUTPATIENT OCCUPATIONA",
    codeSet: "WLP",
  },
  {
    id: "GP",
    description: "SERVICES DELIVERED UNDER AN OUTPATIENT PHYSICAL TH",
    codeSet: "WLP",
  },
  {
    id: "GQ",
    description: "VIA ASYNCHRONOUS TELECOMMUNICATIONS SYSTEM",
    codeSet: "WLP",
  },
  {
    id: "GR",
    description: "THIS SERVICE WAS PERFORMED IN WHOLE OR IN PART BY",
    codeSet: "WLP",
  },
  {
    id: "GS",
    description: "DOSAGE OF ERYTHROPOIETIN STIMULATING AGENT HAS BEE",
    codeSet: "WLP",
  },
  {
    id: "GT",
    description: "VIA INTERACTIVE AUDIO AND VIDEO TELECOMMUNICATION",
    codeSet: "WLP",
  },
  {
    id: "GU",
    description: "WAIVER OF LIABILITY STATEMENT ISSUED AS REQUIRED B",
    codeSet: "WLP",
  },
  {
    id: "GV",
    description: "ATTENDING PHYSICIAN NOT EMPLOYED OR PAID UNDER ARR",
    codeSet: "WLP",
  },
  {
    id: "GW",
    description: "SERVICE NOT RELATED TO THE HOSPICE PATIENT'S TERMI",
    codeSet: "WLP",
  },
  {
    id: "GX",
    description: "NOTICE OF LIABILITY ISSUED, VOLUNTARY UNDER PAYERÂ ",
    codeSet: "WLP",
  },
  {
    id: "GY",
    description: "ITEM OR SERVICE STATUTORILY EXCLUDED, DOES NOT MEE",
    codeSet: "WLP",
  },
  {
    id: "GZ",
    description: "ITEM OR SERVICE EXPECTED TO BE DENIED AS NOT REASO",
    codeSet: "WLP",
  },
  {
    id: "H9",
    description: "COURT-ORDERED (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HA",
    description: "CHILD/ADOLESCENT PROGRAM (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HB",
    description: "ADULT PROGRAM, NON GERIATRIC (Not payable by Medic",
    codeSet: "WLP",
  },
  {
    id: "HC",
    description: "ADULT PROGRAM, GERIATRIC (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HD",
    description: "PREGNANT/PARENTING WOMEN'S PROGRAM (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "HE",
    description:
      "Hospital to Residential, domiciliary, custodial facility (nursing home, not SNF)",
    codeSet: "WLP",
  },
  {
    id: "HE",
    description: "MENTAL HEALTH PROGRAM (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HF",
    description: "SUBSTANCE ABUSE PROGRAM (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HG",
    description: "OPIOID ADDICTION TREATMENT PROGRAM (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "HH",
    description: "INTEGRATED MENTAL HEALTH/SUBSTANCE ABUSE PROGRAM (",
    codeSet: "WLP",
  },
  {
    id: "HI",
    description: "INTEGRATED MENTAL HEALTH AND MENTAL RETARDATION/DE",
    codeSet: "WLP",
  },
  {
    id: "HJ",
    description: "EMPLOYEE ASSISTANCE PROGRAM (Not payable by Medica",
    codeSet: "WLP",
  },
  {
    id: "HK",
    description: "SPECIALIZED MENTAL HEALTH PROGRAMS FOR HIGH-RISK P",
    codeSet: "WLP",
  },
  { id: "HL", description: "INTERN (Not payable by Medicare)", codeSet: "WLP" },
  {
    id: "HM",
    description: "LESS THAN BACHELOR DEGREE LEVEL (Not payable by Me",
    codeSet: "WLP",
  },
  {
    id: "HN",
    description: "BACHELORS DEGREE LEVEL (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HO",
    description: "MASTERS DEGREE LEVEL (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HP",
    description: "Hospital to Physician OfficeÂ Â Â Â ",
    codeSet: "WLP",
  },
  {
    id: "HP",
    description: "DOCTORAL LEVEL (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HQ",
    description: "GROUP SETTING (Not payable by Medicare)",
    codeSet: "WLP",
  },
  { id: "HR", description: "Hospital to Residence", codeSet: "WLP" },
  {
    id: "HR",
    description: "FAMILY/COUPLE WITH CLIENT PRESENT (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "HS",
    description: "FAMILY/COUPLE WITHOUT CLIENT PRESENT (Not payable",
    codeSet: "WLP",
  },
  {
    id: "HT",
    description: "MULTI-DISCIPLINARY TEAM (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "HU",
    description: "FUNDED BY CHILD WELFARE AGENCY (Not payable by Med",
    codeSet: "WLP",
  },
  {
    id: "HV",
    description: "FUNDED STATE ADDICTIONS AGENCY (Not payable by Med",
    codeSet: "WLP",
  },
  {
    id: "HW",
    description: "FUNDED BY STATE MENTAL HEALTH AGENCY (Not payable",
    codeSet: "WLP",
  },
  {
    id: "HX",
    description: "FUNDED BY COUNTY/LOCAL AGENCY (Not payable by Medi",
    codeSet: "WLP",
  },
  {
    id: "HY",
    description: "FUNDED BY JUVENILE JUSTICE AGENCY (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "HZ",
    description: "FUNDED BY CRIMINAL JUSTICE AGENCY (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "ID",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IE",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IG",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IH",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "II",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IJ",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IN",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IP",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IR",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IS",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "IX",
    description: "From Site of transfer (e.g. airport or helicopter",
    codeSet: "WLP",
  },
  {
    id: "J1",
    description: "COMPETITIVE ACQUISITION PROGRAM NO-PAY SUBMISSION",
    codeSet: "WLP",
  },
  {
    id: "J2",
    description: "COMPETITIVE ACQUISITION PROGRAM, RESTOCKING OF EME",
    codeSet: "WLP",
  },
  {
    id: "J3",
    description: "COMPETITIVE ACQUISITION PROGRAM (CAP), DRUG NOT AV",
    codeSet: "WLP",
  },
  {
    id: "J4",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  { id: "JA", description: "ADMINISTERED INTRAVENOUSLY", codeSet: "WLP" },
  { id: "JB", description: "ADMINISTERED SUBCUTANEOUSLY", codeSet: "WLP" },
  { id: "JC", description: "SKIN SUBSTITUTE USED AS A GRAFT", codeSet: "WLP" },
  {
    id: "JD",
    description: "SKIN SUBSTITUTE NOT USED AS A GRAFT",
    codeSet: "WLP",
  },
  { id: "JE", description: "ADMINISTERED VIA DIALYSATE", codeSet: "WLP" },
  {
    id: "JG",
    description: "From Freestanding ESRD facility To Hospital based",
    codeSet: "WLP",
  },
  {
    id: "JH",
    description: "From Freestanding ESRD facility To Hospital",
    codeSet: "WLP",
  },
  {
    id: "JI",
    description: "From Freestanding ESRD facility To Site of transfe",
    codeSet: "WLP",
  },
  {
    id: "JJ",
    description: "From Freestanding ESRD facility To Freestanding ES",
    codeSet: "WLP",
  },
  {
    id: "JN",
    description: "From Freestanding ESRD facility To Skilled Nursing",
    codeSet: "WLP",
  },
  {
    id: "JP",
    description: "From Freestanding ESRD facility To Physician's off",
    codeSet: "WLP",
  },
  {
    id: "JR",
    description: "Non-Hospital based dialysis facility to Residence",
    codeSet: "WLP",
  },
  {
    id: "JR",
    description: "From Freestanding ESRD facility To Residence",
    codeSet: "WLP",
  },
  {
    id: "JS",
    description: "From Freestanding ESRD facility To Scene of accide",
    codeSet: "WLP",
  },
  {
    id: "JW",
    description: "DRUG AMOUNT DISCARDED/NOT ADMINISTERED TO ANY PATI",
    codeSet: "WLP",
  },
  {
    id: "JX",
    description: "From Freestanding ESRD facility To Intermediate st",
    codeSet: "WLP",
  },
  {
    id: "K0",
    description: "LOWER EXTREMITY PROSTHESIS FUNCTIONAL LEVEL 0 - DO",
    codeSet: "WLP",
  },
  {
    id: "K1",
    description: "LOWER EXTREMITY PROSTHESIS FUNCTIONAL LEVEL 1 - HA",
    codeSet: "WLP",
  },
  {
    id: "K2",
    description: "LOWER EXTREMITY PROSTHESIS FUNCTIONAL LEVEL 2 - HA",
    codeSet: "WLP",
  },
  {
    id: "K3",
    description: "LOWER EXTREMITY PROSTHESIS FUNCTIONAL LEVEL 3 - HA",
    codeSet: "WLP",
  },
  {
    id: "K4",
    description: "LOWER EXTREMITY PROSTHESIS FUNCTIONAL LEVEL 4 - HA",
    codeSet: "WLP",
  },
  {
    id: "KA",
    description: "ADD ON OPTION/ACCESSORY FOR WHEELCHAIR",
    codeSet: "WLP",
  },
  {
    id: "KB",
    description: "BENEFICIARY REQUESTED UPGRADE FOR ABN, MORE THAN 4",
    codeSet: "WLP",
  },
  {
    id: "KC",
    description: "REPLACEMENT OF SPECIAL POWER WHEELCHAIR INTERFACE",
    codeSet: "WLP",
  },
  {
    id: "KD",
    description: "DRUG OR BIOLOGICAL INFUSED THROUGH DME",
    codeSet: "WLP",
  },
  {
    id: "KE",
    description: "BID UNDER ROUND ONE OF THE DMEPOS COMPETITIVE BIDD",
    codeSet: "WLP",
  },
  {
    id: "KF",
    description: "ITEM DESIGNATED BY FDA AS CLASS III DEVICE",
    codeSet: "WLP",
  },
  {
    id: "KG",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  {
    id: "KH",
    description: "DMEPOS ITEM, INITIAL CLAIM, PURCHASE OR FIRST MONT",
    codeSet: "WLP",
  },
  {
    id: "KI",
    description: "DMEPOS ITEM, SECOND OR THIRD MONTH RENTAL",
    codeSet: "WLP",
  },
  {
    id: "KJ",
    description: "DMEPOS ITEM, PARENTERAL ENTERAL NUTRITION (PEN) PU",
    codeSet: "WLP",
  },
  {
    id: "KK",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  { id: "KL", description: "DMEPOS ITEM DELIVERED VIA MAIL", codeSet: "WLP" },
  {
    id: "KM",
    description: "REPLACEMENT OF FACIAL PROSTHESIS INCLUDING NEW IMP",
    codeSet: "WLP",
  },
  {
    id: "KN",
    description: "REPLACEMENT OF FACIAL PROSTHESIS USING PREVIOUS MA",
    codeSet: "WLP",
  },
  {
    id: "KO",
    description: "SINGLE DRUG UNIT DOSE FORMULATION",
    codeSet: "WLP",
  },
  {
    id: "KP",
    description: "FIRST DRUG OF A MULTIPLE DRUG UNIT DOSE FORMULATIO",
    codeSet: "WLP",
  },
  {
    id: "KQ",
    description: "SECOND OR SUBSEQUENT DRUG OF A MULTIPLE DRUG UNIT",
    codeSet: "WLP",
  },
  {
    id: "KR",
    description: "RENTAL ITEM, BILLING FOR PARTIAL MONTH",
    codeSet: "WLP",
  },
  {
    id: "KS",
    description: "GLUCOSE MONITOR SUPPLY FOR DIABETIC BENEFICIARY NO",
    codeSet: "WLP",
  },
  {
    id: "KT",
    description: "BENEFICIARY RESIDES IN A COMPETITIVE BIDDING AREA",
    codeSet: "WLP",
  },
  {
    id: "KU",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  {
    id: "KV",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  {
    id: "KW",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  {
    id: "KX",
    description: "REQUIREMENTS SPECIFIED IN THE MEDICAL POLICY HAVE",
    codeSet: "WLP",
  },
  {
    id: "KY",
    description: "DMEPOS ITEM SUBJECT TO DMEPOS COMPETITIVE BIDDING",
    codeSet: "WLP",
  },
  {
    id: "KZ",
    description: "NEW COVERAGE NOT IMPLEMENTED BY MANAGED CARE",
    codeSet: "WLP",
  },
  {
    id: "L1",
    description: "PROVIDER ATTESTATION THAT THE HOSPITAL LABORATORY",
    codeSet: "WLP",
  },
  { id: "LC", description: "LEFT CIRCUMFLEX CORONARY ARTERY", codeSet: "WLP" },
  {
    id: "LD",
    description: "LEFT ANTERIOR DESCENDING CORONARY ARTERY",
    codeSet: "WLP",
  },
  {
    id: "LL",
    description: "LEASE/RENTAL (USE THE 'LL' MODIFIER WHEN DME EQUIP",
    codeSet: "WLP",
  },
  { id: "LM", description: "LEFT MAIN CORONARY ARTERY", codeSet: "WLP" },
  { id: "LR", description: "LABORATORY ROUND TRIP", codeSet: "WLP" },
  {
    id: "LS",
    description: "FDA-MONITORED INTRAOCULAR LENS IMPLANT (Special co",
    codeSet: "WLP",
  },
  { id: "M2", description: "MEDICARE SECONDARY PAYER (MSP)", codeSet: "WLP" },
  {
    id: "MS",
    description: "SIX MONTH MAINTENANCE AND SERVICING FEE FOR REASON",
    codeSet: "WLP",
  },
  {
    id: "NB",
    description: "NEBULIZER SYSTEM, ANY TYPE, FDA-CLEARED FOR USE WI",
    codeSet: "WLP",
  },
  {
    id: "ND",
    description: "From Skilled Nursing Facility To Diagnostic or the",
    codeSet: "WLP",
  },
  {
    id: "NE",
    description: "From Skilled Nursing Facility To Residential, domi",
    codeSet: "WLP",
  },
  {
    id: "NG",
    description: "From Skilled Nursing Facility To Hospital based ES",
    codeSet: "WLP",
  },
  {
    id: "NH",
    description: "From Skilled Nursing Facility To Hospital",
    codeSet: "WLP",
  },
  {
    id: "NI",
    description: "From Skilled Nursing Facility To Site of transfer",
    codeSet: "WLP",
  },
  {
    id: "NJ",
    description: "From Skilled Nursing Facility To Freestanding ESRD",
    codeSet: "WLP",
  },
  {
    id: "NN",
    description: "From Skilled Nursing Facility To Skilled Nursing F",
    codeSet: "WLP",
  },
  { id: "NP", description: "SNF toÂ  Physicians office", codeSet: "WLP" },
  {
    id: "NR",
    description: "NEW WHEN RENTED (USE THE 'NR' MODIFIER WHEN DME WH",
    codeSet: "WLP",
  },
  {
    id: "NS",
    description: "From Skilled Nursing Facility To Scene of accident",
    codeSet: "WLP",
  },
  { id: "NU", description: "PURCHASE", codeSet: "WLP" },
  { id: "NU", description: "NEW EQUIPMENT", codeSet: "WLP" },
  {
    id: "NX",
    description: "From Skilled Nursing Facility To Intermediate stop",
    codeSet: "WLP",
  },
  { id: "P1", description: "A NORMAL HEALTHY PATIENT", codeSet: "WLP" },
  {
    id: "P2",
    description: "A PATIENT WITH MILD SYSTEMIC DISEASE",
    codeSet: "WLP",
  },
  {
    id: "P3",
    description: "A PATIENT WITH SEVERE SYSTEMIC DISEASE",
    codeSet: "WLP",
  },
  {
    id: "P4",
    description: "A PATIENT WITH SEVERE SYSTEMIC DISEASE THAT IS A C",
    codeSet: "WLP",
  },
  {
    id: "P5",
    description: "A MORIBUND PATIENT WHO IS NOT EXPECTED TO SURVIVE",
    codeSet: "WLP",
  },
  {
    id: "P6",
    description: "A DECLARED BRAIN-DEAD PATIENT WHOSE ORGANS ARE BEI",
    codeSet: "WLP",
  },
  {
    id: "PA",
    description: "SURGICAL OR OTHER INVASIVE PROCEDURE ON WRONG BODY",
    codeSet: "WLP",
  },
  {
    id: "PB",
    description: "SURGICAL OR OTHER INVASIVE PROCEDURE ON WRONG PATI",
    codeSet: "WLP",
  },
  {
    id: "PC",
    description: "WRONG SURGERY OR OTHER INVASIVE PROCEDURE ON PATIE",
    codeSet: "WLP",
  },
  {
    id: "PD",
    description: "DIAGNOSTIC OR RELATED NON DIAGNOSTIC ITEM OR SERVI",
    codeSet: "WLP",
  },
  {
    id: "PE",
    description: "From Physician's office To Residential, domiciliar",
    codeSet: "WLP",
  },
  {
    id: "PG",
    description: "From Physician's office To Hospital based ESRD fac",
    codeSet: "WLP",
  },
  {
    id: "PH",
    description: "From Physician's office To Hospital",
    codeSet: "WLP",
  },
  {
    id: "PI",
    description: "POSITRON EMISSION TOMOGRAPHY (PET) OR PET/COMPUTED",
    codeSet: "WLP",
  },
  {
    id: "PJ",
    description: "From Physician's office To Freestanding ESRD facil",
    codeSet: "WLP",
  },
  { id: "PL", description: "PROGRESSIVE ADDITION LENSES", codeSet: "WLP" },
  { id: "PM", description: "POST MORTEM", codeSet: "WLP" },
  {
    id: "PN",
    description: "Physician OfficeÂ  toÂ  SNFÂ Â Â Â Â Â Â Â Â ",
    codeSet: "WLP",
  },
  {
    id: "PP",
    description: "From Physician's office To Physician's office",
    codeSet: "WLP",
  },
  {
    id: "PR",
    description: "Physician OfficeÂ  toÂ  Residence",
    codeSet: "WLP",
  },
  {
    id: "PS",
    description: "POSITRON EMISSION TOMOGRAPHY (PET) OR PET/COMPUTED",
    codeSet: "WLP",
  },
  {
    id: "PT",
    description: "COLORECTAL CANCER SCREENING TEST; CONVERTED TO DIA",
    codeSet: "WLP",
  },
  {
    id: "PX",
    description: "From Physician's office To Intermediate stop at ph",
    codeSet: "WLP",
  },
  {
    id: "Q0",
    description: "INVESTIGATIONAL CLINICAL SERVICE PROVIDED IN A CLI",
    codeSet: "WLP",
  },
  {
    id: "Q1",
    description: "ROUTINE CLINICAL SERVICE PROVIDED IN A CLINICAL RE",
    codeSet: "WLP",
  },
  {
    id: "Q2",
    description: "HCFA/ORD DEMONSTRATION PROJECT PROCEDURE/SERVICE",
    codeSet: "WLP",
  },
  {
    id: "Q3",
    description: "LIVE KIDNEY DONOR SURGERY AND RELATED SERVICES",
    codeSet: "WLP",
  },
  {
    id: "Q4",
    description: "SERVICE FOR ORDERING/REFERRING PHYSICIAN QUALIFIES",
    codeSet: "WLP",
  },
  {
    id: "Q5",
    description: "SERVICE FURNISHED BY A SUBSTITUTE PHYSICIAN UNDER",
    codeSet: "WLP",
  },
  {
    id: "Q6",
    description: "SERVICE FURNISHED BY A LOCUM TENENS PHYSICIAN (Spe",
    codeSet: "WLP",
  },
  { id: "Q7", description: "ONE CLASS A FINDING", codeSet: "WLP" },
  { id: "Q8", description: "TWO CLASS B FINDINGS", codeSet: "WLP" },
  {
    id: "Q9",
    description: "ONE CLASS B AND TWO CLASS C FINDINGS",
    codeSet: "WLP",
  },
  {
    id: "QB",
    description: "Physician providing service in a rural HPSA",
    codeSet: "WLP",
  },
  { id: "QC", description: "SINGLE CHANNEL MONITORING", codeSet: "WLP" },
  {
    id: "QD",
    description: "RECORDING AND STORAGE IN SOLID STATE MEMORY BY A D",
    codeSet: "WLP",
  },
  {
    id: "QE",
    description: "PRESCRIBED AMOUNT OF OXYGEN IS LESS THAN 1 LITER P",
    codeSet: "WLP",
  },
  {
    id: "QF",
    description: "PRESCRIBED AMOUNT OF OXYGEN EXCEEDS 4 LITERS PER M",
    codeSet: "WLP",
  },
  {
    id: "QG",
    description: "PRESCRIBED AMOUNT OF OXYGEN IS GREATER THAN 4 LITE",
    codeSet: "WLP",
  },
  {
    id: "QH",
    description: "OXYGEN CONSERVING DEVICE IS BEING USED WITH AN OXY",
    codeSet: "WLP",
  },
  {
    id: "QJ",
    description: "SERVICES/ITEMS PROVIDED TO A PRISONER OR PATIENT I",
    codeSet: "WLP",
  },
  {
    id: "QK",
    description: "MEDICAL DIRECTION OF TWO, THREE, OR FOUR CONCURREN",
    codeSet: "WLP",
  },
  {
    id: "QL",
    description: "PATIENT PRONOUNCED DEAD AFTER AMBULANCE CALLED",
    codeSet: "WLP",
  },
  {
    id: "QM",
    description: "AMBULANCE SERVICE PROVIDED UNDER ARRANGEMENT BY A",
    codeSet: "WLP",
  },
  {
    id: "QN",
    description: "AMBULANCE SERVICE FURNISHED DIRECTLY BY A PROVIDER",
    codeSet: "WLP",
  },
  {
    id: "QP",
    description: "DOCUMENTATION IS ON FILE SHOWING THAT THE LABORATO",
    codeSet: "WLP",
  },
  {
    id: "QQ",
    description: "Claim submitted with a written statement of intent",
    codeSet: "WLP",
  },
  {
    id: "QS",
    description: "MONITORED ANESTHESIA CARE SERVICE (Special coverag",
    codeSet: "WLP",
  },
  {
    id: "QT",
    description: "RECORDING AND STORAGE ON TAPE BY AN ANALOG TAPE RE",
    codeSet: "WLP",
  },
  {
    id: "QU",
    description: "Physician providing service in an urban HPSA",
    codeSet: "WLP",
  },
  {
    id: "QV",
    description: "Routine care in a Medicare qualifying trial",
    codeSet: "WLP",
  },
  { id: "QW", description: "CLIA WAIVED TEST", codeSet: "WLP" },
  {
    id: "QX",
    description: "CRNA SERVICE: WITH MEDICAL DIRECTION BY A PHYSICIA",
    codeSet: "WLP",
  },
  {
    id: "QY",
    description: "MEDICAL DIRECTION OF ONE CERTIFIED REGISTERED NURS",
    codeSet: "WLP",
  },
  {
    id: "QZ",
    description: "CRNA SERVICE: WITHOUT MEDICAL DIRECTION BY A PHYSI",
    codeSet: "WLP",
  },
  {
    id: "RA",
    description: "REPLACEMENT OF A DME, ORTHOTIC OR PROSTHETIC ITEM",
    codeSet: "WLP",
  },
  {
    id: "RB",
    description: "REPLACEMENT OF A PART OF A DME, ORTHOTIC OR PROSTH",
    codeSet: "WLP",
  },
  { id: "RC", description: "RIGHT CORONARY ARTERY", codeSet: "WLP" },
  {
    id: "RD",
    description: "DRUG PROVIDED TO BENEFICIARY, BUT NOT ADMINISTERED",
    codeSet: "WLP",
  },
  {
    id: "RE",
    description: "FURNISHED IN FULL COMPLIANCE WITH FDA-MANDATED RIS",
    codeSet: "WLP",
  },
  {
    id: "RG",
    description: "From Residence To Hospital based ESRD facility",
    codeSet: "WLP",
  },
  { id: "RH", description: "From Residence To Hospital", codeSet: "WLP" },
  {
    id: "RI",
    description: "RAMUS INTERMEDIUS CORONARY ARTERY",
    codeSet: "WLP",
  },
  {
    id: "RJ",
    description: "Residence to Non-Hospital based dialysis facility",
    codeSet: "WLP",
  },
  {
    id: "RJ",
    description: "From Residence To Freestanding ESRD facility",
    codeSet: "WLP",
  },
  {
    id: "RN",
    description: "From Residence To Skilled Nursing Facility",
    codeSet: "WLP",
  },
  { id: "RP", description: "ResidenceÂ  to Physician Office", codeSet: "WLP" },
  { id: "RR", description: "RENTAL", codeSet: "WLP" },
  {
    id: "RS",
    description: "From Residence To Scene of accident or acute event",
    codeSet: "WLP",
  },
  {
    id: "RX",
    description: "From Residence To Intermediate stop at physician's",
    codeSet: "WLP",
  },
  {
    id: "SA",
    description: "NURSE PRACTITIONER RENDERING SERVICE IN COLLABORAT",
    codeSet: "WLP",
  },
  {
    id: "SB",
    description: "NURSE MIDWIFE (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "SC",
    description: "MEDICALLY NECESSARY SERVICE OR SUPPLY",
    codeSet: "WLP",
  },
  {
    id: "SD",
    description: "SERVICES PROVIDED BY REGISTERED NURSE WITH SPECIAL",
    codeSet: "WLP",
  },
  {
    id: "SE",
    description: "STATE AND/OR FEDERALLY-FUNDED PROGRAMS/SERVICES (N",
    codeSet: "WLP",
  },
  {
    id: "SF",
    description: "SECOND OPINION ORDERED BY A PROFESSIONAL REVIEW OR",
    codeSet: "WLP",
  },
  {
    id: "SG",
    description: "AMBULATORY SURGICAL CENTER (ASC) FACILITY SERVICE",
    codeSet: "WLP",
  },
  {
    id: "SH",
    description: "SECOND CONCURRENTLY ADMINISTERED INFUSION THERAPY",
    codeSet: "WLP",
  },
  {
    id: "SI",
    description: "From Scene of accident or acute event To Site of t",
    codeSet: "WLP",
  },
  {
    id: "SJ",
    description: "THIRD OR MORE CONCURRENTLY ADMINISTERED INFUSION T",
    codeSet: "WLP",
  },
  {
    id: "SK",
    description: "MEMBER OF HIGH RISK POPULATION (USE ONLY WITH CODE",
    codeSet: "WLP",
  },
  {
    id: "SL",
    description: "STATE SUPPLIED VACCINE (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "SM",
    description: "SECOND SURGICAL OPINION (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "SN",
    description: "THIRD SURGICAL OPINION (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "SP",
    description: "From Scene of accident or acute event To Physician",
    codeSet: "WLP",
  },
  {
    id: "SQ",
    description: "ITEM ORDERED BY HOME HEALTH (Not payable by Medica",
    codeSet: "WLP",
  },
  {
    id: "SR",
    description: "From Scene of accident or acute event To Residence",
    codeSet: "WLP",
  },
  {
    id: "SS",
    description: "HOME INFUSION SERVICES PROVIDED IN THE INFUSION SU",
    codeSet: "WLP",
  },
  {
    id: "ST",
    description: "RELATED TO TRAUMA OR INJURY (Not payable by Medica",
    codeSet: "WLP",
  },
  {
    id: "SU",
    description: "PROCEDURE PERFORMED IN PHYSICIAN'S OFFICE (TO DENO",
    codeSet: "WLP",
  },
  {
    id: "SV",
    description: "PHARMACEUTICALS DELIVERED TO PATIENT'S HOME BUT NO",
    codeSet: "WLP",
  },
  {
    id: "SW",
    description: "SERVICES PROVIDED BY A CERTIFIED DIABETIC EDUCATOR",
    codeSet: "WLP",
  },
  {
    id: "SX",
    description: "From Scene of accident or acute event To Intermedi",
    codeSet: "WLP",
  },
  {
    id: "SY",
    description: "PERSONS WHO ARE IN CLOSE CONTACT WITH MEMBER OF HI",
    codeSet: "WLP",
  },
  { id: "SZ", description: "HABILITATIVE SERVICES", codeSet: "WLP" },
  { id: "T1", description: "LEFT FOOT, SECOND DIGIT", codeSet: "WLP" },
  { id: "T2", description: "LEFT FOOT, THIRD DIGIT", codeSet: "WLP" },
  { id: "T3", description: "LEFT FOOT, FOURTH DIGIT", codeSet: "WLP" },
  { id: "T4", description: "LEFT FOOT, FIFTH DIGIT", codeSet: "WLP" },
  { id: "T5", description: "RIGHT FOOT, GREAT TOE", codeSet: "WLP" },
  { id: "T6", description: "RIGHT FOOT, SECOND DIGIT", codeSet: "WLP" },
  { id: "T7", description: "RIGHT FOOT, THIRD DIGIT", codeSet: "WLP" },
  { id: "T8", description: "RIGHT FOOT, FOURTH DIGIT", codeSet: "WLP" },
  { id: "T9", description: "RIGHT FOOT, FIFTH DIGIT", codeSet: "WLP" },
  { id: "TA", description: "LEFT FOOT, GREAT TOE", codeSet: "WLP" },
  {
    id: "TC",
    description: "TECHNICAL COMPONENT; UNDER CERTAIN CIRCUMSTANCES,Â ",
    codeSet: "WLP",
  },
  { id: "TD", description: "RN (Not payable by Medicare)", codeSet: "WLP" },
  {
    id: "TE",
    description: "LPN/LVN (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "TF",
    description: "INTERMEDIATE LEVEL OF CARE (Not payable by Medicar",
    codeSet: "WLP",
  },
  {
    id: "TG",
    description: "COMPLEX/HIGH TECH LEVEL OF CARE (Not payable by Me",
    codeSet: "WLP",
  },
  {
    id: "TH",
    description: "OBSTETRICAL TREATMENT/SERVICES, PRENATAL OR POSTPA",
    codeSet: "WLP",
  },
  {
    id: "TJ",
    description: "PROGRAM GROUP, CHILD AND/OR ADOLESCENT (Not payabl",
    codeSet: "WLP",
  },
  {
    id: "TK",
    description: "EXTRA PATIENT OR PASSENGER, NON-AMBULANCE (Not pay",
    codeSet: "WLP",
  },
  {
    id: "TL",
    description: "EARLY INTERVENTION/INDIVIDUALIZED FAMILY SERVICE P",
    codeSet: "WLP",
  },
  {
    id: "TM",
    description: "INDIVIDUALIZED EDUCATION PROGRAM (IEP) (Not payabl",
    codeSet: "WLP",
  },
  {
    id: "TN",
    description: "RURAL/OUTSIDE PROVIDERS' CUSTOMARY SERVICE AREA (N",
    codeSet: "WLP",
  },
  {
    id: "TP",
    description: "MEDICAL TRANSPORT, UNLOADED VEHICLE (Not payable b",
    codeSet: "WLP",
  },
  {
    id: "TQ",
    description: "BASIC LIFE SUPPORT TRANSPORT BY A VOLUNTEER AMBULA",
    codeSet: "WLP",
  },
  {
    id: "TR",
    description: "SCHOOL-BASED INDIVIDUALIZED EDUCATION PROGRAM (IEP",
    codeSet: "WLP",
  },
  { id: "TS", description: "FOLLOW-UP SERVICE", codeSet: "WLP" },
  {
    id: "TT",
    description: "INDIVIDUALIZED SERVICE PROVIDED TO MORE THAN ONE P",
    codeSet: "WLP",
  },
  {
    id: "TU",
    description: "SPECIAL PAYMENT RATE, OVERTIME (Not payable by Med",
    codeSet: "WLP",
  },
  {
    id: "TV",
    description: "SPECIAL PAYMENT RATES, HOLIDAYS/WEEKENDS (Not paya",
    codeSet: "WLP",
  },
  {
    id: "TW",
    description: "BACK-UP EQUIPMENT (Not payable by Medicare)",
    codeSet: "WLP",
  },
  {
    id: "U1",
    description: "MEDICAID LEVEL OF CARE 1, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U2",
    description: "MEDICAID LEVEL OF CARE 2, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U3",
    description: "MEDICAID LEVEL OF CARE 3, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U4",
    description: "MEDICAID LEVEL OF CARE 4, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U5",
    description: "MEDICAID LEVEL OF CARE 5, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U6",
    description: "MEDICAID LEVEL OF CARE 6, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U7",
    description: "MEDICAID LEVEL OF CARE 7, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U8",
    description: "MEDICAID LEVEL OF CARE 8, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "U9",
    description: "MEDICAID LEVEL OF CARE 9, AS DEFINED BY EACH STATE",
    codeSet: "WLP",
  },
  {
    id: "UA",
    description: "MEDICAID LEVEL OF CARE 10, AS DEFINED BY EACH STAT",
    codeSet: "WLP",
  },
  {
    id: "UB",
    description: "MEDICAID LEVEL OF CARE 11, AS DEFINED BY EACH STAT",
    codeSet: "WLP",
  },
  {
    id: "UC",
    description: "MEDICAID LEVEL OF CARE 12, AS DEFINED BY EACH STAT",
    codeSet: "WLP",
  },
  {
    id: "UD",
    description: "MEDICAID LEVEL OF CARE 13, AS DEFINED BY EACH STAT",
    codeSet: "WLP",
  },
  { id: "UE", description: "USED DURABLE MEDICAL EQUIPMENT", codeSet: "WLP" },
  {
    id: "UF",
    description: "SERVICES PROVIDED IN THE MORNING (Not payable by M",
    codeSet: "WLP",
  },
  {
    id: "UG",
    description: "SERVICES PROVIDED IN THE AFTERNOON (Not payable by",
    codeSet: "WLP",
  },
  {
    id: "UH",
    description: "SERVICES PROVIDED IN THE EVENING (Not payable by M",
    codeSet: "WLP",
  },
  {
    id: "UJ",
    description: "SERVICES PROVIDED AT NIGHT (Not payable by Medicar",
    codeSet: "WLP",
  },
  {
    id: "UK",
    description: "SERVICES PROVIDED ON BEHALF OF THE CLIENT TO SOMEO",
    codeSet: "WLP",
  },
  { id: "UN", description: "TWO PATIENTS SERVED", codeSet: "WLP" },
  { id: "UP", description: "THREE PATIENTS SERVED", codeSet: "WLP" },
  { id: "UQ", description: "FOUR PATIENTS SERVED", codeSet: "WLP" },
  { id: "UR", description: "FIVE PATIENTS SERVED", codeSet: "WLP" },
  { id: "US", description: "SIX OR MORE PATIENTS SERVED", codeSet: "WLP" },
  {
    id: "V5",
    description: "VASCULAR CATHETER (ALONE OR WITH ANY OTHER VASCULA",
    codeSet: "WLP",
  },
  {
    id: "V6",
    description: "ARTERIOVENOUS GRAFT (OR OTHER VASCULAR ACCESS NOT",
    codeSet: "WLP",
  },
  {
    id: "V7",
    description: "ARTERIOVENOUS FISTULA ONLY (IN USE WITH TWO NEEDLE",
    codeSet: "WLP",
  },
  { id: "VP", description: "APHAKIC PATIENT", codeSet: "WLP" },
  {
    id: "HNGT",
    description: "Bio-psychosocial Evaluation, mental health Telemedicine",
    codeSet: "WLP",
  },
  {
    id: "HOGT",
    description: "In-depth assessment, new patient, mental health Telemedicine",
    codeSet: "WLP",
  },
  {
    id: "TSGT",
    description:
      "In-depth assessment, established patient, mental health Telemedicine",
    codeSet: "WLP",
  },
  {
    id: "BAGK",
    description: "Behavior Analysis - Group (up to 6)",
    codeSet: "WLP",
  },
  {
    id: "HRGT",
    description:
      "Behavioral Health Therapy: Individual and Family Telemedicine",
    codeSet: "WLP",
  },
  {
    id: "96",
    description:
      "Habilitative-services that help a person DEVELOP skills or functions they didn't have before",
    codeSet: "WLP",
  },
  {
    id: "97",
    description:
      "Rehabilitative- services that help a person RESTORE functions which have become either impaired or lost",
    codeSet: "WLP",
  },
];

export interface AdmissionTypeCode {
  id: string;
  ediCode: string;
  selectable: boolean;
  description: string;
}

export const AdmissionTypeCodeList: Array<AdmissionTypeCode> = [
  { id: "", ediCode: "0", selectable: false, description: "" },
  {
    id: "EMERGENCY",
    ediCode: "1",
    selectable: true,
    description:
      "The patient required immediate medical intervention as a result of severe, life threatening, or potentially disabling conditions. Generally, the patient was admitted through the emergency room.",
  },
  {
    id: "URGENT",
    ediCode: "2",
    selectable: true,
    description:
      "The patient required immediate attention for the care and treatment of a physical or mental disorder. Generally, the patient was admitted to the first available and suitable accommodation.",
  },
  {
    id: "ELECTIVE",
    ediCode: "3",
    selectable: true,
    description:
      "The patient's condition permitted adequate time to schedule the availability of suitable accommodations.",
  },
  {
    id: "NEWBORN",
    ediCode: "4",
    selectable: true,
    description: "Necessitates the use of special source of admission codes.",
  },
  {
    id: "TRAUMA_CENTER",
    ediCode: "5",
    selectable: true,
    description:
      "visits to a trauma center/hospital as licensed or designated by the State or local government authority authorized to do so, or as verified by the American College of Surgeons and involving a trauma activation.",
  },
  { id: "Reserved", ediCode: "6", selectable: false, description: "Reserved" },
  { id: "Reserved", ediCode: "7", selectable: false, description: "Reserved" },
  { id: "Reserved", ediCode: "8", selectable: false, description: "Reserved" },
  {
    id: "UNKNOWN",
    ediCode: "9",
    selectable: false,
    description: "Unknown - Information not available",
  },
];

export interface AdmissionSourceCode {
  id: string;
  ediCode: string;
  description: string;
}

export const AdmissionSourceCodeList: Array<AdmissionSourceCode> = [
  {
    id: "NO_FACILITY",
    ediCode: "1",
    description: "Non-Health Facility Point of Origin",
  },
  { id: "CLINIC", ediCode: "2", description: "Clinic" },
  { id: "RESERVED", ediCode: "3", description: "Reserved" },
  {
    id: "XFER_HOSPITAL",
    ediCode: "4",
    description: "Transfer From a Hospital (Different Facility)",
  },
  {
    id: "XFER_SKILLED_NURSING",
    ediCode: "5",
    description:
      "Transfer From Skilled Nursing Facility (SNF) or Intermediate Care Facility (ICF)",
  },
  {
    id: "XFER_OTHER",
    ediCode: "6",
    description: "Transfer From Another Health Care Facility",
  },
  { id: "ER", ediCode: "7", description: "Emergency Room" },
  { id: "COURT_LAW", ediCode: "8", description: "Court/Law Enforcement" },
  { id: "NA", ediCode: "9", description: "Information Not Available" },
  {
    id: "XFER_SURGERY_CENTER",
    ediCode: "E",
    description: "Transfer from Ambulatory Surgery Center",
  },
  { id: "XFER_HOSPICE", ediCode: "F", description: "Transfer from Hospice" },
];
