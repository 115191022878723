//Global root singleton for axios requests wrapped in RxJS beforehand, with base url for endpoints set for env files
import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { from, Observable } from "rxjs";
import Vue from "vue";
import store from "@core/store/store";

export function ApiPlugin(_vue: typeof Vue, options?: unknown): void {
  _vue.prototype.$api = new Api();
}

export default class Api {
  private configOrDefault = (config?: unknown) => {
    return !!config ? this.addAuthorization(config) : this.addAuthorization({
      response: true
    })
  };

  public addAuthorization = (config: any) => {
    const idToken = store.state['UserModule']['idToken'];
    if (idToken) {
      return { ...config, headers: { ...config.headers, 'Authorization': idToken } }
    }
  }

  constructor() {
    Axios.defaults.headers.post['Content-Type'] = 'application/json';
    // Add a request interceptor
    Axios.interceptors.request.use(
      function(config) {
        // Do something before request is sent
        store.dispatch("GlobalModule/enterWaitingState");
        return config;
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    Axios.interceptors.response.use(
      function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch("GlobalModule/stopWaiting");
        return response;
      },
      function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  public request<T>(config: AxiosRequestConfig): Observable<AxiosResponse<T>> {
    return from(Axios.request<T>(config));
  }

  public get<T>(
    url: string,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return from(Axios.get<T>(url, this.configOrDefault(config)));
  }

  //DELETE
  public delete<T>(
    url: string,
    data? : unknown,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return !!data ? from(Axios.delete<T>(url, this.configOrDefault({...(config || {}) as Object, data }))) : from(Axios.delete<T>(url, this.configOrDefault(config)));
  }

  public head<T>(
    url: string,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return from(Axios.head<T>(url, this.configOrDefault(config)));
  }

  //POST
  public post<T>(
    url: string,
    data?: unknown,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return from(Axios.post<T>(url, data, this.configOrDefault(config)));
  }

  public put<T>(
    url: string,
    data?: unknown,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return from(Axios.put<T>(url, data, this.configOrDefault(config)));
  }

  //PATCH
  public patch<T>(
    url: string,
    data?: unknown,
    config?: unknown
  ): Observable<AxiosResponse<T>> {
    return from(Axios.patch<T>(url, data, this.configOrDefault(config)));
  }
}
