import Vue from "vue";
import Vuex from "vuex";
import UserModule from "./modules/user/user";
import CaseModule from "./modules/case/case";
import GlobalModule from "./modules/global/global";
Vue.use(Vuex);

// !!!!!!! IMPORTANT !!!!!!!!!
// NEVER change the NODE_ENV to be anything other than 'production' or 'development' as
// this has impacts on how webpack builds our website. If code without a NODE_ENV of
// 'production' actually makes it to production it will break our whole app
const debug = process.env.NODE_ENV !== "production";

const store = {
  modules: {
    GlobalModule,
    UserModule,
    CaseModule,
  },
  strict: debug,
};

export default new Vuex.Store(store);
