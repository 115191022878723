import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { decodeJwtBody } from "@/app/shared/utils";
import * as Sentry from "@sentry/vue";

@Module({ namespaced: true }) //marking the module as namespaced is necessary so we can access stores independently
export default class UserModule extends VuexModule {
  public loggedIn: boolean = false;
  public isAdmin: boolean = false;
  public idToken: string = "";
  public expirationRange: number = 0;
  public tokenExpiration: number = 0;
  public embedded: boolean = false;
  public organization: string = "";
  public id: string = "";
  public email: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public name: string = "";
  public activeOrgID: string = "";
  public refreshInterval: number = 0;
  public isReactTachyonEnabled: boolean = false;
  public userHasOrgs = false;
  public organizationIDs = [];

  get isReadOnly() {
    //There is no more read only role. This getter is used in ways I don't totally understand
    //so I'm just forcing false and moving on with my life.
    return false;
  }

  @Mutation
  public setLoggedIn(loggedInStatus: boolean): void {
    this.loggedIn = loggedInStatus;
  }
  @Action
  public logIn(): void {
    this.context.commit("setLoggedIn", true);
    this.context.dispatch("GlobalModule/resetSessionEndTime", null, {
      root: true,
    });
  }
  @Action
  public logout(): void {
    this.context.dispatch("clearIntervalAndExpiration");

    // Resets all state modules to defined initial state values.
    this.context.dispatch("resetCurrentUserState");
    this.context.dispatch("GlobalModule/resetCurrentGlobalState", null, {
      root: true,
    });
    this.context.dispatch("CaseModule/resetCurrentCaseState", null, {
      root: true,
    });

    Sentry.configureScope((scope) => scope.setUser(null));
  }

  @Mutation
  public setAdmin(adminStatus: boolean): void {
    this.isAdmin = adminStatus;
  }
  @Action
  public updateIsAdmin(isAdmin: boolean): void {
    this.context.commit("setAdmin", isAdmin);
  }

  @Mutation
  public setIdToken(idToken: string): void {
    this.idToken = idToken;
  }
  @Mutation
  public emptyIdToken(): void {
    this.idToken = "";
  }
  @Action
  public saveIdToken(idToken: string): void {
    this.context.commit("setIdToken", idToken);
    const { given_name, family_name, email, sub, exp, iat } =
      decodeJwtBody(idToken);
    this.context.commit("setFirstName", given_name || this.firstName);
    this.context.commit("setLastName", family_name || this.lastName);
    this.context.commit(
      "setName",
      !!given_name && !!family_name ? `${given_name} ${family_name}` : ""
    );
    this.context.commit("setEmail", email || this.email);

    Sentry.setUser({
      email: email || this.email,
      given_name: given_name,
      last_name: family_name,
      full_name: `${given_name} ${family_name}`,
    });

    this.context.commit("setId", sub || "");
    this.context.commit("setExpirationRange", {
      expiration: exp,
      initialAuthTime: iat,
    });
    this.context.commit("setTokenExpiration", exp);
  }
  @Action
  public removeIdToken(): void {
    this.context.commit("emptyIdToken");
  }

  @Mutation
  public setFirstName(firstName): void {
    this.firstName = firstName;
  }
  @Action
  public updateFirstName(firstName): void {
    this.context.commit("setFirstName", firstName);
  }
  @Mutation
  public setLastName(lastName): void {
    this.lastName = lastName;
  }
  @Action
  public updateLastName(lastName): void {
    this.context.commit("setLastName", lastName);
  }
  @Mutation
  public setEmail(email): void {
    this.email = email;
  }
  @Action
  public updateEmail(email): void {
    this.context.commit("setEmail", email);
  }
  @Mutation
  public setId(id): void {
    this.id = id;
  }
  @Action
  public updateId(id): void {
    this.context.commit("setId", id);
  }

  // Embedded
  @Mutation
  public setEmbedded(isEmbedded: boolean): void {
    this.embedded = isEmbedded;
  }
  @Action
  public embedUser(): void {
    this.context.commit("setEmbedded", true);
  }
  @Action
  public unEmbedUser(): void {
    this.context.commit("setEmbedded", false);
  }
  // Name
  @Mutation
  public setName(newName: string): void {
    this.name = newName;
  }
  @Action
  public updateName(newName: string): void {
    this.context.commit("setName", newName);
  }
  @Mutation
  public setOrganization(organization: string): void {
    this.organization = organization;
  }
  @Action
  public updateOrganization(organization: string): void {
    this.context.commit("setOrganization", organization);
  }

  @Mutation
  public setTokenExpiration(expiration: number): void {
    this.tokenExpiration = expiration * 1000; // Expiration is missing millisecond count
  }
  @Action
  public updateTokenExpiration(expiration: number): void {
    this.context.commit("setTokenExpiration", expiration);
  }

  @Mutation
  public setExpirationRange({ expiration, initialAuthTime }): void {
    this.expirationRange = (expiration - initialAuthTime) * 1000; // Expiration is missing millisecond count
  }
  @Action
  public updateExpirationRange(
    expiration: number,
    initialAuthTime: number
  ): void {
    this.context.commit("setExpirationRange", { expiration, initialAuthTime });
  }

  @Mutation
  public setRefreshInterval(refreshInterval): void {
    this.refreshInterval = refreshInterval;
  }
  @Action
  public updateRefreshInterval(refreshInterval: string): void {
    this.context.commit("setRefreshInterval", refreshInterval);
  }
  @Action
  public clearIntervalAndExpiration(): void {
    clearInterval(this.refreshInterval); // Clears timeout or interval
    this.context.commit("setRefreshInterval", 0);
    this.context.commit("setTokenExpiration", 0);
  }

  @Mutation
  public setActiveOrg(org: string): void {
    this.activeOrgID = org;
    Sentry.setTag("orgID", org);
  }
  @Action
  public updateActiveOrgId(org: string): void {
    this.context.commit("setActiveOrg", org);
  }

  @Mutation
  public setIsReactTachyonIsEnabled(isEnabled: boolean): void {
    this.isReactTachyonEnabled = isEnabled;
  }
  @Action
  public updateIsReactTachyonEnabled(isEnabled: boolean): void {
    this.context.commit("setIsReactTachyonIsEnabled", isEnabled);
  }

  @Mutation
  public setUserHasOrgs(hasOrgs: boolean): void {
    this.userHasOrgs = hasOrgs;
  }
  @Action
  public updateUserHasOrgs(hasOrgs: boolean): void {
    this.context.commit("setUserHasOrgs", hasOrgs);
  }

  @Mutation
  public setDefaultState(): void {
    this.loggedIn = false;
    this.isAdmin = false;
    this.idToken = "";
    this.expirationRange = 0;
    this.tokenExpiration = 0;
    this.embedded = false;
    this.organization = "";
    this.id = "";
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.name = "";
    this.activeOrgID = "";
    this.refreshInterval = 0;
    this.organizationIDs = [];
  }
  @Action
  public resetCurrentUserState(): void {
    this.context.commit("setDefaultState");
  }

  @Mutation
  public setOrganizationIds(organizationIds): void {
    this.organizationIDs = organizationIds;
  }
  @Action
  public updateOrganizationIds(organizationIds): void {
    this.context.commit("setOrganizationIds", organizationIds);
  }
}
