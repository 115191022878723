import { submittedComponentNames } from "@/app/views/submitted/interfaces/interfaces";

export interface DataGatheringSupportedResponse {
  supported: boolean;
}

export interface DataGatheringInitializationData extends Object {
  // if we ever have universal initialization data, it would go here.
}

export interface InitialData extends DataGatheringInitializationData {
  // V1
  DefaultCpt?: string;
  AdmissionTime?: string;
  Diagnosis?: string;
  // V2
  defaultCpt?: string;
  diagnosisCode?: string;
  fromDate?: string;
  toDate?: string;
  contactName?: string;
  contactPhone?: string;
  fileLimits?: FileLimits;
}
export interface FileLimits {
  individualFileSizeMB?: number;
}
export interface ExternalActionRequiredInitializationData
  extends DataGatheringInitializationData {
  BenefitManagerNote?: string;
  ExternalActionUrl?: string;
}

export interface DataGatheringRequiredResponse {
  dataGatheringScreen: DataGatheringId;
  dataGatheringScreenInitializationData?: DataGatheringInitializationData;
}

export interface ProvideRequireDataRequest {
  dataGatheringScreen: DataGatheringId;
  submittedData: Object;
}

export interface DataGatheringResultTracking {
  screen: submittedComponentNames;
  sdgDetermined: boolean;
  dgrResponse: DataGatheringRequiredResponse | null;
  submissionId: string;
}

export enum DataGatheringId {
  ClinicalSurvey = "ClinicalSurvey",
  ClinicalFileUpload = "ClinicalFileUpload",
  ClinicalUploadandFax = "ClinicalUploadandFax",
  AdhocClinical = "AdhocClinical",
  ProviderSelections = "ProviderSelections",
  EnhancedProviderSelection = "EnhancedProviderSelection",
  RetroDateRetrification = "RetroDateRertrification",
  DynamicForm = "DynamicForm",
  PayerSubmissionDataGathering = "PayerSubmissionDataGathering",
  NotificationOfAdmission = "NotificationOfAdmission",
  ContinuedStayReview = "ContinuedStayReview",
  ExternalActionRequired = "ExternalActionRequired",
  RetroRequestCorrection = "RetroRequestCorrection",
  FHIRClinicalAttestation = "FHIRClinicalAttestation",
  None = "None",
}

export interface DataGatheringIdSubmissionCorrectionMapping {
  dataGatheringId: DataGatheringId;
  submissionCorrectionMapping?: submittedComponentNames;
}

// TODO - Phase 1 is just NoA and CSR
// Example: {dataGatheringId: DataGatheringId.ContinuedStayReview, submissionCorrectionMapping: submittedComponentNames.ContinuedStayReview},
export const DataGatheringIdMap: DataGatheringIdSubmissionCorrectionMapping[] =
  [
    {
      dataGatheringId: DataGatheringId.ContinuedStayReview,
      submissionCorrectionMapping:
        submittedComponentNames.ContinueStayCaseDetails,
    },
    {
      dataGatheringId: DataGatheringId.NotificationOfAdmission,
      submissionCorrectionMapping: submittedComponentNames.NoaFileUpload,
    },
    {
      dataGatheringId: DataGatheringId.ProviderSelections,
      submissionCorrectionMapping:
        submittedComponentNames.SubmissionCorrections,
    },
    {
      dataGatheringId: DataGatheringId.ClinicalFileUpload,
      submissionCorrectionMapping: submittedComponentNames.ClinicalRequired,
    },
    {
      dataGatheringId: DataGatheringId.ExternalActionRequired,
      submissionCorrectionMapping:
        submittedComponentNames.ExternalActionRequired,
    },
    {
      dataGatheringId: DataGatheringId.EnhancedProviderSelection,
      submissionCorrectionMapping:
        submittedComponentNames.SubmissionCorrections,
    },
    {
      dataGatheringId: DataGatheringId.RetroRequestCorrection,
      submissionCorrectionMapping:
        submittedComponentNames.RetroRequestCorrection,
    },
    {
      dataGatheringId: DataGatheringId.FHIRClinicalAttestation,
      submissionCorrectionMapping: submittedComponentNames.Surveys,
    },
    { dataGatheringId: DataGatheringId.None },
  ];
