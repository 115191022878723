













import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class/lib/bindings";
import { ROUTER_NAMES, SESSION_LENGTH } from '../constants';
import { rsoUrl } from '../utils';

const GlobalModule = namespace("GlobalModule");
const UserModule = namespace("UserModule");
// When we start showing the modal and ticking
const timerStartsAt = 30000;

@Component<SessionTimer>({
  mounted() {
    this.interval = setInterval(() => {
      if (this.loggedIn) {
          this.sessionTimeRemaining = this.sessionTimeRemainingCalc();
          if (this.loggedIn && this.sessionTimeRemaining <= 0) {
              this.$router.push({ name: rsoUrl() ? ROUTER_NAMES.SessionExpired : ROUTER_NAMES.Login })
          }
      }
    }, 150);
  },
  beforeDestroy() {
    clearInterval(this.interval!);
  }
})
export default class SessionTimer extends Vue {
  public interval?: NodeJS.Timeout;
  public sessionTimeRemaining: number = SESSION_LENGTH;
  @GlobalModule.State
  public sessionEndTime!: string;
  @GlobalModule.Action
  public resetSessionEndTime!: () => void;
  @UserModule.State
  public loggedIn!: boolean;

  get timeRemaining() : number {
    const inSeconds = Math.floor(this.sessionTimeRemaining / 1000);
    return inSeconds >= 0 ? inSeconds : 0;
  }
  get percentComplete() : number {
      return (this.sessionTimeRemaining <= timerStartsAt ? (this.sessionTimeRemaining / timerStartsAt) : 0) * 100;
  }
  get showSessionExpiration() : boolean {
      return this.loggedIn && this.sessionTimeRemaining >= 0 && this.sessionTimeRemaining <= timerStartsAt;
  }
  sessionTimeRemainingCalc() : number {
      // If session time is set and a useable number, use it. Otherwiser sesion length is always max to avoid weird logic
    return !!this.sessionEndTime && !Number.isNaN(Number(this.sessionEndTime)) ? Number(this.sessionEndTime) - (new Date().getTime()) : SESSION_LENGTH;
  }
}
