import { Referral } from './referral';
import { Submission, SubmissionProcessingStatus } from './submission';
import { Castable } from '../Castable';
import { RetrievableFileData } from '@/app/views/submitted/caseDetails/interfaces/interfaces';
import { isAfter } from 'date-fns';
import { DocumentTypes, FileData, FileDataStatus } from '../file/FileData';
import { ProviderSelection } from './providerSelection';
import { ValidationError } from '../standardResponses';
import { AddressCorrection } from './addressCorrection';
import { PayerQuestion } from './payerQuestion';
import { CaseIngredient } from './caseIngredient';

export enum CaseStatusIds {
    APPROVED = 'APPROVED',
    APPROVED_WITH_MODIFICATIONS = 'APPROVED_WITH_MODIFICATIONS',
    CANCELED = 'CANCELED',
    CLINICAL_NEEDED = 'CLINICAL_NEEDED',
    CLINICAL_REQUESTED_PROVIDER_NOTIFIED = "CLINICAL_REQUESTED_PROVIDER_NOTIFIED",
    DENIED = 'DENIED',
    IN_REVIEW = 'IN_REVIEW',
    INCOMPLETE = 'INCOMPLETE',
    MEMBER_NOT_COVERED = 'MEMBER_NOT_COVERED',
    MEMBER_NOT_SUPPORTED = 'MEMBER_NOT_SUPPORTED',
    MULTIPLE_DECISIONS = 'MULTIPLE_DECISIONS',
    NOT_COVERED = 'NOT_COVERED',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    NOT_REQUIRED = 'NOT_REQUIRED',
    PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
    PARTIALLY_REVIEWED = 'PARTIALLY_REVIEWED',
    PEER_TO_PEER = 'PEER_TO_PEER',
    PROCESSING = 'PROCESSING',
    SERVICE_NOT_HANDLED = 'SERVICE_NOT_HANDLED',
    SURVEY_REQUIRED = 'SURVEY_REQUIRED',
    UNABLE_TO_PROCEED = 'UNABLE_TO_PROCEED',
    WITHDRAWN = 'WITHDRAWN',
}

export enum CaseReasonIds {
    APPEAL = 'APPEAL',
    APPROVED = 'APPROVED',
    AUTH_HANDLER = 'AUTH_HANDLER',
    AUTH_ON_FILE = 'AUTH_ON_FILE',
    BAD_APPLICATION_NAME = 'BAD_APPLICATION_NAME',
    BAD_REQUEST = 'BAD_REQUEST',
    CANCELED = 'CANCELED',
    CASE_INGESTION_FAILURE = 'CASE_INGESTION_FAILURE',
    CASE_NOT_FOUND = 'CASE_NOT_FOUND',
    CASE_SNAPSHOT_PENDING = 'CASE_SNAPSHOT_PENDING',
    CASE_SNAPSHOT_SUCCESS = 'CASE_SNAPSHOT_SUCCESS',
    CATASTROPHIC_ERRORS = 'CATASTROPHIC_ERRORS',
    CERTIFICATES_DO_NOT_MATCH = 'CERTIFICATES_DO_NOT_MATCH',
    CLINICAL_RECEIVED = 'CLINICAL_RECEIVED',
    CLINICAL_REQUIRED = 'CLINICAL_REQUIRED',
    CLINICAL_UPLOAD_SUCCESS = 'CLINICAL_UPLOAD_SUCCESS',
    CPT_EXPIRED = 'CPT_EXPIRED',
    CPT_NOT_FOUND = 'CPT_NOT_FOUND',
    CRED_AUTH_HANDLER = 'CRED_AUTH_HANDLER',
    CRED_HANDLER = 'CRED_HANDLER',
    DECLINED_RETRO_REQUEST = 'DECLINED_RETRO_REQUEST',
    DECLINED_RETRO_REQUEST_AIM = 'DECLINED_RETRO_REQUEST_AIM',
    DECLINED_SAMEDAY_REQUESTS = 'DECLINED_SAMEDAY_REQUESTS',
    DENIED = 'DENIED',
    DIFF_EXAM_MORE_APPROPRIATE = 'DIFF_EXAM_MORE_APPROPRIATE',
    DOS_PRECEDES_CARRIER_ACTIVATION = 'DOS_PRECEDES_CARRIER_ACTIVATION',
    DOS_PRECEDES_CPT4_ACTIVATION = 'DOS_PRECEDES_CPT4_ACTIVATION',
    DOS_PRECEDES_HEALTHPLAN_ACTIVATION = 'DOS_PRECEDES_HEALTHPLAN_ACTIVATION',
    DOS_TOO_FAR_OUT = 'DOS_TOO_FAR_OUT',
    DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',
    DUPLICATE_TENANT_ID = 'DUPLICATE_TENANT_ID',
    DUPLICATE_TRANSACTION = 'DUPLICATE_TRANSACTION',
    EC_UPLOAD_FAILURE = 'EC_UPLOAD_FAILURE',
    ERROR_GETTING_USER_PROFILE = 'ERROR_GETTING_USER_PROFILE',
    ERROR_INTERNAL_SERVER = 'ERROR_INTERNAL_SERVER',
    ERROR_TOKEN_HANDLERS = 'ERROR_TOKEN_HANDLERS',
    ERROR_VERIFYING_SIGNATURE = 'ERROR_VERIFYING_SIGNATURE',
    EXCEEDED_CLIN_SEND_ATT = 'EXCEEDED_CLIN_SEND_ATT',
    EXCEEDS_MAX_DIAGNOSES = 'EXCEEDS_MAX_DIAGNOSES',
    EXEMPT_FROM_AUTOMATED_PREAUTH = 'EXEMPT_FROM_AUTOMATED_PREAUTH',
    FAILURE_SUBMISSION = 'FAILURE_SUBMISSION',
    FILE_UPLOAD = 'FILE_UPLOAD',
    ICD_NOT_FOUND = 'ICD_NOT_FOUND',
    Incomplete = 'INCOMPLETE',
    IN_REVIEW = 'IN_REVIEW',
    INVALID_BPO_VALUE = 'INVALID_BPO_VALUE',
    INVALID_DATE_OF_SERVICE = 'INVALID_DATE_OF_SERVICE',
    INVALID_DIAGNOSIS = 'INVALID_DIAGNOSIS',
    INVALID_FACILITY_DETAIL = 'INVALID_FACILITY_DETAIL',
    INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
    INVALID_HEALTHPLAN = 'INVALID_HEALTHPLAN',
    INVALID_ICD_CODE = 'INVALID_ICD_CODE',
    INVALID_MEMBER_DOB = 'INVALID_MEMBER_DOB',
    INVALID_MEMBER_GENDER = 'INVALID_MEMBER_GENDER',
    INVALID_MEMBER_ID = 'INVALID_MEMBER_ID',
    INVALID_MEMBER_NETWORK = 'INVALID_MEMBER_NETWORK',
    INVALID_MEMBER_PHONE = 'INVALID_MEMBER_PHONE',
    INVALID_MODIFIER = 'INVALID_MODIFIER',
    INVALID_NPI = 'INVALID_NPI',
    INVALID_ORDER_TYPE_CODE = 'INVALID_ORDER_TYPE_CODE',
    INVALID_ORDERING_ADDRESS = 'INVALID_ORDERING_ADDRESS',
    INVALID_ORDERING_FAX = 'INVALID_ORDERING_FAX',
    INVALID_ORDERING_FIRST_NAME = 'INVALID_ORDERING_FIRST_NAME',
    INVALID_ORDERING_LAST_NAME = 'INVALID_ORDERING_LAST_NAME',
    INVALID_ORDERING_NPI = 'INVALID_ORDERING_NPI',
    INVALID_ORDERING_NPI_TIN = 'INVALID_ORDERING_NPI-TIN',
    INVALID_ORDERING_PHONE = 'INVALID_ORDERING_PHONE',
    INVALID_ORDERING_STATE = 'INVALID_ORDERING_STATE',
    INVALID_ORDERING_TIN = 'INVALID_ORDERING_TIN',
    INVALID_ORDERING_ZIP = 'INVALID_ORDERING_ZIP',
    INVALID_PHONE = 'INVALID_PHONE',
    INVALID_PROCEDURE = 'INVALID_PROCEDURE',
    INVALID_RECORD_LOCATOR = 'INVALID_RECORD_LOCATOR',
    INVALID_RENDERING_FAX = 'INVALID_RENDERING_FAX',
    INVALID_RENDERING_ID = 'INVALID_RENDERING_ID',
    INVALID_RENDERING_NPI = 'INVALID_RENDERING_NPI',
    INVALID_RENDERING_PHONE = 'INVALID_RENDERING_PHONE',
    INVALID_RENDERING_STATE = 'INVALID_RENDERING_STATE',
    INVALID_RENDERING_TIN = 'INVALID_RENDERING_TIN',
    INVALID_RENDERING_ZIP = 'INVALID_RENDERING_ZIP',
    INVALID_RETRO_CASE = 'INVALID_RETRO_CASE',
    INVALID_RETRO_DEFINITION = 'INVALID_RETRO_DEFINITION',
    INVALID_SERVICE_TYPE = 'INVALID_SERVICE_TYPE',
    INVALID_STATE = 'INVALID_STATE',
    INVALID_SUBMISSION = 'INVALID_SUBMISSION',
    INVALID_TENANT_ID = 'INVALID_TENANT_ID',
    INVALID_TOKEN_TYPE = 'INVALID_TOKEN_TYPE',
    INVALID_TRANS_ID = 'INVALID_TRANS_ID',
    INVALID_TRANS_TIMESTAMP = 'INVALID_TRANS_TIMESTAMP',
    INVALID_UNITS = 'INVALID_UNITS',
    INVALID_ZIP = 'INVALID_ZIP',
    MAY_BE_REQUIRED = 'MAY_BE_REQUIRED',
    MAY_NOT_BE_REQUIRED = 'MAY_NOT_BE_REQUIRED',
    MEMBER_FOUND = 'MEMBER_FOUND',
    MEMBER_NOT_COVERED = 'MEMBER_NOT_COVERED',
    MEMBER_NOT_FOUND = 'MEMBER_NOT_FOUND',
    MEMBER_TRANSPLANT_PATIENT = 'MEMBER_TRANSPLANT_PATIENT',
    MISSING_BPO_VALUE = 'MISSING_BPO_VALUE',
    MISSING_CASE_ID = 'MISSING_CASE_ID',
    MISSING_CPT = 'MISSING_CPT',
    MISSING_DATE_OF_SERVICE = 'MISSING_DATE_OF_SERVICE',
    MISSING_EXT_PARTNER_ID = 'MISSING_EXT_PARTNER_ID',
    MISSING_FAX = 'MISSING_FAX',
    MISSING_INFO_CLINICAL_UPLOAD = 'MISSING_INFO_CLINICAL_UPLOAD',
    MISSING_MEMBER_DOB = 'MISSING_MEMBER_DOB',
    MISSING_MEMBER_FIRST_NAME = 'MISSING_MEMBER_FIRST_NAME',
    MISSING_MEMBER_GENDER = 'MISSING_MEMBER_GENDER',
    MISSING_MEMBER_ID = 'MISSING_MEMBER_ID',
    MISSING_MEMBER_INFO = 'MISSING_MEMBER_INFO',
    MISSING_MEMBER_LAST_NAME = 'MISSING_MEMBER_LAST_NAME',
    MISSING_MEMBER_PHONE = 'MISSING_MEMBER_PHONE',
    MISSING_ORDER_TYPE_CODE = 'MISSING_ORDER_TYPE_CODE',
    MISSING_ORDERING_CITY = 'MISSING_ORDERING_CITY',
    MISSING_ORDERING_FAX = 'MISSING_ORDERING_FAX',
    MISSING_ORDERING_FIRST_NAME = 'MISSING_ORDERING_FIRST_NAME',
    MISSING_ORDERING_INFO = 'MISSING_ORDERING_INFO',
    MISSING_ORDERING_LAST_NAME = 'MISSING_ORDERING_LAST_NAME',
    MISSING_ORDERING_NPI = 'MISSING_ORDERING_NPI',
    MISSING_ORDERING_NPI_TIN = 'MISSING_ORDERING_NPI',
    MISSING_ORDERING_PROVIDER = 'MISSING_ORDERING_PROVIDER',
    MISSING_ORDERING_STATE = 'MISSING_ORDERING_STATE',
    MISSING_ORDERING_TIN = 'MISSING_ORDERING_TIN',
    MISSING_ORDERING_ZIP = 'MISSING_ORDERING_ZIP',
    MISSING_PAN_PAYLOAD = 'MISSING_PAN_PAYLOAD',
    MISSING_PRIMARY_ICD_CODE = 'MISSING_PRIMARY_ICD_CODE',
    MISSING_PRIVATE_KEY = 'MISSING_PRIVATE_KEY',
    MISSING_PROCEDURES = 'MISSING_PROCEDURES',
    MISSING_RENDERING_CITY = 'MISSING_RENDERING_CITY',
    MISSING_RENDERING_NAME = 'MISSING_RENDERING_NAME',
    MISSING_RENDERING_NPI = 'MISSING_RENDERING_NPI',
    MISSING_RENDERING_PROVIDER = 'MISSING_RENDERING_PROVIDER',
    MISSING_RENDERING_STATE = 'MISSING_RENDERING_STATE',
    MISSING_RENDERING_TIN = 'MISSING_RENDERING_TIN',
    MISSING_RENDERING_ZIP = 'MISSING_RENDERING_ZIP',
    MISSING_REQUIRED_DATA = 'MISSING_REQUIRED_DATA',
    MISSING_TRANS_ID = 'MISSING_TRANS_ID',
    MISSING_TRANS_TIMESTAMP = 'MISSING_TRANS_TIMESTAMP',
    MISSING_TRANSACTION_ID = 'MISSING_TRANSACTION_ID',
    MODIFIED_APPROVAL = 'MODIFIED_APPROVAL',
    MODIFIER_REQUIRED = 'MODIFIER_REQUIRED',
    MULTIPLE_HEALTH_PLANS = 'MULTIPLE_HEALTH_PLANS',
    NO_ACTIVE_PLAN = 'NO_ACTIVE_PLAN',
    NO_CLAIMS_FOUND = 'NO_CLAIMS_FOUND',
    NO_WITHDRAWAL_APPROVED = 'NO_WITHDRAWAL_APPROVED',
    NO_WITHDRAWAL_CANCELED = 'NO_WITHDRAWAL_CANCELED',
    NO_WITHDRAWAL_COMPLETED = 'NO_WITHDRAWAL_COMPLETED',
    NO_WITHDRAWAL_DENIED = 'NO_WITHDRAWAL_DENIED',
    NO_WITHDRAWAL_MODIFIED_APPROVED = 'NO_WITHDRAWAL_MODIFIED_APPROVED',
    NOT_AUTO_APPROVED = 'NOT_AUTO_APPROVED',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    NOT_HANDLED = 'NOT_HANDLED',
    NOT_REQUIRED = 'NOT_REQUIRED',
    NOT_REVIEWED_CPT = 'NOT_REVIEWED_CPT',
    NOT_SUBMITTED = 'NOT_SUBMITTED',
    NOT_SUPPORTED_BY_FEED = 'NOT_SUPPORTED_BY_FEED',
    NOT_SUPPORTED_BY_PAN = 'NOT_SUPPORTED_BY_PAN',
    ORDERING_PROVIDER_CANT_ORDER = 'ORDERING_PROVIDER_CANT_ORDER',
    ORDERING_PROVIDER_NOT_FOUND = 'ORDERING_PROVIDER_NOT_FOUND',
    ORDERING_PROVIDER_NOT_FOUND_ECv2 = 'ORDERING_PROVIDER_NOT_FOUND_ECv2',
    ORDERING_PROVIDER_REJECTED = 'ORDERING_PROVIDER_REJECTED',
    PEER_TO_PEER = 'PEER_TO_PEER',
    POST_SERVICE_REQUEST = 'POST_SERVICE_REQUEST',
    PRECISE_PAYER_EXCEPTION_CASE = 'PRECISE_PAYER_EXCEPTION_CASE',
    PROCEDURE_NOT_ELIGIBLE = 'PROCEDURE_NOT_ELIGIBLE',
    PROCEDURES_EXCEED_TWO = 'PROCEDURES_EXCEED_TWO',
    PROCEDURES_FAILED_VALIDATION = 'PROCEDURES_FAILED_VALIDATION',
    PROCESSING = 'PROCESSING',
    PROSPECTIVE_NOT_ALLOWED = 'PROSPECTIVE_NOT_ALLOWED',
    PROVIDER_INFO_REQD = 'PROVIDER_INFO_REQD',
    RECONSIDERATION = 'RECONSIDERATION',
    RENDERING_PROVIDER_CANT_REQUEST = 'RENDERING_PROVIDER_CANT_REQUEST',
    RENDERING_PROVIDER_INACTIVE = 'RENDERING_PROVIDER_INACTIVE',
    RENDERING_PROVIDER_INFO_REQD = 'RENDERING_PROVIDER_INFO_REQD',
    RENDERING_PROVIDER_NOT_APPROVED = 'RENDERING_PROVIDER_NOT_APPROVED',
    RENDERING_PROVIDER_NOT_FOUND = 'RENDERING_PROVIDER_NOT_FOUND',
    RENDERING_SITE_CHANGED = 'RENDERING_SITE_CHANGED',
    RENDERING_SITE_NOT_ELIGIBLE = 'RENDERING_SITE_NOT_ELIGIBLE',
    RENDERING_SITE_NOT_FOUND = 'RENDERING_SITE_NOT_FOUND',
    RENDERING_SITE_OUT_OF_NETWORK = 'RENDERING_SITE_OUT_OF_NETWORK',
    REQUEST_FORBIDDEN = 'REQUEST_FORBIDDEN',
    REQUEST_SIGNED_INCORRECTLY = 'REQUEST_SIGNED_INCORRECTLY',
    REQUEST_SPANS_DISCIPLINES = 'REQUEST_SPANS_DISCIPLINES',
    REQUEST_TIMED_OUT = 'REQUEST_TIMED_OUT',
    REQUEST_UNAUTHORIZED = 'REQUEST_UNAUTHORIZED',
    REQUESTS_MUST_BE_EXPEDITED = 'REQUESTS_MUST_BE_EXPEDITED',
    REQUESTS_NOT_ACCEPTED_FOR_MEMBER = 'REQUESTS_NOT_ACCEPTED_FOR_MEMBER',
    REQUESTS_NOT_PROCESSED_FOR_MEMBER = 'REQUESTS_NOT_PROCESSED_FOR_MEMBER',
    RESTRICTED_BY_STATE_MEMBER = 'RESTRICTED_BY_STATE_MEMBER',
    RESTRICTED_BY_STATE_RENDERER = 'RESTRICTED_BY_STATE_RENDERER',
    RETRO_CASE_FUTURE_DATE = 'RETRO_CASE_FUTURE_DATE',
    SECONDARY_CPT = 'SECONDARY_CPT',
    SUCCESSFUL_CASE_CREATION = 'SUCCESSFUL_CASE_CREATION',
    SUCCESSFUL_DOCUMENT_SUBMISSION = 'SUCCESSFUL_DOCUMENT_SUBMISSION',
    SUCCESSFUL_SUBMISSION = 'SUCCESSFUL_SUBMISSION',
    SUCCESSFUL_WITHDRAWAL = 'SUCCESSFUL_WITHDRAWAL',
    SURVEY_REQUIRED = 'SURVEY_REQUIRED',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR',
    TECHNICAL_EXCEPTION = 'TECHNICAL_EXCEPTION',
    TRANSACTION_ID_NOT_FOUND = 'TRANSACTION_ID_NOT_FOUND',
    UNABLE_TO_APPROVE = 'UNABLE_TO_APPROVE',
    UNDEFINED = 'UNDEFINED',
    UNMARKED_RETRO_CASE = 'UNMARKED_RETRO_CASE',
    UNSUPPORTED_HANDLING_TYPE = 'UNSUPPORTED_HANDLING_TYPE',
    UNSUPPORTED_ORDER_TYPE_CODE = 'UNSUPPORTED_ORDER_TYPE_CODE',
    UNSUPPORTED_PROFILE_HANDLER = 'UNSUPPORTED_PROFILE_HANDLER',
    UNSUPPORTED_SAML_TOKEN = 'UNSUPPORTED_SAML_TOKEN',
    UNSUPPORTED_TOKEN = 'UNSUPPORTED_TOKEN',
    UNSUPPORTED_TOKEN_SERIALIZATION = 'UNSUPPORTED_TOKEN_SERIALIZATION',
    USER_CANCELED = 'USER_CANCELED',
    USER_CANCELED_DUPLICATE = 'USER_CANCELED_DUPLICATE',
    USER_CANCELED_FACILITY_SEARCH = 'USER_CANCELED_FACILITY_SEARCH',
    USER_CANCELED_MULTIPLE_ORDERERS = 'USER_CANCELED_MULTIPLE_ORDERERS',
    USER_CANCELED_SAMEDAY_RETRO = 'USER_CANCELED_SAMEDAY_RETRO',
    USER_RESET_STATUS = 'USER_RESET_STATUS',
    WEB_INTAKE_CASES = 'WEB_INTAKE_CASES',
    WITHDRAW_REQUEST_REJECTED = 'WITHDRAW_REQUEST_REJECTED',
    WITHDRAWN = 'WITHDRAWN',
    WRITTEN_WITH_CLINICAL = 'WRITTEN_WITH_CLINICAL',
}

const caseInsensitiveContains = (list: Array<string>, stringToCheck: string) : boolean => {
    return list.map(item => item.toUpperCase()).some(itemUpcased => itemUpcased === stringToCheck.toUpperCase())
}

export const isDateOfServiceReason = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
    // If the reason matches one of these, its a date of service reason
    return caseInsensitiveContains([
        CaseReasonIds.DECLINED_RETRO_REQUEST, 
        CaseReasonIds.DECLINED_RETRO_REQUEST_AIM, 
        CaseReasonIds.PROSPECTIVE_NOT_ALLOWED,
        CaseReasonIds.DECLINED_SAMEDAY_REQUESTS
    ], reasonId);
}

export const isFileUploadNeeded = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
    return caseInsensitiveContains([
        CaseReasonIds.CLINICAL_REQUIRED
    ], reasonId);
}

export const fileErroredNotProcessing = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
    return caseInsensitiveContains([
        CaseReasonIds.EXCEEDED_CLIN_SEND_ATT,
        CaseReasonIds.EC_UPLOAD_FAILURE
    ], reasonId);
}

export const isReprocessingReason = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
    // If the reason matches one of these, its a date of service reason
    return caseInsensitiveContains([
        CaseReasonIds.Incomplete,
    ], reasonId);
}


export const isAddressChangeReason = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
        // If the reason matches one of these, its an address change reason
    return caseInsensitiveContains([
        CaseReasonIds.ORDERING_PROVIDER_NOT_FOUND,
        CaseReasonIds.PROVIDER_INFO_REQD
    ], reasonId)
}

export const isPayerQuestionReason = (caseResponse: Case): boolean => {
    if (!caseResponse.reasonId || !caseResponse.payerQuestions) return false;
    //if the reason matches missing required data and there are payer questions on the case, its a payer question reason
    return caseInsensitiveContains([
        CaseReasonIds.MISSING_REQUIRED_DATA
    ], caseResponse.reasonId)
}

export const isModifierChangeReason = (reasonId: CaseReasonIds): boolean => {
    if (!reasonId) return false;
        // If the reason matches one of these, its a modifier change reason
    return caseInsensitiveContains([
        CaseReasonIds.MODIFIER_REQUIRED
    ], reasonId)
}

export class Case extends Castable {
    static Cast(obj: Object): Case {
        const createdCase: Case = super.Cast(Case, obj);
        // Cast properties that are Castable Classes
        if (!!obj) createdCase.referral = Referral.Cast(obj['referral']);
        if (!!obj) createdCase.submissions = (obj['submissions'] || []).map(submission => Submission.Cast(submission));
        if (!!obj) createdCase.ingredients = (obj['ingredients'] || []).map(ingredient => CaseIngredient.Cast(ingredient));
        return createdCase;
    }

    hashKey!: string;
    caseId!: string;
    organizationId!: string;
    partnerCaseId!: string;
    ingredients: Array<CaseIngredient> = [];
    statusId!: CaseStatusIds;
    reasonId!: CaseReasonIds;
    reasonDescriptionId?: string;
    reasonDescription?: string;
    referral: Referral = new Referral();
    submissions: Array<Submission> = [];
    /*@deprecated 
    * Clinical Documents are Depricated please use files array instead. 
    */
    clinicalDocuments: Array<RetrievableFileData> = [];
    /*@deprecated 
    * Clinical Notes are Depricated please use files array instead. 
    */
    clinicalNotes: Array<RetrievableFileData> = [];
    /*@deprecated 
    * Payer Artifacts are Depricated please use files array instead. 
    */
    payerArtifacts: Array<RetrievableFileData> = [];
    
    files : Array<FileData> = [];
    addressCorrections: Array<AddressCorrection> = [];
    payerQuestions?: Array<PayerQuestion> = [];
    validations: Array<ValidationError> = [];
    benefitManagerNote?: string = '';
    benefitManager: string = '';
    version!: number;

    get downloadableDocuments() : Array<RetrievableFileData> {
        // Gather all files
        let fileData : Array<RetrievableFileData>;
        if(this.files.length > 0){
            const validDocumentTypes : Array<string> = [DocumentTypes.ClinicalDocument, DocumentTypes.ClinicalNote, DocumentTypes.PayerArtifact]
            const unConvertedFileData: Array<FileData> = [...this.files]
                .filter(fd => !!fd && !!fd.status 
                    && (fd.status.toUpperCase() === FileDataStatus.AVAILABLE.toUpperCase() || fd.status.toUpperCase() === FileDataStatus.SUBMITTED.toUpperCase())
                    && validDocumentTypes.includes(fd.documentType));
            fileData = unConvertedFileData.map(function (fd): RetrievableFileData {
                return {
                    fileData: fd,
                    submissionIds: []
                }
            });
            
        } else {
            fileData = [...this.clinicalDocuments, ...this.clinicalNotes, ...this.payerArtifacts]
                .filter(rfd => !!rfd.fileData && !!rfd.fileData.status && (rfd.fileData.status.toUpperCase() === FileDataStatus.AVAILABLE.toUpperCase() || rfd.fileData.status.toUpperCase() === FileDataStatus.SUBMITTED.toUpperCase()));
        }
        // Return sorted list
        return fileData.sort((a, b) =>
          isAfter(new Date(a.fileData.dateReceived), new Date(b.fileData.dateReceived)) ? 1 : -1
        );
    }

    get activeSubmissions() : Array<Submission> {
        return this.submissions.filter(submission => (submission.processingStatus !== SubmissionProcessingStatus.Ignored) && (submission.processingStatus !== SubmissionProcessingStatus.Deleted))
    }

    get isProcessingDocuments() : boolean {
        const validFileStatuses: Array<string> = [FileDataStatus.AVAILABLE.toUpperCase(), FileDataStatus.SCANNING.toUpperCase(), FileDataStatus.PENDING.toUpperCase(), FileDataStatus.SUBMITTED.toUpperCase(), FileDataStatus.UPLOADED.toUpperCase()];
        const validDocumentTypes : Array<string> = [DocumentTypes.ClinicalDocument, DocumentTypes.ClinicalNote]
        if(this.files.length > 0){
            const validClinicalfiles  : Array<FileData> = [...this.files]
            .filter(vcf => !!vcf.status && validFileStatuses.includes(vcf.status.toUpperCase()) && validDocumentTypes.includes(vcf.documentType) );
            return (validClinicalfiles.length > 0);
        } else {
            const clincialFiles: Array<RetrievableFileData> = [...this.clinicalDocuments, ...this.clinicalNotes]
                .filter(rfd => !! rfd.fileData && !!rfd.fileData.status && validFileStatuses.includes(rfd.fileData.status.toUpperCase()));
            return (clincialFiles.length > 0);
        }

    }
}