import { SESSION_LENGTH } from '@/app/shared/constants';
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { PayerEntry } from '@shared/interfaces/draft/payerEntry';

@Module({ namespaced: true })
export default class GlobalModule extends VuexModule {
  public waiting: boolean = false;
  public error: Error | null = null; // Here I am explicitly indicating this value can be a null or an object of type Error
  public flashError: string = '';
  public savedRoute:string = "";
  public sessionEndTime:string = "";
  public payerEntryList:Array<PayerEntry> = [];
  public submittingSurvey: boolean = false;

  @Mutation
  public setPayerEntryList(value: Array<PayerEntry>): void {
    this.payerEntryList = value;
  }
  @Action
  public emitSavedPayerEntryList(value: Array<PayerEntry>): void {
    this.context.commit("setPayerEntryList",value);
  }
  @Mutation
  public setSavedRoute(value: string): void {
    this.savedRoute = value;
  }
  @Action
  public emitSavedRoute(value: string): void {
    this.context.commit("setSavedRoute",value);
  }
  get SavedRoute() {
    return this.savedRoute;
  }
  // Waiting
  @Mutation
  public setWaiting(value: boolean): void {
    this.waiting = value;
  }
  @Action
  public enterWaitingState(): void {
    this.context.commit("setWaiting", true);
  }
  @Action
  public stopWaiting(): void {
    this.context.commit("setWaiting", false);
  }

  @Mutation
  public setFlashError(value: string): void {
      this.flashError = value || 'We are currently experiencing problems. Please try again soon';
  }
  @Mutation
  public clearFlashError(): void {
      this.flashError = '';
  }
  @Action
  public emitFlashError(value: string): void {
    this.context.commit("setFlashError", value);
  }
  //ErrorState
  @Mutation
  public setError(value: Error | null): void {
      this.error = value;
  }
  @Action
  public emitError(value: Error): void {
      this.context.commit("setError", value);
      this.context.commit("setFlashError", value);
      setTimeout(() => {
        this.context.commit("clearFlashError");
      }, 5000);
  }
  @Action
  public clearError(): void {
      this.context.commit("setError", null);
      this.context.commit("clearFlashError");
  }

  @Mutation
  public setSessionEndTime(sessionEndTime: string) {
    this.sessionEndTime = sessionEndTime;
  }
  @Action
  public resetSessionEndTime(): void {
    const freshSessionEndTime = (new Date().getTime()) + SESSION_LENGTH;
    this.context.commit("setSessionEndTime", freshSessionEndTime);
  }

  @Mutation
  public setSubmittingSurvey(submittingSurvey: boolean) {
    this.submittingSurvey = submittingSurvey;
  }
  @Action
  public surveyIsSubmitting(): void {
    this.context.commit('setSubmittingSurvey', true);
  }
  @Action
  public surveySubmitComplete(): void {
    this.context.commit('setSubmittingSurvey', false);
  }

  @Mutation
  public setDefaultState() : void {
    this.waiting = false;
    this.error = null;
    this.flashError = '';
    this.savedRoute = '';
    this.sessionEndTime = '';
    this.payerEntryList = [];
    this.submittingSurvey = false;
  }
  @Action
  public resetCurrentGlobalState(): void {
    this.context.commit("setDefaultState");
  }
}
