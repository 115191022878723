import { Service } from './service';
import { SubmissionStatus } from './submissionStatus';
import { FileData } from '../file/FileData';
import { Provider } from './provider';
import { Facility } from './facility';
import { Castable } from '../Castable';
import { RetrievableFileData } from '@/app/views/submitted/caseDetails/interfaces/interfaces';

export enum SubmissionProcessingStatus {
    WaitingOnSubmitResponse = "WAITINGONSUBMITRESPONSE",
    Active = "ACTIVE",
    Ignored = "IGNORED",
    Deleted = "DELETED"
}
export class Submission extends Castable {
    
    static Cast(obj: Object) : Submission {
        const submission = super.Cast(Submission, obj);
        if (!!obj) {
            submission.submissionStatus = SubmissionStatus.Cast(obj['submissionStatus']);
        }
        return submission
    }

    submissionId?: string;
    routeId?: string;
    services: Array<string> = [];
    submissionTimeUTC?: string; //ISO String plz
    payerSubmissionIdExternal?: string;
    payerSubmissionIdInternal?: string;
    submissionStatus?: SubmissionStatus;
    payerUpdatedRenderingFacility?: Facility;
    payerUpdatedOrderingProvider?: Provider;
    processingStatus?: SubmissionProcessingStatus;
}