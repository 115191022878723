import { Castable } from "../Castable";
import { FileData } from "../file/FileData";
import { Ingredient } from "./ingredient";

export class CaseIngredient extends Castable {
  static Cast(obj: Object): CaseIngredient {
    const createdCaseIngredient = super.Cast(CaseIngredient, obj);
      return createdCaseIngredient;
  }
  completionPercentage?: number;
  files?: Array<FileData> = [];
  ingredient?: Ingredient;
}
