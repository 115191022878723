import { DecisionDataCode } from './decisionDataCode';
import { Castable } from '../Castable';
import { CaseStatusIds, CaseReasonIds } from './case';


export class DecisionData extends Castable {
    static Cast(obj: Object): DecisionData {
        const decisionData = super.Cast(DecisionData, obj);
        // Cast properties that are Castable Classes
        if(!!obj) decisionData.codes = (obj['codes']||[]).map(code => DecisionDataCode.Cast(code));
        return decisionData;
    }

    statusId!: CaseStatusIds;
    reasonId!: CaseReasonIds;
    benefitManagerNote?: string;
    codes: Array<DecisionDataCode> = [];
} 