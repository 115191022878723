import Api from "@/app/core/api";
import store from "@/app/core/store/store";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

//This method returns the user object from the IdentityAndAccessService (new authorization system).
//Once the user object is retrieved, the user state fields are set to allow the application to
//function as it did with the legacy authorization system.
export function initializeUser(api: Api): Observable<RhymeUser> {
  const url = `${process.env.VUE_APP_RHYME_API}/authorization/user`;
  return api.get<RhymeUser>(url).pipe(
    catchError((err) => {
      return of(err.response);
    }),
    map((response) => {
      const user = response.data as RhymeUser;
      const isAdmin = user.userRoles.some((role) =>
        role.name.includes("Admin")
      );
      user.userRoles = user.userRoles.filter(
        (role) =>
          role.scope["organizationId"] && role.capabilities.includes("GetCase")
      );
      const organizationIds = user.userRoles
        .flatMap((role) => Object.values(role.scope))
        .filter(onlyUnique);
      store.dispatch("UserModule/updateOrganizationIds", organizationIds);
      store.dispatch("UserModule/updateIsAdmin", isAdmin);
      store.dispatch("UserModule/updateActiveOrgId", organizationIds[0]);
      return user;
    })
  );
}

export type UserRole = {
  name: string;
  scope: Record<string, string>;
  capabilities: string[];
};

export type RhymeUser = {
  username: string;
  userRoles: UserRole[];
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
