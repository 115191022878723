



















import { Component, Vue, Watch } from "vue-property-decorator";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Subject } from "rxjs";
import { namespace } from "vuex-class";
import * as Bowser from "bowser";
import Header from "@/app/views/header/Header.vue";
import SessionTimer from "@shared/components/SessionTimer.vue";
import TermsOfService from "@shared/components/TermsOfService.vue";
import { authStateHandler } from "./app/shared/utils/auth";
import store from "@core/store/store";
import { rsoUrl } from "@shared/utils";
import { initializeUser } from "@/app/shared/utils/initializeUser";

const UserModule = namespace("UserModule");
const GlobalModule = namespace("GlobalModule");
const CaseModule = namespace("CaseModule");
@Component<App>({
  components: {
    Header,
    SessionTimer,
    TermsOfService,
  },
  mounted() {
    onAuthUIStateChange(
      authStateHandler(
        () => this.$router,
        () => this.$route,
        this.logout,
        this.removeIdToken,
        store
      )
    );
  },
  beforeDestroy() {
    if (this.refreshInterval != null && this.refreshInterval > 0)
      window.clearInterval(this.refreshInterval);
  },
})
export default class App extends Vue {
  public amplifySubject$!: Subject<boolean>;
  public signedInSubject$!: Subject<boolean>;
  public isUserInitialized = false;

  @UserModule.Action
  public removeIdToken!: () => void;

  @UserModule.State
  public loggedIn!: boolean;

  @UserModule.State
  public refreshInterval!: number;

  @GlobalModule.State
  public flashError?: string;

  @CaseModule.State
  public hideCaseHeader?: boolean;

  @UserModule.Action
  public logout!: () => void;

  get uiVersion() {
    return process.env.VUE_APP_UI_VERSION;
  }

  get buildId() {
    return process.env.VUE_APP_BUILD_ID;
  }

  get isRso() {
    return rsoUrl();
  }

  get showHeader() {
    return this.loggedIn && !this.hideCaseHeader && this.isUserInitialized;
  }

  get ie11User() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowser().name === "Internet Explorer";
  }

  @Watch("loggedIn")
  onLoggedIn() {
    if (this.loggedIn) {
      initializeUser(this.$api).subscribe(
        () => (this.isUserInitialized = true)
      );
    }
  }
}
