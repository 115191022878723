import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import * as Sentry from "@sentry/vue";

import { Referral, TransactionType } from '@/app/shared/interfaces/case/referral';
import { Submission } from '@/app/shared/interfaces/case/submission';
import { Case } from '@/app/shared/interfaces/case/case';
import { Draft } from '@/app/shared/interfaces/draft/draft';
import { ValidationError } from '@shared/interfaces/standardResponses';
import { FileDataStatus } from '@/app/shared/interfaces/file/FileData';
import { NoaRecord } from '@/app/shared/interfaces/case/noaRecord';
import { DataGatheringId, DataGatheringInitializationData, InitialData } from '@/app/core/interfaces/scalableDataGathering';
import { NoaRequest } from '@/app/shared/interfaces/case/noaRequest';
import { isEmpty } from 'lodash';
import { submittedComponentNames } from '@/app/views/submitted/interfaces/interfaces';

@Module({ namespaced: true })
export default class CaseModule extends VuexModule {

  public caseId: string = '';
  public case: Case = Case.Cast({});
  public referral: Referral = Referral.Cast({});
  public inProgressReferral: Referral = Referral.Cast({});
  public draft: Draft = Draft.Cast({});
  public submissions: Array<Submission> = [];
  public organizationId: string = '';
  public validations: Array<ValidationError> = [];
  public startedOnPrecheck: boolean = false;
  public successfullySubmitted : boolean = false;
  public shownSubmittedComponent : submittedComponentNames | null = null;
  public clinicalFileStatus: FileDataStatus = FileDataStatus.PENDING;
  /// These two items should probably be their own state module
  public noaRecord: NoaRecord = NoaRecord.Cast({});
  public inProgressNoa: NoaRequest = NoaRequest.Cast({});
  public resetNoa: boolean = false;
  ///////////////////////////////////////////////////////////////
  public dataGatheringId: DataGatheringId | null = null;
  public dataGatheringScreenData: DataGatheringInitializationData | null = null;
  public dataGatheringSubmissionId: string = '';

  get getCaseId() {
    return this.caseId;
  }
  get getCase(): Case {
    return this.case;
  }
  get getCaseVersion() {
    return this.case.version;
  }
  get referralData() {
    return this.referral;
  }
  get inProgressReferralData() {
    return this.inProgressReferral;
  }
  get getInProgressNoa() {
    return this.inProgressNoa;
  }

  get firstNoaSubmissionResult() {
    if (this.case.activeSubmissions.length === 0 || isEmpty(this.noaRecord.noaSubmissionResults)) {
      return null;
    }
    return this.noaRecord.noaSubmissionResults[this.case.activeSubmissions[0].submissionId!] || null;
  }

  get draftData() {
    return this.draft;
  }

  get latestValidations() {
    return this.validations;
  }

  get getPartnerCaseId() {
    return this.draft.partnerCaseId ?? this.case.partnerCaseId;
  }

  get getOrganizationId() {
    return this.organizationId;
  }

  get submissionData() {
    return this.submissions
  }

  get caseStartedOnPrecheck() {
    return this.startedOnPrecheck;
  }

  get benefitManager() {
    return this.draft.benefitManagerId;
  }

  get panPayerId() {
    return this.draft.referral.panPayerId;
  }
  
  get draftSuccessfullySubmitted(){
    return this.successfullySubmitted;
  }

  get getClinicalFileStatus() {
    return this.clinicalFileStatus;
  }

  get isNoaDraftReferral() {
    return this.draft.transactionType === TransactionType.INPATIENT_NOTICE_OF_ADMISSION || this.referral.transactionType === TransactionType.INPATIENT_NOTICE_OF_ADMISSION
  }

  get draftHasACaseId() : boolean {
    return !!this.draft.panCaseId
  }

  @Action 
  setOrganizationId(organizationId) {
    this.context.commit('setOrganizationIdMutation', organizationId)
  }
  @Mutation
  setOrganizationIdMutation(organizationId) {
    this.organizationId = organizationId;
  }
  @Action
  public setDraftAction(draft: Draft){
    this.context.commit('setDraftMutation', draft);
  }
  @Mutation
  public setDraftMutation(draft: Draft) {
    this.draft = Draft.Cast(draft);
    this.referral = Referral.Cast(draft.referral);
    this.organizationId = this.draft.organizationId;
    this.validations = [...this.draft.errors];

    Sentry.setTag("partnerCaseID", this.draft.partnerCaseId);
  }
  @Action
  public setInProgressReferral(inProgressReferral: Referral) {
    this.context.commit('setInProgressReferralMutation', inProgressReferral)
  }
  @Mutation
  public setInProgressReferralMutation(inProgressReferral: Referral) {
    this.inProgressReferral = inProgressReferral;
  }
  @Action
  public setCaseNoaAction(theNoa: NoaRecord) {
    this.context.commit('setCaseNoaMutation', theNoa);
  }
  @Mutation
  public setCaseNoaMutation(payload: NoaRecord){
    this.noaRecord = payload;
  }
  @Action
  public setCaseStateAction(theCase: Case) {
    this.context.commit('setCaseStateMutation', theCase);
  }
  @Mutation
  public setCaseStateMutation(payload: Case) {
    this.caseId = payload.caseId;
    this.case = payload;
    this.organizationId = payload.organizationId;
    this.referral = Referral.Cast(payload.referral);
    this.submissions = payload.submissions.map(submission => Submission.Cast(submission));

    Sentry.setTag("partnerCaseID", this.case.partnerCaseId);
  }

  @Action
  public setStartOnPrecheck(val: boolean) {
    this.context.commit('setStartOnPrecheckMutation', val);
  }
  @Mutation
  public setStartOnPrecheckMutation(val: boolean) {
    this.startedOnPrecheck = val;
  }


  @Action
  public setSuccessfullySubmitted(val: boolean) {
    this.context.commit('setSuccessfullySubmittedMutation', val);
  }
  @Mutation
  public setSuccessfullySubmittedMutation(val: boolean) {
    this.successfullySubmitted = val;
  }

  @Action
  public setShownSubmittedComponent(val: submittedComponentNames | null) {
    this.context.commit('setShownSubmittedComponentMutation', val);
  }
  @Mutation
  public setShownSubmittedComponentMutation(val: submittedComponentNames | null) {
    this.shownSubmittedComponent = val;
  }

  @Action
  public setValidations(theValidations: Array<ValidationError>) {
    this.context.commit('setValidationsMutation', theValidations);
  }
  @Mutation
  public setValidationsMutation(payload: Array<ValidationError>) {
    this.validations = [...payload];
  }
  
  @Action 
  public clearValidations(){
    this.context.commit('clearValidationsMutation');   
  }
  @Mutation 
  public clearValidationsMutation(){
    this.validations = [];
  }

  @Action setClinicalFileStatus(val: FileDataStatus) {
    this.context.commit('setClinicalFileStatusMutation', val)
  }
  @Mutation setClinicalFileStatusMutation(val: FileDataStatus) {
    this.clinicalFileStatus = val;
  }

  @Action
  public setInProgressNoa(inProgressNoa: NoaRequest) {
    this.context.commit('setInProgressNoaMutation', inProgressNoa)
  }
  @Mutation
  public setInProgressNoaMutation(inProgressNoa: NoaRequest) {
    this.inProgressNoa = inProgressNoa;
  }

  @Action
  public resetNoaAction(reset: boolean) {
    this.context.commit('resetNoaMutation', reset)
  }
  @Mutation
  public resetNoaMutation(reset: boolean) {
    this.resetNoa = reset;
  }

  @Action
  public setDataGatheringId(dataGatheringId: DataGatheringId | null) {
    this.context.commit('setDataGatheringIdMutation', dataGatheringId);
  }
  @Mutation
  public setDataGatheringIdMutation(dataGatheringId: DataGatheringId | null) {
    this.dataGatheringId = dataGatheringId;
  }

  @Action
  public setDataGatheringScreenData(dataGatheringScreenData: Object | null) {
    this.context.commit('setDataGatheringScreenDataMutation', dataGatheringScreenData);
  }
  @Mutation
  public setDataGatheringScreenDataMutation(dataGatheringScreenData: Object | null) {
    this.dataGatheringScreenData = dataGatheringScreenData;
  }

  @Action
  public setDataGatheringSubmissionId(dataGatheringSubmissionId: string) {
    this.context.commit('setDataGatheringSubmissionIdMutation', dataGatheringSubmissionId);
  }
  @Mutation
  public setDataGatheringSubmissionIdMutation(dataGatheringSubmissionId: string) {
    this.dataGatheringSubmissionId = dataGatheringSubmissionId;
  }
  
  @Mutation
  public setDefaultState() : void {
    this.caseId = '';
    this.case= Case.Cast({});
    this.referral = Referral.Cast({});
    this.inProgressReferral = Referral.Cast({});
    this.draft = Draft.Cast({});
    this.submissions = [];
    this.organizationId = '';
    this.validations = [];
    this.startedOnPrecheck = false;
    this.successfullySubmitted = false;
    this.shownSubmittedComponent = null;
    this.clinicalFileStatus = FileDataStatus.PENDING;
    this.noaRecord  = NoaRecord.Cast({});
    this.inProgressNoa = NoaRequest.Cast({});
    this.resetNoa = false;
    this.dataGatheringId = null;
    this.dataGatheringScreenData = null;
    this.dataGatheringSubmissionId = '';
  }
  @Action
  public resetCurrentCaseState(): void {
    this.context.commit("setDefaultState");
  }
}