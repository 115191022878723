import { Referral } from '../case/referral';
import { Castable } from '../Castable';
import { ValidationError } from '../standardResponses';


export enum DraftStatus {
    Incomplete = "INCOMPLETE",
    Submitted = "SUBMITTED",
    Pending = "PENDING",
    Reprocessing = "REPROCESSING"
}

export class Draft extends Castable {
    static Cast(obj: Object) : Draft {
        const createdDraft: Draft = super.Cast(Draft, obj);
        // Cast properties that are Castable Classes
        createdDraft.referral = Referral.Cast(obj['referral'] || {});
        if (!!obj) createdDraft.errors = (obj['errors'] || []).map(error => ValidationError.Cast(error));

        return createdDraft;
    }

    partnerCaseId!: string;
    organizationId!: string;
    referral: Referral = {} as Referral;
    metadata: Object = {}; // Shrug
    status!: DraftStatus
    errors: Array<ValidationError> = [];
    benefitManagerId?: string;
    panCaseId?: string
    transactionType? : string;
    version?: number;
}