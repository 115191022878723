export enum IntegrationType {
  AIM = "AIM",
  AimRpa = "AimRpa",
  AIMSmr = "AIMSmr",
  AvailityAetna = "AvailityAetna",
  AvailityAnthem = "AvailityAnthem",
  AvailityBcbsMnPsa = "AvailityBcbsMnPsa",
  AvailityFloridaBlue = "AvailityFloridaBlue",
  AvailityHcsc = "AvailityHcsc",
  AvailityHumana = "AvailityHumana",
  Evicore = "eviCore",
  Cohere = "CohereMmo",
  EvicoreCareCorePsa = "EvicoreCareCorePsa",
  EvicoreIntellipath = "eviCoreIntellipath",
  EvicoreUhcPsa = "EvicoreUhcPsa",
  MCG = "MCG",
  MOCK = "MOCK",
  NIA = "NIA",
  NotSupported = "NotSupported",
  UHC = "UHC",
  UhcSurgicalPsa = "UhcSurgicalPsa",
  Undetermined = "Undetermined",
  OliveFloridaBlue = "OliveFloridaBlue",
}

// Shares the same namespace as the above enum.
// IntegrationType.AIM will return "AIM"
// IntegrationType.DisplayName.AIM will return "AIM Speciality Health"
export namespace IntegrationType {
  export enum DisplayName {
    AIM = "AIM Specialty Health",
    AIMRPA = "AIM Specialty Health",
    AIMSMR = "AIM Specialty Health",
    AVAILITYAETNA = "Aetna",
    AVAILITYANTHEM = "Anthem",
    AVAILITYBCBSMNPSA = "BCBS MN",
    AVAILITYFLORIDABLUE = "Florida Blue",
    AUTHAIFLORIDABLUE = "Florida Blue",
    CARELON = "Carelon",
    OLIVEFLORIDABLUE = "Florida Blue",
    AVAILITYHCSC = "HCSC",
    AVAILITYHUMANA = "Humana",
    EVICORE = "eviCore",
    EVICORECARECOREPSA = "eviCore",
    EVICOREINTELLIPATH = "eviCore",
    EVICOREUHCPSA = "UHC",
    NIA = "NIA",
    MOCK = "Rhyme Mock",
    NOTSUPPORTED = "Not Supported",
    UNDETERMINED = "Undetermined",
    UHC = "UHC",
  }
}

export enum UiStepTypes {
  DataGatheringComplete = "DATA_GATHERING_COMPLETE",
  ClinicalUploadComplete = "CLINICAL_UPLOAD_COMPLETE",
  SurveyComplete = "SURVEY_COMPLETE",
  AddressCorrectionComplete = "ADDRESS_CORRECTION_COMPLETE",
}
