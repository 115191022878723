import { ROUTER_NAMES } from "../constants";
import { AuthState } from "@aws-amplify/ui-components";
import { experianTokens, isPanRso, panRsoToken, rsoUrl } from ".";
import VueRouter, { Route } from "vue-router";
import {
  BearerTokensResponse,
  ExperianAuthRequest,
} from "@/app/views/submitted/caseDetails/interfaces/interfaces";
import { catchError } from "rxjs/operators";
import { isAfter } from "date-fns";
import { Store } from "vuex";

const getRsoExpiration = (storeUserModule) => {
  return Number(storeUserModule.tokenExpiration || "0");
};

const currentRsoSession = (storeUserModule) => {
  return isAfter(new Date(getRsoExpiration(storeUserModule)), new Date())
    ? storeUserModule.idToken
    : null;
};

// Extracted for testability(sometime)
// App Wide Auth Handling.
// If you are on login screen and we get a signin, then we are gona push to HOME
// If there is any sign out anywhere, we are going to remove the data and potentially send you to login if your not embedded
export const authStateHandler =
  (
    getRouter: Function,
    getRoute: Function,
    logout: Function,
    removeIdToken: Function,
    store: Store<any>
  ) =>
  (authState, data) => {
    const router = getRouter();
    const route = getRoute();
    if (route.name === ROUTER_NAMES.Login && authState === AuthState.SignedIn) {
      const savedRoute = store.getters["GlobalModule/SavedRoute"];
      if (savedRoute !== "") {
        router.push({ path: savedRoute });
        store.dispatch("GlobalModule/emitSavedRoute", "");
      } else {
        router.push({ name: ROUTER_NAMES.CaseLookup });
      }
    }
    if (authState === AuthState.SignedOut) {
      logout();
      removeIdToken();
      if (route.name != ROUTER_NAMES.Login && !rsoUrl()) {
        router.push({ name: ROUTER_NAMES.Login });
      }
    }
  };

const getPanRsoAuthSource = (
  router: VueRouter,
  to: Route,
  queryParams: string[],
  id: string
) => {
  const foundTokenId = queryParams.find(
    (queryParam) => queryParam.toLowerCase() === panRsoToken.toLowerCase()
  ) as string;
  const foundToken = to.query[foundTokenId];
  return router.app.$api.post<BearerTokensResponse>(
    `${process.env.VUE_APP_RHYME_API}/rso/authenticate`,
    JSON.stringify(foundToken)
  );
};

const getExperianRsoAuthSource = (
  router: VueRouter,
  to: Route,
  queryParams: string[],
  id: string
) => {
  const foundTokenId = queryParams.find((queryParam) =>
    experianTokens.some(
      (experianToken) =>
        queryParam.toLowerCase() === experianToken.toLowerCase()
    )
  ) as string;
  const foundToken = to.query[foundTokenId] as string;
  const request: ExperianAuthRequest = {
    experianToken: foundToken,
    caseId: id,
    organizationId: to.params.orgId,
  };
  return router.app.$api.post<BearerTokensResponse>(
    // TODO: theres no new route for this yet
    `${process.env.VUE_APP_RHYME_API}/experian`,
    JSON.stringify(request)
  );
};

// Workflow for Federated Signins
const rsoSignIn = (
  Auth,
  router: VueRouter,
  store: any,
  to: Route,
  from: Route,
  next: any
) => {
  // Set us 'embed user'
  store.dispatch("UserModule/embedUser");

  // Get the source of our auth call;
  const queryParams: string[] = Object.keys(to.query);
  const id = to.params.caseId || to.params.draftId;
  const source = isPanRso()
    ? getPanRsoAuthSource(router, to, queryParams, id)
    : getExperianRsoAuthSource(router, to, queryParams, id);

  // Enter waiting state and sign out. If no RSO token found, explode. Otherwise continue with our found source
  store.dispatch("GlobalModule/enterWaitingState");
  Auth.signOut({ global: true }).finally(() => {
    if (!rsoUrl()) {
      next({
        path: "/notfound",
      });
      throw "Authentication Error: No Valid Token Found in URL!";
    }
    return source
      .pipe(
        catchError((err) => {
          next({
            path: "/notfound",
          });
          store.dispatch("GlobalModule/stopWaiting");

          throw "Authentication Error: " + err;
        })
      )
      .subscribe(
        (res: any) => {
          store.dispatch("UserModule/saveIdToken", res.data.idToken);
          store.dispatch("GlobalModule/stopWaiting");
          store.dispatch("UserModule/logIn");
          next();
        },
        (err) => {
          throw "Authentication Error:" + err;
        }
      );
  });
};

// If we don't have idToken and Login as set, set them now
const setLoginData = (store: any, token: any) => {
  if (!store.state["UserModule"]["loggedIn"])
    store.dispatch("UserModule/logIn");
  store.dispatch("UserModule/saveIdToken", token);
};

const getActiveSession = async (Auth, storeUserModule) => {
  try {
    if (rsoUrl()) return currentRsoSession(storeUserModule);
    const currentLoginSession = await Auth.currentSession();
    return currentLoginSession.idToken.jwtToken;
  } catch (ex) {
    return null;
  }
};

const updateInterval = (store, interval) => {
  store.dispatch("UserModule/updateRefreshInterval", interval);
};

const getRefreshedIdToken = async (Auth) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = cognitoUser.signInUserSession;
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (!!err) reject(err);
      resolve(session.idToken.jwtToken);
    });
  });
};

const setLoginRefreshInterval = async (Auth, store) => {
  // Time from the the token acquisition to the time it expires
  const expiryInterval = store.state.UserModule.expirationRange;
  // One Minute before it expires in range
  const consistentRefreshTime = expiryInterval - 60000;
  // Time from the expiration from this instant
  const untilExpiry =
    store.state.UserModule.tokenExpiration - new Date().getTime();
  // One Minute before the token expires or before the expiry range, whichever is smaller
  const lowestRefreshTime =
    (untilExpiry >= expiryInterval ? expiryInterval : untilExpiry) - 60000;
  // If under a minute, use 0 to instantly refresh
  const initialRefreshTime = lowestRefreshTime >= 0 ? lowestRefreshTime : 0;

  // Starts the expiration refresher a minute or less before expiration and saves the result as refreshInterval
  updateInterval(
    store,
    setTimeout(async () => {
      await store.dispatch(
        "UserModule/saveIdToken",
        await getRefreshedIdToken(Auth)
      );
      // Sets the refreshInterval to the actual interval we should use determined by the token
      updateInterval(
        store,
        setInterval(async () => {
          await store.dispatch(
            "UserModule/saveIdToken",
            await getRefreshedIdToken(Auth)
          );
        }, consistentRefreshTime)
      );
    }, initialRefreshTime)
  );
};

// Guard before each route transfer.
export const authGuard =
  (Auth, router, store) => async (to: Route, from: Route, next) => {
    store.dispatch("GlobalModule/resetSessionEndTime");
    const requiresAuth: any = to.matched[0].meta.requiresAuth;
    const storeUserModule = store.state.UserModule;
    // Active Session? Federated currently does not support current session, have to track it ourselves
    const activeSession = await getActiveSession(Auth, storeUserModule);
    if (!!activeSession) {
      setLoginData(store, activeSession);
      if (rsoUrl()) {
        if (to.name === ROUTER_NAMES.Login)
          return next({ name: ROUTER_NAMES.NotFound });
      } else {
        if (!store.state["UserModule"]["refreshInterval"])
          await setLoginRefreshInterval(Auth, store);
      }
      next();
    } else {
      if (!requiresAuth) {
        if (rsoUrl() && to.name === ROUTER_NAMES.Login)
          next({ name: ROUTER_NAMES.NotFound });
        else next();
      } else {
        if (rsoUrl()) {
          rsoSignIn(Auth, router, store, to, from, next);
        } else {
          store.dispatch("GlobalModule/emitSavedRoute", to.fullPath);
          next({ name: ROUTER_NAMES.Login });
        }
      }
    }
  };
