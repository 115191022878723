export enum FileDataStatus {
    PENDING = "pending",
    UPLOADED = "uploaded",
    SCANNING = "scanning",
    INFECTED = "infected",
    AVAILABLE = "available",
    UNSUPPORTED = "unsupported",
    ERROR = "error",
    SUBMITTED = "submitted",
    UMDIDNOTLIKETHISFILESOTHEYDIDNOTACCEPTIT = "umDidNotLikeThisFileSoTheyDidNotAcceptIt",
    CANCELED = "canceled",
    DELETED = "deleted",
    WARNING = "warning",
    // UI Only
    INITIAL = "initial"
}


export enum DocumentTypes {
    ClinicalDocument = "clinicalDocument",
    PayerArtifact = "payerArtifact",
    ClinicalNote = "clinicalNote"
}

export interface FileData {
    fileId: string;
    name: string;
    documentType: string;
    dateReceived: string; // ISO Date Please
    status: FileDataStatus;
}